import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
	selector: '[appOneDigitDecimalNumber]'
})
export class OneDigitDecimalNumberDirective {

	// Allow one decimal numbers
	private regex: RegExp = new RegExp(/^\d*\.?\d{0,1}$/g);
	// Allow key codes for special events. Reflect :
	// Backspace, tab, end, home
	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

	constructor(private el: ElementRef) {
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const input = event.key;
		const value = this.el.nativeElement.value;
		const newValue = input === '.' ? value + input + 0 : value + input;
		const singleDigitRegEx = /^\d+(\.\d{0,1})?$/;
		const digit = /^[0-9.]?$/;

		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		if (!digit.test(input) || (value === '' && event.keyCode === 190)) {
			event.preventDefault();
		} else {
			if (!singleDigitRegEx.test(newValue)) {
				event.preventDefault();
			}
		}
	}
}
