import {Component, HostListener, OnInit} from '@angular/core';
import {UserIdService} from './services/user-id.service.service';
import {SpinnerService} from './services/spinner/spinner.service';
import {Bulletin} from './models/bulletin';
import {ManageBulletinsService} from './services/master/manage-bulletins/manage-bulletins.service';
import {CarRequestService} from './services/request/car-request.service';
import {UtilService} from './services/util.service';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	/** Boolean for whether the menu is changed to collapsed 'hamburger menu' or full top horizontal menu */
	hamMenu: boolean;

	userName;
	bulletinsToShow: Bulletin[];
	isPrintPreview = false;

	constructor(private utilService: UtilService, private userIdService: UserIdService, public spinnerService: SpinnerService, private bulletinService: ManageBulletinsService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.userIdService.userNameSubject.subscribe((value: string) => {
			this.userName = value;
			if (this.userName !== 'User Name Not Available') {
				this.getBulletin();
			}
		});
		this.carRequestService.disableBulletinsOnPrintSubject.subscribe(onPrint => {
			if (onPrint) {
				this.isPrintPreview = true;
			}
		});
	}

	/** A listener to toggle between full screen menu and collapsed hamburger menu for smaller screens */

	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}

	checkSpinner() {
		return this.spinnerService.spinner.value;
	}

	private getBulletin() {
		this.bulletinService.findByCurrentDate().subscribe(response => {
			this.bulletinsToShow = response;
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}
}
