import {Injectable} from '@angular/core';
import {HistoricalMfgPerf} from '../models/historical-mfgperf';
import * as _ from 'lodash';
import {Policies} from '../models/aps-policies';
import {CarRequest} from '../models/car-request';
import {MessageService} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {SiteCodeService} from './sitecode.service';
import {SiteCode} from '../models/site-code';

@Injectable({
	providedIn: 'root'
})
export class UtilService {

	isAllProcessDataValidFromExternalDependencyError = true;
	isAllProcessDataExternalDependencyValidationScanDone = false;
	totalAllocationPercentageGreaterThan100 = false;
	containAssumptionsHasFalse = false;
	siteCodes: SiteCode[];

	constructor(private messageService: MessageService, private datepipe: DatePipe, private siteCodeService: SiteCodeService) {
	}

	isPrimaryAndSecondaryStreamProcessContainsAPWGreaterThan5(carRequest: CarRequest): boolean {
		let primaryStreamProcessContainsAPWGreaterThan5 = false;
		if (carRequest.psPnMapTransList && carRequest.psPnMapTransList.length > 0) {
			carRequest.psPnMapTransList.forEach(psPnMapTransList => {
				if ((psPnMapTransList.processDataList[0] && psPnMapTransList.processDataList[0].daysPerWeek > 5) || (psPnMapTransList.processDataList[1] && psPnMapTransList.processDataList[1].daysPerWeek > 6)) {
					primaryStreamProcessContainsAPWGreaterThan5 = true;
				}
			});
		}
		if (!primaryStreamProcessContainsAPWGreaterThan5) {
			primaryStreamProcessContainsAPWGreaterThan5 = this.isSupportingStreamProcessContainsAPWGreaterThan5(carRequest);
		}
		console.log('return ' + primaryStreamProcessContainsAPWGreaterThan5);
		return primaryStreamProcessContainsAPWGreaterThan5;
	}

	private isSupportingStreamProcessContainsAPWGreaterThan5(carRequest: CarRequest): boolean {
		//console.log('isSupportingStreamProcessContainsAPWGreaterThan5');
		//console.log(carRequest);
		let supportingStreamProcessContainsAPWGreaterThan5 = false;
		if (carRequest.primarySupportingMapTranList && carRequest.primarySupportingMapTranList.length > 0) {
			carRequest.primarySupportingMapTranList.forEach(supportingTransList => {
				supportingTransList.psPnMapTranList.forEach(psPnMapTranList => {
					if ((psPnMapTranList.processDataList[0] && psPnMapTranList.processDataList[0].daysPerWeek > 5) || (psPnMapTranList.processDataList[1] && psPnMapTranList.processDataList[1].daysPerWeek > 6)) {
						supportingStreamProcessContainsAPWGreaterThan5 = true;
					}
				});
			});
		}
		console.log('return ' + supportingStreamProcessContainsAPWGreaterThan5);
		return supportingStreamProcessContainsAPWGreaterThan5;
	}

	calculateAverageOEE(historicalmfgperfs: HistoricalMfgPerf[]): number {
		let sum = 0;
		let avg = 0;
		let recordCount = 0;
		let safetyFactor = 0;
		historicalmfgperfs.forEach(function (value) {
			if (!(value.hmfDateDisplay === null && value.gpp === null && value.nict === null && value.nat === null) && recordCount < 25) {
				sum += value.oee;
				recordCount++;
				safetyFactor = value.safetyFactor;
			}
		});
		avg = sum / recordCount;
		avg = avg - safetyFactor;
		if (avg) {
			const number = _.round(avg, 1);
			return number;
		} else {
			return 0;
		}
	}

	checkPermittedUpdatePolicy(policies: Policies, policyName): boolean {
		let hasPermission = false;
		if (policies && policies.Permitted !== null && policies.Permitted[0].update !== null) {
			hasPermission = policies.Permitted[0].update.includes(policyName);
		}
		return hasPermission;
	}

	checkAccessPermittedPolicy(policies: Policies): boolean {
		let hasPermission = false;
		if (policies && policies.Permitted !== null) {
			hasPermission = true;
		}
		return hasPermission;
	}

	checkPermittedDeletePolicy(policies: Policies, policyName): boolean {
		let hasPermission = false;
		if (policies && policies.Permitted !== null && policies.Permitted[0].delete !== null) {
			hasPermission = policies.Permitted[0].delete.includes(policyName);
		}
		return hasPermission;
	}

	isPrimaryAndSecondaryStreamProcessContainsErrors(carRequest: CarRequest) {
		console.log('isPrimaryAndSecondaryStreamProcessContainsErrors');
		console.log(carRequest);
		let status = 'N/A';
		let inCompleteCount = 0;
		let errorCount = 0;
		let riskCount = 0;
		let warningCount = 0;
		let validCount = 0;
		let emptyProcessCount = 0;
		let noOfProcessInAllStream = this.calculatePSLength(carRequest);
		this.isAllProcessDataExternalDependencyValidationScanDone = true;
		let isAllProcessDataValidFromExternalDependencyError = true; //global variable getting undefined so creating local variable
		if (carRequest.psPnMapTransList && carRequest.psPnMapTransList.length > 0) {
			for (let index = 0; index < carRequest.psPnMapTransList.length; index++) {
				const psPnMapTran = carRequest.psPnMapTransList[index];
				console.log(psPnMapTran.processDataList[0].resultStatus);
				if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Incomplete') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Incomplete')) {
					inCompleteCount++;
				} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Error') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Error')) {
					errorCount++;
				} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Risk') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Risk')) {
					riskCount++;
				} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Warning') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Warning')) {
					warningCount++;
				} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Valid') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Valid')) {
					validCount++;
				} else {
					emptyProcessCount++;
				}

				if (psPnMapTran.processDataList[0] && (psPnMapTran.processDataList[0].apwMpwChange || psPnMapTran.processDataList[0].hmpChange || psPnMapTran.processDataList[1].hmpChange || psPnMapTran.processDataList[0].sharedLoadChange || psPnMapTran.processDataList[1].sharedLoadChange || psPnMapTran.processDataList[0].scenarioChange || psPnMapTran.processDataList[0].partPrefixBaseSuffixChange || psPnMapTran.processDataList[0].isApprovalRobustness || psPnMapTran.processDataList[0].isCopiedCar || psPnMapTran.processDataList[0].scrapChange || psPnMapTran.processDataList[0].psChange)) {
					isAllProcessDataValidFromExternalDependencyError = false;
				}
			}
		}
		if (emptyProcessCount === 0) {
			if (carRequest.primarySupportingMapTranList && carRequest.primarySupportingMapTranList.length > 0) {
				for (let streamIndex = 0; streamIndex < carRequest.primarySupportingMapTranList.length; streamIndex++) {
					const primarySupportingMapTran = carRequest.primarySupportingMapTranList[streamIndex];
					noOfProcessInAllStream = noOfProcessInAllStream + primarySupportingMapTran.psPnMapTranList.length;
					for (let processIndex = 0; processIndex < primarySupportingMapTran.psPnMapTranList.length; processIndex++) {
						const psPnMapTran = primarySupportingMapTran.psPnMapTranList[processIndex];
						if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Incomplete') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Incomplete')) {
							inCompleteCount++;
						} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Error') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Error')) {
							errorCount++;
						} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Risk') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Risk')) {
							riskCount++;
						} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Warning') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Warning')) {
							warningCount++;
						} else if ((psPnMapTran.processDataList[0] && psPnMapTran.processDataList[0].resultStatus === 'Valid') || (psPnMapTran.processDataList[1] && psPnMapTran.processDataList[1].resultStatus === 'Valid')) {
							validCount++;
						} else {
							emptyProcessCount++;
						}
						if (psPnMapTran.processDataList[0] && (psPnMapTran.processDataList[0].apwMpwChange || psPnMapTran.processDataList[0].hmpChange || psPnMapTran.processDataList[1].hmpChange || psPnMapTran.processDataList[0].sharedLoadChange || psPnMapTran.processDataList[1].sharedLoadChange || psPnMapTran.processDataList[0].scenarioChange || psPnMapTran.processDataList[0].partPrefixBaseSuffixChange || psPnMapTran.processDataList[0].isApprovalRobustness || psPnMapTran.processDataList[0].isCopiedCar || psPnMapTran.processDataList[0].scrapChange || psPnMapTran.processDataList[0].psChange)) {
							isAllProcessDataValidFromExternalDependencyError = false;
						}
					}
				}
			}
		}
		if (inCompleteCount > 0) {
			status = 'Incomplete';
		} else if (inCompleteCount === 0 && errorCount > 0) {
			status = 'Error';
		} else if (inCompleteCount === 0 && errorCount === 0 && riskCount > 0) {
			status = 'Risk';
		} else if (inCompleteCount === 0 && errorCount === 0 && riskCount === 0 && warningCount > 0) {
			status = 'Warning';
		} else if (validCount === noOfProcessInAllStream && noOfProcessInAllStream > 0 && validCount > 0) {
			status = 'Valid';
		} else {
			status = 'N/A';
		}

		if (!isAllProcessDataValidFromExternalDependencyError && status !== 'Incomplete') {
			status = 'Error';
		}
		console.log('*******final status ' + status);
		this.isAllProcessDataValidFromExternalDependencyError = isAllProcessDataValidFromExternalDependencyError;
		console.log('*******after scan this.isAllProcessDataValidFromExternalDependencyError ' + this.isAllProcessDataValidFromExternalDependencyError);
		return status;
	}

	private calculatePSLength(carRequest: CarRequest) {
		if (carRequest && carRequest.psPnMapTransList) {
			return carRequest.psPnMapTransList.length;
		} else {
			return 0;
		}
	}

	inputNumberValidationWithSingleDecimal(event: any) {

		// Check if the text is selected allow to continue
		/*if (document.getSelection().toString() === event.target.value.toString()) {
			console.log('selection condition matched, returning true');
			return true;
		}
		// checking for zero on first char position
		if (event.target.value.length === 0) {
			//	console.log('length is zero so checking entered value is zero or not');
			if (event.key === '0') {
				console.log('entered values is zero returning false');
				return false;
			}
		}*/
		//checking entered value is between 0 and 9 or dot
		if (event.key >= '0' && event.key <= '9' || event.key === '.') {
			//	console.log('Entered values is between 0 and 9');
		} else {
			//	console.log('Entered key is not between 0 and 9');
			return false;
		}
		//checking after . there should be only one digit
		const tarValue = event.target.value.toString();
		//	console.log('Target value in string format: ' + tarValue);
		const spltarr = tarValue.split('.');
		console.log('splitted valie');
		console.log(spltarr);
		if (spltarr.length > 1) {
			console.log('splitted second value: ' + spltarr[1]);
			if (event.key === '.') {
				return false;
			}
			if (spltarr[1] >= '0' && spltarr[1] <= '9') {
				console.log('Entered after one digit. so returning false for second digit');
				return false;
			}
		}
		return true;
	}

	inputNumberValidationWithTwoDecimal(event: any) {
		//checking entered value is between 0 and 9 or dot
		if (event.key >= '0' && event.key <= '9' || event.key === '.') {
			//	console.log('Entered values is between 0 and 9');
		} else {
			//	console.log('Entered key is not between 0 and 9');
			return false;
		}
		//checking after . there can be two digit
		const tarValue = event.target.value.toString();
		//	console.log('Target value in string format: ' + tarValue);
		const spltarr = tarValue.split('.');
		console.log('splitted value');
		console.log(spltarr);
		if (spltarr.length > 1) {
			console.log('splitted second value: ' + spltarr[1]);
			console.log('length of second array: ');
			console.log(spltarr[1].length);
			if (event.key === '.') {
				return false;
			}
			if (spltarr[1].length === 2) {
				console.log('Two digits present. so returning false for third digit');
				return false;
			}
		}
		return true;
	}

	inputNumberValidationWithDecimal(event: any, scale: number) {
		//checking entered value is between 0 and 9 or dot
		if (event.key >= '0' && event.key <= '9' || event.key === '.') {
		} else {
			return false;
		}
		//checking after . there can be two digit
		const tarValue = event.target.value.toString();
		const spltarr = tarValue.split('.');
		if (spltarr.length > 1) {
			console.log('split second value and its length: ' + spltarr[1] + ' ' + spltarr[1].length);
			if (event.key === '.') {
				return false;
			}
			if (spltarr[1].length === scale) {
				console.log(scale + ' digits present. so returning false for next digit');
				return false;
			}
		}
		return true;
	}

	/*
		validateDecimalNumber(event: any, singleDecimal: boolean) {
			const input = String.fromCharCode(event.charCode);
			const value = event.target.value;
			const newValue = event.charCode === 46 ? value + input + 0 : value + input;
			const regEx = singleDecimal ? /^\d+(\.\d{1})?$/ : /^\d+(\.\d{1,2})?$/;
			const digit = /^[0-9.]?$/;

			if (!digit.test(input) || (value === '' && event.charCode === 46)) {
				event.preventDefault();
			} else {
				if (!regEx.test(newValue)) {
					event.preventDefault();
				}
			}
		}*/

	pushMsg(msgSeverity: string, messageTitle: string, message: string) {
		let msgLife;
		if (messageTitle === 'Success' || messageTitle === 'Information') {
			msgLife = 15000;
		} else {
			msgLife = 15000;
		}
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: msgLife
		});
	}

	isPrimaryAndSecondaryStreamProcessContainsMPWGreaterThan6(carRequest: CarRequest): boolean {
		let primaryStreamProcessContainsMPWGreaterThan6 = false;
		if (carRequest.psPnMapTransList) {
			primaryStreamProcessContainsMPWGreaterThan6 = carRequest.psPnMapTransList.some(psPnMapTransList => {
				if (psPnMapTransList.processDataList[1] && psPnMapTransList.processDataList[1].daysPerWeek > 6) {
					return true;
				}
			});
		}
		if (!primaryStreamProcessContainsMPWGreaterThan6) {
			primaryStreamProcessContainsMPWGreaterThan6 = this.isSupportingStreamProcessContainsMPWGreaterThan6(carRequest);
		}
		console.log('return ' + primaryStreamProcessContainsMPWGreaterThan6);
		return primaryStreamProcessContainsMPWGreaterThan6;
	}

	private isSupportingStreamProcessContainsMPWGreaterThan6(carRequest: CarRequest): boolean {
		let supportingStreamProcessContainsMPWGreaterThan6 = false;
		if (carRequest.primarySupportingMapTranList) {
			supportingStreamProcessContainsMPWGreaterThan6 = carRequest.primarySupportingMapTranList.some(supportingTransList => {
				supportingTransList.psPnMapTranList.some(psPnMapTranList => {
					if (psPnMapTranList.processDataList[0] && psPnMapTranList.processDataList[0].daysPerWeek > 5) {
						return true;
					}
				});
			});
		}
		console.log('return ' + supportingStreamProcessContainsMPWGreaterThan6);
		return supportingStreamProcessContainsMPWGreaterThan6;
	}

	linkify(plainLinkableText): string {
		if (plainLinkableText && plainLinkableText.trim() !== '') {
			let replacedLinkText;

			if (plainLinkableText.toLowerCase().startsWith('http') || plainLinkableText.startsWith('ftp')) {
				//URLs starting with http://, https://, or ftp://
				let httpReplacePattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
				replacedLinkText = plainLinkableText.replace(httpReplacePattern, '$1');
			}
			//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
			else if (plainLinkableText.toLowerCase().startsWith('www')) {
				let wwwReplacePattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
				replacedLinkText = plainLinkableText.replace(wwwReplacePattern, '$1');
				replacedLinkText = 'http://' + replacedLinkText;
			}
			//Change email addresses to mailto:: links.
			else {
				let mailReplacePattern = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
				replacedLinkText = plainLinkableText.replace(mailReplacePattern, 'mailto:$1');
			}
			console.log('Changed link ' + replacedLinkText);
			return replacedLinkText;
		}
	}

	convertDateStringInFormat(dateString: string): string {
		if (dateString !== null) {
			dateString = this.datepipe.transform(dateString, 'MM/dd/yyyy');
		}
		return dateString;
	}

	isUserHasOnlyTokenOnly(): boolean {
		const sites = JSON.parse(sessionStorage.getItem('attributes')).siteCodes;
		const uRole = JSON.parse(sessionStorage.getItem('attributes')).userRole;
		if (sites && sites.length > 0) {
			this.siteCodes = this.siteCodeService.fetchSiteCodes();
		}
		if ((uRole.toLowerCase().includes('ecar') || uRole.includes('Site') || uRole.includes('Developer')) && sites && (sites.length === 0 || (this.siteCodes && this.siteCodes.length === 0) || (sites.length === 1 && sites[0].toUpperCase().includes('TOKEN_ONLY')))) {
			return true;
		} else {
			return false;
		}
	}
}
