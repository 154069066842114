import {Component, OnInit} from '@angular/core';
import {ManageSubmissionScenario, masterTemplates} from '../../models/manage-submission-scenario';
import {DialogService} from 'primeng/api';
import {ManageSubmissionScenarioService} from '../../services/master/manage-submission-scenario/manage-submission-scenario.service';
import {Policies} from '../../models/aps-policies';
import {UtilService} from '../../services/util.service';
import {OndeleteDialogueboxComponent} from '../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../models/on-delete-display-dialogue-model';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'manage-ecar-scenario',
	templateUrl: './manage-submission-scenario.component.html',
	styleUrls: ['./manage-submission-scenario.component.css']
})
export class ManageSubmissionScenarioComponent implements OnInit {
	scenarioNames: ManageSubmissionScenario [];
	clonedScenarioName: { [s: string]: ManageSubmissionScenario; } = {};
	row = true;
	scenarioName: string = null;
	scenarioDesc: string = null;
	selectedTemplateName: ManageSubmissionScenario = null;
	updatedTemplateName: ManageSubmissionScenario = null;
	scenarioNameObj: any;
	active = true;
	private templateNamesList: ManageSubmissionScenario[];
	private msg: string;
	approvalReq = false;
	notifySta = false;
	notifyBuyer = false;
	policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;

	constructor(private utilService: UtilService, private service: ManageSubmissionScenarioService, public dialogService: DialogService, private title: Title) {
	}

	ngOnInit() {
		this.findAllScenarioNames();
		this.templateNamesList = masterTemplates;
		this.findAllPolicies();
		this.title.setTitle('eCAR - Submission Scenario');
	}

	onRowEditInit(scenarioName: ManageSubmissionScenario) {
		this.clonedScenarioName[scenarioName.id] = {...scenarioName};
		this.updatedTemplateName = this.templateNamesList.find(value => value.templateName === scenarioName.templateName);
	}

	onRowDelete(scenarioName: ManageSubmissionScenario) {
		this.service.checkCARAssociatedWithScenarioId(scenarioName.id).subscribe(value => {
			if (value) {
				this.utilService.pushMsg('error', 'Error', 'Unable to delete Scenario, it is already associated with an existing eCAR record');
				console.log('Submission Scenario Id is associated with eCAR !');
			} else {
				console.log('Submission Scenario Id is not associated with any eCAR. Proceed next');
				console.log('calling dialog ref');
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '30%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'}
				});

				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					console.log('Received after close');
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.service.delete(scenarioName.id).subscribe(successValue => {
								this.utilService.pushMsg('success', 'Success', 'Scenario Name is deleted successfully');
								this.findAllScenarioNames();
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Delete operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						} else {
							console.log('dont Delete response from dialogue box');
						}
					}
				});
			}
		});
	}

	onUpdate(scenario: ManageSubmissionScenario, index) {
		let isValid = true;
		if (scenario.id > 0 && !scenario.scenarioName && scenario.scenarioName.trim() === '') {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Scenario Name should not be empty');
		} else if (scenario.id > 0 && !scenario.scenarioDesc && scenario.scenarioDesc.trim() === '') {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Scenario Description should not be empty');
		} else if (scenario.id > 0 && !scenario.templateName && scenario.templateName.trim() === '') {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Template Name should not be empty');
		}
		if (isValid) {
			delete this.clonedScenarioName[scenario.id];
			scenario.templateName = this.updatedTemplateName.templateName;
			this.service.update(scenario.id, scenario).subscribe(value => {
				this.utilService.pushMsg('success', 'Success', 'Scenario Name is updated successfully');
				this.findAllScenarioNames();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Update operation failed, Scenario Name is already exist');
				this.findAllScenarioNames();
			});
		}
	}

	onRowEditCancel(scenarioName: ManageSubmissionScenario, index: number) {
		this.scenarioNames[index] = this.clonedScenarioName[scenarioName.id];
		delete this.clonedScenarioName[scenarioName.id];
	}

	onClose($event: any) {
		this.resetFields();
	}

	onCreate() {
		if (this.scenarioName && this.scenarioDesc && this.selectedTemplateName) {
			this.scenarioNameObj = {
				scenarioName: this.scenarioName,
				scenarioDesc: this.scenarioDesc,
				templateName: this.selectedTemplateName.templateName,
				active: this.active,
				approvalReq: this.approvalReq,
				notifySta: this.notifySta,
				notifyBuyer: this.notifyBuyer
			};
			this.service.create(this.scenarioNameObj).subscribe((value) => {
				this.utilService.pushMsg('success', 'Success', 'Scenario Name is created successfully');
				this.findAllScenarioNames();
				this.resetFields();
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Scenario Name is already exist');
			});
		} else {
			if (this.scenarioName === null || this.scenarioName.trim() === '') {
				this.msg = 'Scenario Name';
			} else if (this.scenarioDesc === null || this.scenarioDesc.trim() === '') {
				this.msg = 'Scenario Description';
			} else {
				this.msg = 'Template Name';
			}
			this.utilService.pushMsg('error', 'Error', this.msg + ' should not be empty');
		}
	}

	private findAllScenarioNames() {
		this.service.findAll().subscribe((response: ManageSubmissionScenario[]) => {
			this.scenarioNames = response;
		});
	}

	private resetFields() {
		this.scenarioName = null;
		this.scenarioDesc = null;
		this.selectedTemplateName = null;
		this.active = true;
		this.approvalReq = false;
		this.notifySta = false;
		this.notifyBuyer = false;
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:ManageScenario';
		this.disableCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
	}
}
