/**This file contains a constant object that has properties that are necessary
 * for the build found in the `production` configuration in `angular.json`.
 */
export const environment = {
	envName: 'qa',
	production: true,
	tokenUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:ecar_preprod:clientid:web_ecarpreprodwebsite:dev',
	resource: 'urn:ecar_preprod:resource:web_ecarpreprodwebsite:dev',
	apiUrl: 'https://wwwqa.api.ecar.ford.com/api/v1/car'
};
