export class ManageSubmissionScenario {
	id: number;
	scenarioName: string;
	scenarioDesc: string;
	templateName: string;
	capacityStudy: boolean;
	rfqScenario: boolean;
	currentProdRun: boolean;

	constructor(manageScenario: ManageSubmissionScenario) {
		Object.assign(this, manageScenario);
	}
}

export const masterTemplates: ManageSubmissionScenario[] = [
	{templateName: 'Capacity Planning', id: 1, scenarioName: '', scenarioDesc: '', capacityStudy: false, rfqScenario: false, currentProdRun: null},
	{templateName: 'Phase 0 PPAP', id: 2, scenarioName: '', scenarioDesc: '', capacityStudy: false, rfqScenario: false, currentProdRun: null},
	{templateName: 'Phase 3 PPAP', id: 3, scenarioName: '', scenarioDesc: '', capacityStudy: false, rfqScenario: false, currentProdRun: null}];

