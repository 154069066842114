import {DeclarationQuestion} from './declaration-question';
import {SelectItem} from 'primeng/api';

export class DeclarationQuestionsTran {

	id: number;
	masterQuestionId: number;
	carReqId: number;
	question: string;
	ansType: string;
	mandatory: boolean;
	template: string;
	openTypeAnswer: string;
	yesNoAnswer: string;
	dateAnswer: string;
	dateDisplayAnswer: Date;
	dateAnswerDisplay: number;
	optionObj: SelectItem[];
	answer: string;
	answered: boolean;
	comments: string;
	optionStr: string;
	disableProp = false;
	commentsPlaceholder: string;


	constructor(declarationQuestionsTran: DeclarationQuestionsTran | DeclarationQuestion) {
		if (declarationQuestionsTran instanceof DeclarationQuestionsTran) {
			Object.assign(this, declarationQuestionsTran);
		} else if (declarationQuestionsTran instanceof DeclarationQuestion) {
			this.id = declarationQuestionsTran.id;
			this.masterQuestionId = declarationQuestionsTran.id;
			this.question = declarationQuestionsTran.question;
			this.template = declarationQuestionsTran.template;
			this.optionObj = JSON.parse(declarationQuestionsTran.option);
			this.ansType = declarationQuestionsTran.ansType;
			this.openTypeAnswer = '';
			this.yesNoAnswer = '';
			this.dateAnswer = null;
			this.dateDisplayAnswer = null;
			this.dateAnswerDisplay = null;
			this.answer = '';
			this.answered = false;
			this.mandatory = declarationQuestionsTran.mandatory;
			this.disableProp = false;
			this.commentsPlaceholder = declarationQuestionsTran.commentsPlaceholder;
		}
	}
}

export const emptyDCLRTNQuestionsTran = {
	'id': 0,
	'carReqId': 0,
	'question': '',
	'template': '',
	'openTypeAnswer': '',
	'yesNoAnswer': '',
	'dateAnswer': 0,
	'option': '',
	'answer': '',
	'answered': false,
	'disableProp': false,
	'commentsPlaceholder': ''
};
