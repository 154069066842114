import {DropdownOption} from './dropdown-option';

export class HistoricalMfgPerf {
	hmfId: number;
	siteCode: string;
	processId: number;
	weekNumber: number;
	hmfDate: string;
	hmfDateDisplay: number;
	gpp: number;
	nict: number;
	nat: number;
	oee: number;
	averageOEE: number;
	noOfRecords: number;
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;
	source: DropdownOption;
	comments: string;
	safetyFactor: number;

	constructor(historicalmfgperf: HistoricalMfgPerf) {
		Object.assign(this, historicalmfgperf);
	}
}

export const emptyHMP = {
	'hmfId': 0,
	'siteCode': '',
	'processId': 0,
	'weekNumber': 0,
	'hmfDate': '',
	'hmfDateDisplay': 0,
	'gpp': 0.0,
	'nict': 0.0,
	'nat': 0.0,
	'oee': 0.0,
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'source': null,
	'comments': '',
	'groupId': 0,
	'safetyFactor': 0.0,
	'averageOEE': 0,
	'noOfRecords': 0
};
