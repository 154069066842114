export class KeyContacts {
	keyContactsId: number;
	carReqId: number;
	cdsid: string;
	mail: string;
	phone: string;
	type: string;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	name: string;
	organizationName: string;

	constructor(keyContacts: KeyContacts) {
		Object.assign(this, keyContacts);
	}
}

export const emptyKeyContacts = {
	'keyContactsId': 0,
	'carReqId': 0,
	'cdsid': '',
	'mail': '',
	'phone': '',
	'type': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'name': null,
	'organizationName': null
};
