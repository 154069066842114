export class ProcessType {
	processTypeId: number;
	processTypeName: string;
	processTypeDesc: string;
	active: boolean;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;

	constructor(processType: ProcessType) {
		Object.assign(this, processType);
	}
}