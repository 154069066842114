import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ManageSubmissionScenario} from '../../../models/manage-submission-scenario';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Config} from '../../../config';

@Injectable({
	providedIn: 'root'
})
export class ManageSubmissionScenarioService {
	private config = new Config();
	private httpUrl = `${this.config.url}/manageScenario`;

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<ManageSubmissionScenario[]> {
		return this.http.get(this.httpUrl).pipe(map((manageScenarios: ManageSubmissionScenario[]) => {
			return manageScenarios.map(manageScenario => {
				return new ManageSubmissionScenario(manageScenario);
			});
		}));
	}

	create(manageScenario: ManageSubmissionScenario): Observable<ManageSubmissionScenario> {
		return this.http.post<ManageSubmissionScenario>(this.httpUrl, manageScenario);
	}

	update(id: number, manageScenario: ManageSubmissionScenario): Observable<ManageSubmissionScenario> {
		return this.http.put<ManageSubmissionScenario>(this.httpUrl + '/' + id, manageScenario);
	}

	delete(id: number): Observable<ManageSubmissionScenario> {
		return this.http.delete<ManageSubmissionScenario>(this.httpUrl + '/' + id);
	}

	checkCARAssociatedWithScenarioId(scenarioId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/findCARAssociatedWithScenarioId/' + scenarioId);
	}

	findById(id: number): Observable<ManageSubmissionScenario> {
		return this.http.get<ManageSubmissionScenario>(this.httpUrl + '/' + id);
	}
}
