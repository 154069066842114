import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DeclarationQuestion} from '../../../models/declaration-question';
import {map} from 'rxjs/operators';
import {Config} from '../../../config';
import {DeclarationQuestionsTran} from '../../../models/declaration-question-trans';

@Injectable({
	providedIn: 'root'
})
export class ManageDeclarationQuestionsService {
	private config = new Config();
	private httpUrl = `${this.config.url}/declarationQuestion`;

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<DeclarationQuestion[]> {
		return this.http.get(this.httpUrl).pipe(map((declarationQuestions: DeclarationQuestion[]) => {
			return declarationQuestions.map(question => {
				return new DeclarationQuestion(question);
			});
		}));
	}

	create(declarationQuestion: DeclarationQuestion): Observable<DeclarationQuestion> {
		return this.http.post<DeclarationQuestion>(this.httpUrl, declarationQuestion);
	}

	update(id: number, declarationQuestion: DeclarationQuestion): Observable<DeclarationQuestion> {
		return this.http.put<DeclarationQuestion>(this.httpUrl + '/' + id, declarationQuestion);
	}

	findById(id: number): Observable<DeclarationQuestion> {
		return this.http.get<DeclarationQuestion>(this.httpUrl + '/' + id);
	}

	deleteById(id: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrl + '/' + id);
	}

	delete(id: number) {
		return this.http.delete<boolean>(this.httpUrl + '/' + id);
	}

	findByTemplate(template: string): Observable<DeclarationQuestion[]> {
		return this.http.get(this.httpUrl + '/findByTemplate/' + template).pipe(map((declarationQuestions: DeclarationQuestion[]) => {
			return declarationQuestions.map(question => {
				return new DeclarationQuestion(question);
			});
		}));
	}

	findByCarReqId(carReqId: number): Observable<DeclarationQuestionsTran[]> {
		return this.http.get(this.httpUrl + '/findByCarReqId/' + carReqId).pipe(map((declarationQuestions: DeclarationQuestionsTran[]) => {
			return declarationQuestions.map(question => {
				question.optionObj = JSON.parse(question.optionStr);
				return question;
			});
			//return declarationQuestions;
		}));
	}
}
