export class CarReqComments {

	carReqCommentId: number;
	carReqId: string;
	comment: string;
	createdBy: string;
	createdByUserRole: boolean;
	createdDate: number;

	deSerialize(obj) {
		this.carReqId = obj.carReqId;
		this.comment = obj.comment;
		this.createdBy = obj.createdBy;
		this.createdByUserRole = obj.createdByUserRole;
		this.createdDate = obj.createdDate;
		return this;
	}
}
