export class SupplierSiteDetails {
	q1StatusCode: string;
	q1StatusDesc: string;
	supplierName: string;
	staSiteEngCdsId: string;
	staSiteEngName: string;
	staSiteEngPhone: string;
	siteDetailsLoaded: boolean;
	streetName: string;
	cityName: string;
	provinceCode: string;
	countryCode: string;
	postalCode: string;
	prodPPAPLevel: number

	constructor(supplierSiteDetails: SupplierSiteDetails) {
		Object.assign(this, supplierSiteDetails);
	}
}

export const emptySupplierSiteDetails = {
	'q1StatusCode': '',
	'q1StatusDesc': '',
	'supplierName': '',
	'staSiteEngCdsId': '',
	'staSiteEngName': '',
	'staSiteEngPhone': '',
	'siteDetailsLoaded': false,
	'streetName': '',
	'cityName': '',
	'provinceCode': '',
	'countryCode': '',
	'postalCode': '',
	'prodPPAPLevel': 0
};
