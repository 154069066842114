import {KeyContacts} from './key-contacts';
import {PsPnMapTran} from './pspnmaptran';
import {SiteCode} from './site-code';
import {ManageSubmissionScenario} from './manage-submission-scenario';
import {PrimarySupportingMapTran} from './primary-supporting-map-tran';
import {DeclarationQuestionsTran} from './declaration-question-trans';
import {Option} from './option';

export class CarRequest {
	reqId: number;
	siteCodeObject: SiteCode;
	siteCode: string;
	supplierName: string;
	partName: string;
	partNumDisplay;
	prefix: string;
	base: string;
	suffix: string;
	programCodeOthers: string;
	programs: string;
	partPpapLevel: number;
	partPPAPLevelObject: PartPPAPLevel;
	submissionScenario: number;
	submissionScenarioObject: ManageSubmissionScenario;
	scenarioName: string;
	capacityRequirementSource: string;
	reqDescription: string;
	paRequirementsApw: number;
	paRequirementsMpw: number;
	gcpMcpvApw: number;
	gcpMcpvMpw: number;
	primaryStreamId: number;
	requestStatus: string;
	userAction: string;
	assignedTo: string;
	actionRequired: string;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	plannedCapacityApw: number;
	plannedCapacityMpw: number;
	phase0DemonstratedCapacityApw: number;
	phase0DemonstratedCapacityMpw: number;
	commitmentAppc: number;
	commitmentMppc: number;
	notes: string;
	workflowStatus: string;
	comment: string;
	priority: string;
	priorityObject: Priority;
	q1StatusCode: string;
	q1StatusDesc: string;
	keyContacts: KeyContacts[];
	psPnMapTransList: PsPnMapTran[];
	capacityRequirementSourceObject: CapacityRequirementSource;
	primarySupportingMapTranList: PrimarySupportingMapTran[];
	declarationQuestionsTranList: DeclarationQuestionsTran[];
	awaitingApproval: boolean;
	isAnyProcessContainsAPWGreaterThan5: boolean;
	isDeviationApproved: string;
	deviationReasonCode: number;
	deviationReasonObject: DeviationReason;
	submittedByEmailId: string;
	submittedByName: string;
	isLinkCar: boolean;
	selectedCarIdForLinkageObject: Option;
	selectedCarIdForLinkage: number;
	selectedStreamIdForLinkageObject: Option;
	selectedStreamIdForLinkage: number;
	selectedProcessNameIdForLinkageObject: Option;
	selectedProcessNameIdForLinkage: number;
	parentCarStatus: string;
	submissionMessage: string;
	requirementType: number;
	revisedRequirementsApw: number;
	revisedRequirementsMpw: number;
	nextApproverUserId: string;
	workflowId: number;
	recallReason: string;
	isAlertRequired: boolean;
	bottleNeckProcessNameIdApwMap?: Map<number, number[]>;
	bottleNeckProcessNameIdMpwMap?: Map<number, number[]>;
	bottleNeckProcessNameIdApw?: string;
	bottleNeckProcessNameIdMpw?: string;
	loggedInUserId: string;
	proposedPlanCode: number;
	capacityStudyId: string;
	proposedPlanObject: ProposedPlan;
	proposedPlanDate: string;
	isAnyProcessInCARHasWarning: boolean;
	copiedFromReqId: number;
	lastSubmittedDate: number;
	isSiteEngReassign: boolean;
	isPSUpdated: boolean;
	isPSUpdatedToClearData: boolean;
	keepHistoricalPSInRejRecall: boolean;
	isProcessStreamAvailable: boolean;
	removeProcessStreamData: boolean;
	paRequirementsProdApw: number;
	paRequirementsProdMpw: number;
	paRequirementsServiceApw: number;
	paRequirementsServiceMpw: number;
	revisedRequirementsProdApw: number;
	revisedRequirementsProdMpw: number;
	revisedRequirementsServiceApw: number;
	revisedRequirementsServiceMpw: number;
	gcpMcpvProdApw: number;
	gcpMcpvProdMpw: number;
	gcpMcpvServiceApw: number;
	gcpMcpvServiceMpw: number;

	constructor(carRequest: CarRequest) {
		Object.assign(this, carRequest);
	}
}

export class PartPPAPLevel {
	name: string;
	code: number;
}

export const emptyPartPPAPLevelDropdown = [
//	{name: 'Select Part PPAP Level', code: 0},
	{name: '1', code: 1},
	{name: '3', code: 3},
	{name: '5', code: 5}];

export class CapacityRequirementSource {
	name: string;
	code: string;
}

export const capacityRequirementSourceDropdown = [
//	{name: 'Select Capacity Requirement Source', code: ''},
	{name: 'Ariba RFP', code: 'Ariba RFP'},
	{name: 'Ariba Sourcing Agreement', code: 'Ariba Sourcing Agreement'},
	{name: 'Capacity Study', code: 'Capacity Study'},
	{name: 'CPA', code: 'CPA'},
	{name: 'GCP/MCPV', code: 'GCP/MCPV'},
	{name: 'Long-Lead Tool Order', code: 'Long-Lead Tool Order'},
	{name: 'SOBA', code: 'SOBA'},
	{name: 'WebQuote', code: 'WebQuote'},
	{name: 'Other', code: 'Other'}];

export class Priority {
	name: string;
	code: string;
}

export const priorityDropdown = [
//	{name: 'Select Priority', code: ''},
	{name: 'Yes', code: 'Yes'},
	{name: 'No', code: 'No'}];

export class DeviationReason {
	name: string;
	code: number;
}

export const deviationReasonDropdown = [
//	{name: 'Select DeviationReason', code: ''},
	{name: 'Operating Pattern is acceptable per industry standards', code: 1},
	{name: 'Operating Pattern is acceptable per country standards', code: 2},
	{name: 'Operating Pattern Deviation Approval is Required', code: 3}];

export class ProposedPlan {
	name: string;
	code: number;
}

export const proposedPlanDropdown = [
//	{name: 'Select ProposedPlan', code: ''},
	{
		name: 'There is a proposed plan to attain study volumes by the implementation date and a supporting Planning eCAR is being submitted',
		code: 1
	},
	{name: 'There is currently no proposed plan to attain study volumes by the implementation date', code: 2}];

export const emptyCarRequest = {
	'reqId': 0,
	'siteCodeObject': {name: 'Select SiteCode', code: 'invalid'},
	'siteCode': '',
	'supplierName': '',
	'partName': '',
	'partNumDisplay': '',
	'prefix': '',
	'base': '',
	'suffix': '',
	'programCodeOthers': '',
	'programs': '',
	'partPpapLevel': 0,
	'partPPAPLevelObject': {name: '', code: -1},
	'submissionScenario': 0,
	'submissionScenarioObject': {
		templateName: '',
		id: -1,
		scenarioName: '',
		scenarioDesc: '',
		capacityStudy: false,
		rfqScenario: false,
		currentProdRun: null
	},
	'scenarioName': '',
	'capacityRequirementSourceObject': {name: '', code: 'invalid'},
	'capacityRequirementSource': '',
	'reqDescription': '',
	'paRequirementsApw': null,
	'paRequirementsMpw': null,
	'gcpMcpvApw': null,
	'gcpMcpvMpw': null,
	'primaryStreamId': 0,
	'requestStatus': null,
	'userAction': null,
	'assignedTo': '',
	'actionRequired': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'plannedCapacityApw': 0,
	'plannedCapacityMpw': 0,
	'phase0DemonstratedCapacityApw': 0,
	'phase0DemonstratedCapacityMpw': 0,
	'commitmentAppc': null,
	'commitmentMppc': null,
	'notes': null,
	'workflowStatus': null,
	'comment': null,
	'priority': '',
	'priorityObject': {name: '', code: 'invalid'},
	'q1StatusCode': null,
	'q1StatusDesc': null,
	'keyContacts': [],
	'psPnMapTransList': [],
	'primarySupportingMapTranList': [],
	'declarationQuestionsTranList': [],
	'awaitingApproval': false,
	'isAnyProcessContainsAPWGreaterThan5': false,
	'isDeviationApproved': 'false',
	'deviationReasonObject': {name: '', code: -1},
	'deviationReasonCode': 0,
	'submittedByEmailId': '',
	'submittedByName': '',
	'isLinkCar': false,
	'selectedCarIdForLinkageObject': {name: 'Select eCAR ID', code: 'invalid'},
	'selectedCarIdForLinkage': 0,
	'selectedStreamIdForLinkageObject': {name: 'Select Stream', code: 'invalid'},
	'selectedStreamIdForLinkage': 0,
	'selectedProcessNameIdForLinkageObject': {name: 'Select Process Name', code: 'invalid'},
	'selectedProcessNameIdForLinkage': 0,
	'parentCarStatus': '',
	'submissionMessage': '',
	'requirementType': 1,
	'revisedRequirementsApw': null,
	'revisedRequirementsMpw': null,
	'nextApproverUserId': null,
	'workflowId': null,
	'recallReason': null,
	'isAlertRequired': null,
	'loggedInUserId': null,
	'proposedPlanCode': null,
	'proposedPlanObject': {name: '', code: -1},
	'proposedPlanDate': null,
	'capacityStudyId': null,
	'isAnyProcessInCARHasWarning': false,
	'copiedFromReqId': 0,
	'lastSubmittedDate': null,
	'isSiteEngReassign': false,
	'isPSUpdated': null,
	'isPSUpdatedToClearData': null,
	'keepHistoricalPSInRejRecall': null,
	'isProcessStreamAvailable': null,
	'removeProcessStreamData': null,
	'paRequirementsProdApw': null,
	'paRequirementsProdMpw': null,
	'paRequirementsServiceApw': null,
	'paRequirementsServiceMpw': null,
	'revisedRequirementsProdApw': null,
	'revisedRequirementsProdMpw': null,
	'revisedRequirementsServiceApw': null,
	'revisedRequirementsServiceMpw': null,
	'gcpMcpvProdApw': null,
	'gcpMcpvProdMpw': null,
	'gcpMcpvServiceApw': null,
	'gcpMcpvServiceMpw': null
};
export const testValueCarRequest = {
	'reqId': 0,
	'siteCodeObject': {name: 'C7E3F - Robert Bosch Braga', code: 'C7E3F'},
	'siteCode': 'C7E3F',
	'supplierName': 'Supplier Name',
	'partName': 'Part Name',
	'partNumDisplay': 'partNumDisplay',
	'prefix': 'A',
	'base': 'B',
	'suffix': 'C',
	'programCodeOthers': 'P702',
	'programs': '2020',
	'partPpapLevel': 3,
	'partPPAPLevelObject': {name: '3', code: 3},
	'submissionScenario': 1,
	'submissionScenarioObject': {
		templateName: 'Scenario1',
		id: 1,
		scenarioName: 'Capacity Planning',
		scenarioDesc: 'desc1',
		capacityStudy: false,
		rfqScenario: false,
		currentProdRun: null
	},
	'scenarioName': '',
	'capacityRequirementSourceObject': {name: 'WebQuote', code: 'WebQuote'},
	'capacityRequirementSource': 'WebQuote',
	'reqDescription': 'reqDescription',
	'paRequirementsApw': 611,
	'paRequirementsMpw': 676,
	'gcpMcpvApw': 100,
	'gcpMcpvMpw': 200,
	'primaryStreamId': 0,
	'requestStatus': '',
	'userAction': '',
	'assignedTo': '',
	'actionRequired': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'plannedCapacityApw': 0,
	'plannedCapacityMpw': 0,
	'phase0DemonstratedCapacityApw': 0,
	'phase0DemonstratedCapacityMpw': 0,
	'commitmentAppc': null,
	'commitmentMppc': null,
	'notes': '',
	'workflowStatus': '',
	'comment': '',
	'priority': 'Yes',
	'priorityObject': {name: 'Yes', code: 'Yes'},
	'q1StatusCode': 'Y',
	'q1StatusDesc': 'Q1 Achieved',
	'isAnyProcessContainsAPWGreaterThan5': false,
	'deviationReasonObject': {name: 'Operating Pattern Deviation Approval is Required', code: 3},
	'deviationReasonCode': 3,
	'keyContacts': [
		{
			'keyContactsId': 0,
			'carReqId': 0,
			'cdsid': 'cdsid1',
			'mail': 'mail1@gmail.com',
			'phone': '3232323',
			'type': 'STASiteEngineer',
			'createdBy': '',
			'createdDate': null,
			'updatedBy': '',
			'updatedDate': null,
			'name': null,
			'organizationName': null
		},
		{
			'keyContactsId': 0,
			'carReqId': 0,
			'cdsid': 'cdsid2',
			'mail': 'mail2@gmail.com',
			'phone': '555555',
			'type': 'Supplier Lead',
			'createdBy': '',
			'createdDate': null,
			'updatedBy': '',
			'updatedDate': null,
			'name': null,
			'organizationName': null
		},
		{
			'keyContactsId': 0,
			'carReqId': 0,
			'cdsid': 'cdsid3',
			'mail': 'mail3@gmail.com',
			'phone': '8787878',
			'type': 'Ford Buyer',
			'createdBy': '',
			'createdDate': null,
			'updatedBy': '',
			'updatedDate': null,
			'name': null,
			'organizationName': null
		}
	],
	'psPnMapTransList': [],
	'primarySupportingMapTranList': [],
	'declarationQuestionsTranList': [],
	'awaitingApproval': false,
	'isDeviationApproved': 'false',
	'submittedByEmailId': '',
	'submittedByName': '',
	'isLinkCar': false,
	'selectedCarIdForLinkageObject': {name: 'Select eCAR ID', code: 'invalid'},
	'selectedCarIdForLinkage': 0,
	'selectedStreamIdForLinkageObject': {name: 'Select Stream', code: 'invalid'},
	'selectedStreamIdForLinkage': 0,
	'selectedProcessNameIdForLinkageObject': {name: 'Select Process Name', code: 'invalid'},
	'selectedProcessNameIdForLinkage': 0,
	'parentCarStatus': '',
	'submissionMessage': '',
	'requirementType': 1,
	'revisedRequirementsApw': 100,
	'revisedRequirementsMpw': 200,
	'nextApproverUserId': null,
	'workflowId': null,
	'recallReason': null,
	'isAlertRequired': null,
	'loggedInUserId': null,
	'proposedPlanCode': null,
	'proposedPlanObject': {name: '', code: -1},
	'proposedPlanDate': null,
	'capacityStudyId': null,
	'isAnyProcessInCARHasWarning': false,
	'copiedFromReqId': 0,
	'lastSubmittedDate': null,
	'isSiteEngReassign': false,
	'isPSUpdated': null,
	'isPSUpdatedToClearData': null,
	'keepHistoricalPSInRejRecall': null,
	'isProcessStreamAvailable': null,
	'removeProcessStreamData': null,
	'paRequirementsProdApw': null,
	'paRequirementsProdMpw': null,
	'paRequirementsServiceApw': null,
	'paRequirementsServiceMpw': null,
	'revisedRequirementsProdApw': null,
	'revisedRequirementsProdMpw': null,
	'revisedRequirementsServiceApw': null,
	'revisedRequirementsServiceMpw': null,
	'gcpMcpvProdApw': null,
	'gcpMcpvProdMpw': null,
	'gcpMcpvServiceApw': null,
	'gcpMcpvServiceMpw': null
};

