import {PsPnMapTran} from './pspnmaptran';

export class PrimarySupportingMap {
	primaryProcessStreamId: number;
	primaryProcessNameId: number;
	supportingProcessStreamId: number;
	originProcessStreamId: number;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	psPnMapTran: PsPnMapTran[];

	constructor(primarySupportingMap: PrimarySupportingMap) {
		Object.assign(this, primarySupportingMap);
	}
}

export const dummyPrimarySupportingMap: PrimarySupportingMap = {
	primaryProcessNameId: 0,
	primaryProcessStreamId: 0,
	supportingProcessStreamId: 0,
	originProcessStreamId: 0,
	createdBy: '',
	createdDate: 0,
	updatedBy: '',
	updatedDate: 0,
	psPnMapTran: []
};
