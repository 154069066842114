import {SharedLoading} from './shared-loading';

export class SharedLoadingRoot {
	id: number;
	siteCode: string;
	processNameId: number;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	sharedLoadingList: SharedLoading[];
	demonstratedOEE: number;
	totalAllocationPercentageAPW: number;
	totalAllocationPercentageMPW: number;
	totalAllocationPercentageCapacityAPW: number;
	totalAllocationPercentageCapacityMPW: number;
	startDateStr: string;
	endDateStr: string;
	startDate: number;
	endDate: number;
	isAlertRequired: boolean;
	masterSlrId: number;

	constructor(sharedLoadingRoot: SharedLoadingRoot) {
		Object.assign(this, sharedLoadingRoot);
	}
}

export const emptySLRoot = {
	'id': 0,
	'siteCode': '',
	'processNameId': 0,
	'createdBy': '',
	'createdDate': 1580372096578,
	'updatedBy': '',
	'updatedDate': 1580372096578,
	'demonstratedOEE': 0,
	'totalAllocationPercentageAPW': 0,
	'totalAllocationPercentageMPW': 0,
	'sharedLoadingList': [],
	'totalAllocationPercentageCapacityAPW': 0,
	'totalAllocationPercentageCapacityMPW': 0,
	'startDate': null,
	'endDate': null,
	'startDateStr': null,
	'endDateStr': null,
	'isAlertRequired': null,
	'masterSlrId': null
};
