import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CarReqComments} from '../../models/car-req-comments';

@Injectable({
	providedIn: 'root'
})
export class CarRequestCommentsService {
	private config = new Config();
	private httpUrlBase = `${this.config.url}`;
	private httpUrl: string = this.httpUrlBase + '/carReqComments';

	constructor(private http: HttpClient) {
	}

	/**
	 * Fetch the available comments for the given request id
	 * @param id - car request id
	 * @returns list of CarReqComments
	 */
	findCommentsForReqId(id: number): Observable<CarReqComments[]> {
		const endpoint: string = this.httpUrl + '/findByCarReq/' + id;
		return this.http.get<CarReqComments[]>(endpoint);
	}

	/**
	 * Post the comments to server
	 * @param carReqComment - comment made by the user which need to be posted
	 * @param carReqId - car request id for which the user commented.
	 * @returns Newly created CarReqComment
	 */
	create(carReqComment: CarReqComments, carReqId: number): Observable<CarReqComments> {
		return this.http.post<CarReqComments>(this.httpUrl + '/' + carReqId, carReqComment);
	}
}
