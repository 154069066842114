import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SupplierSiteDetails} from '../../models/supplier-site-details';

@Injectable({
	providedIn: 'root'
})
export class SupplierSiteService {

	private config = new Config();
	private httpUrl = `${this.config.url}/supplierSiteDetails`;

	constructor(private http: HttpClient) {
	}

	findBySiteCode(siteCode: string): Observable<SupplierSiteDetails> {
		return this.http.get<SupplierSiteDetails>(this.httpUrl + '/' + siteCode);
	}
}
