export class AutomationTypes {
	name: string;
	code: string;

	constructor(automationTypes: AutomationTypes) {
		Object.assign(this, automationTypes);
	}
}

export const automationTypesMaster: AutomationTypes[] = [
	{name: 'Manual', code: '1'},
	{name: 'Semi-automatic', code: '2'},
	{name: 'Automatic', code: '3'}];

