import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {UserIdService} from '../services/user-id.service.service';
import {ApsService} from '../aps.service';
import {Subject} from 'rxjs';
import {Policies} from '../models/aps-policies';
import {ApsAttributes} from '../models/aps-attributes';
import {UtilService} from '../services/util.service';
import jwt_decode from 'jwt-decode';

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class UrlConsumerService implements CanActivate {

	policiesSubject = new Subject<Policies>();
	apsAttributesSubject = new Subject<ApsAttributes>();

	/** Creates an instance of the UrlConsumerService
	 *
	 * @param router route instance for current routing params
	 * @param location the angular location service for interacting with the browser location object
	 * @param idService the angular service for handling user ID
	 */
	constructor(private utilService: UtilService, private apsService: ApsService, private router: Router, private location: Location, private idService: UserIdService) {
	}

	/** the actual guard fuction. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		console.log('UrlConsumerService canActivate');
		const queryParamsObj = this.getQueryParams();

		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			try {
				const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
				const jsonToken = JSON.parse(accessToken);
				console.log('UrlConsumerService : Token Received....');

				sessionStorage.setItem('strAccessToken', accessToken);
				sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);

				sessionStorage.setItem('tokenIssue', jsonToken.iat);
				sessionStorage.setItem('tokenExp', jsonToken.exp);
				sessionStorage.setItem('userId', jsonToken.CommonName);
				sessionStorage.setItem('userName', jsonToken.givenName);
				let displayName = jsonToken.sn + ', ' + jsonToken.givenName;
				if (jsonToken.initials !== null && jsonToken.initials !== undefined) {
					displayName = displayName + ' (' + jsonToken.initials + ')';
				}
				sessionStorage.setItem('displayLeadName', displayName);
				sessionStorage.setItem('displayLeadMail', jsonToken.mail);
				sessionStorage.setItem('displayLeadPhone', jsonToken.telephoneNumber);
				sessionStorage.setItem('displayLeadCdsid', jsonToken.uid);

				this.idService.setUserID(jsonToken.CommonName);
				this.idService.setUserName(jsonToken.givenName);
				console.log('Retrieving policies.....');
				this.apsService.getAllDecisions().subscribe(policies => {
					const policiesString = JSON.stringify(policies);
					console.log(policiesString);
					sessionStorage.setItem('policies', policiesString);
					this.policiesSubject.next(policies);

					console.log('Retrieving roles, SiteCodes and OrgCodes.....');
					this.apsService.retrieveAttributes().subscribe(attributes => {
						const attributeString = JSON.stringify(attributes);
						console.log('attributeString::' + attributeString);
						sessionStorage.setItem('attributes', attributeString);
						this.apsAttributesSubject.next(attributes);

						// Redirect to originally requested URL
						if (sessionStorage.getItem('redirectURL')) {
							this.router.navigate([sessionStorage.getItem('redirectURL')]);
							return false;
						} else {
							console.log('redirectURL not present in session');
							this.router.navigate(['home-page']);
						}
					});
				});
				return true;
			} catch (e) {
				console.error('atob token decode exception');
				const jwtToken = jwt_decode(queryParamsObj['access_token']);
				console.log('entries ' + Object.entries(jwtToken));
				sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);

				sessionStorage.setItem('tokenIssue', jwtToken['iat']);
				sessionStorage.setItem('tokenExp', jwtToken['exp']);
				sessionStorage.setItem('userId', jwtToken['CommonName']);
				sessionStorage.setItem('userName', jwtToken['givenName']);
				let displayName = jwtToken['sn'] + ', ' + jwtToken['givenName'];
				if (jwtToken['initials'] !== null && jwtToken['initials'] !== undefined) {
					displayName = displayName + ' (' + jwtToken['initials'] + ')';
				}
				sessionStorage.setItem('displayLeadName', displayName);
				sessionStorage.setItem('displayLeadMail', jwtToken['mail']);
				sessionStorage.setItem('displayLeadPhone', jwtToken['telephoneNumber']);
				sessionStorage.setItem('displayLeadCdsid', jwtToken['uid']);

				this.idService.setUserID(jwtToken['CommonName']);
				this.idService.setUserName(jwtToken['givenName']);
				console.log('Retrieving policies.....');
				this.apsService.getAllDecisions().subscribe(policies => {
					const policiesString = JSON.stringify(policies);
					console.log(policiesString);
					sessionStorage.setItem('policies', policiesString);
					this.policiesSubject.next(policies);

					console.log('Retrieving roles, SiteCodes and OrgCodes.....');
					this.apsService.retrieveAttributes().subscribe(attributes => {
						const attributeString = JSON.stringify(attributes);
						console.log('attributeString::' + attributeString);
						sessionStorage.setItem('attributes', attributeString);
						this.apsAttributesSubject.next(attributes);

						// Redirect to originally requested URL
						if (sessionStorage.getItem('redirectURL')) {
							this.router.navigate([sessionStorage.getItem('redirectURL')]);
							return false;
						} else {
							this.router.navigate(['home-page']);
						}
					});
				});
				return true;
			}
		} else {
			console.error('Invalid Token');
		}

		return false;
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @returns The queryString params in Object format, or null if the string was invalid.
	 */
	getQueryParams() {
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();

			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}

	}
}
