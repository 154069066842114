import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy, Location} from '@angular/common';
import {LoginComponent} from './login.component';
import {OAuthCallbackComponent} from './oauth-callback.component';
import {AuthGuardService} from './auth-guard.service';
import {UrlConsumerService} from './url-consumer.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [OAuthCallbackComponent, LoginComponent, OAuthCallbackComponent],
	providers: [
		{provide: LocationStrategy, useClass: HashLocationStrategy},
		Location,
		AuthGuardService,
		UrlConsumerService
	]
})
export class OauthModule {
}
