import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '../../../services/util.service';
import {emptyRevision, RevisionHistory} from '../../../models/revision-history';
import {
	ManageRevisionHistoryService
} from '../../../services/master/manage-revision-history/manage-revision-history.service';

@Component({
	selector: 'create-revision-history',
	templateUrl: './create-revision-history.component.html',
	styleUrls: ['./create-revision-history.component.css']
})
export class CreateRevisionHistoryComponent implements OnInit {
	private mode: string;
	private displayName: string;
	private revisionObj: RevisionHistory = new RevisionHistory(emptyRevision);

	private buttonName: string;
	private disableCreateButton = false;
	private dateDisplay: Date;
	private yearRange: string;

	constructor(private activatedRoute: ActivatedRoute, private revisionService: ManageRevisionHistoryService, private router: Router, private utilService: UtilService) {

	}

	ngOnInit() {
		this.retrieveRouteParameter();
		if (this.mode === 'create') {
			this.initializeScreen();
		} else if (this.mode === 'edit') {
			this.findById(this.revisionObj.id);
		}
		this.validateCreateButton();
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.revisionObj.id = Number(params.get('id'));
			if (this.mode === 'create') {
				this.displayName = 'Create New Revision History';
				this.buttonName = 'Create New';
			} else if (this.mode === 'edit') {
				this.displayName = 'Edit Revision History';
				this.buttonName = 'Update';
			}
			console.log('this.displayName ' + this.displayName);
		});
		let currentDate = new Date();
		this.yearRange = 2000 + ':' + (currentDate.getFullYear() + 100);
	}

	private initializeScreen() {
		this.revisionObj = new RevisionHistory(emptyRevision);
	}

	private onCreate(newRevision: RevisionHistory) {
		if (this.validateFields(newRevision)) {
			this.revisionService.create(newRevision).subscribe(success => {
				this.utilService.pushMsg('success', 'Success', 'New Revision History has been created successfully');
				this.goToManageScreen();
			}, createError => {
				this.utilService.pushMsg('error', 'Error', 'Error while creating new Revision History');
			});
		}
	}

	private onUpdate(updateRevision: RevisionHistory) {
		if (this.validateFields(updateRevision)) {
			this.revisionService.update(updateRevision.id, updateRevision).subscribe(success => {
				this.utilService.pushMsg('success', 'Success', 'Revision History has been successfully modified');
				this.goToManageScreen();
			}, createError => {
				this.utilService.pushMsg('error', 'Error', 'Error while updating Revision History');
			});
		}
	}

	private validateFields(revision: RevisionHistory): boolean {
		if (revision.description === null || revision.description.trim() === '') {
			this.utilService.pushMsg('error', 'Error', 'Please enter Revision History Description');
			return false;
		} else if (revision.date === null || !revision.date) {
			this.utilService.pushMsg('error', 'Error', 'Please enter Start Date value');
			return false;
		} else {
			return true;
		}
	}

	private validateCreateButton() {
		if (this.revisionObj.revisionNumber !== null && this.revisionObj.revisionNumber > 0 && this.revisionObj.description && this.revisionObj.description.trim() !== '' && (this.dateDisplay || this.revisionObj.date)) {
			this.disableCreateButton = false;
		} else {
			this.disableCreateButton = true;
		}
	}


	private findById(id: number) {
		this.revisionService.findById(id).subscribe(response => {
			this.revisionObj = response;
			this.dateDisplay = new Date(this.revisionObj.date);
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onSubmit() {
		this.revisionObj.date = this.dateDisplay.toISOString();
		if (this.mode === 'create') {
			this.onCreate(this.revisionObj);
		} else if (this.mode === 'edit') {
			this.onUpdate(this.revisionObj);
		}

	}

	private goToManageScreen() {
		this.router.navigate(['manage-revision-history']);
	}

	onCancel() {
		this.utilService.pushMsg('info', 'Information', 'Operation Cancelled');
		this.goToManageScreen();
	}
}
