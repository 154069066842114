import {Injectable} from '@angular/core';
import {Message} from 'primeng/api';
import {Observable, of, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CarmessageService {

	public messageUpdated: Subject<Message> = new Subject<Message>();
	private timeoutFiveSeconds = 5000;
	messageArray: Message[];

	constructor() {
	}

	getMessage(): Observable<Message[]> {
		return of(this.messageArray);
	}

	displayMessage(newMessage: Message) {
		console.log('inside message service');
		//this.messageArray.push(newMessage);
		console.log(newMessage);
		this.messageUpdated.next(newMessage);
		/*setTimeout(() => {
			console.log('inside message service timeout called');
			this.messageUpdated.next({});
		}, this.timeoutFiveSeconds);*/
	}
}
