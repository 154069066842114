export class Bulletin {

	id: number;
	title: string;
	description: string;
	startDate: string;
	endDate: string;
	linkText: string;
	updatedBy: string;
	updatedDate: number;
	linksArray: string[];

	constructor(bulletin: Bulletin) {
		Object.assign(this, bulletin);
	}
}

export const emptyBulletin = {
	'id': 0,
	'title': '',
	'description': '',
	'startDate': '',
	'endDate': '',
	'linkText': '',
	'updatedBy': '',
	'updatedDate': 0,
	'linksArray': ['']
};
