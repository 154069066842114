import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Bulletin} from '../../../models/bulletin';

@Injectable({
	providedIn: 'root'
})
export class ManageBulletinsService {

	private config = new Config();
	private httpUrl = `${this.config.url}/bulletin`;

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<Bulletin[]> {
		return this.http.get(this.httpUrl).pipe(map((bulletins: Bulletin[]) => {
			return bulletins.map(bulletin => {
				return new Bulletin(bulletin);
			});
		}));
	}

	create(bulletin: Bulletin): Observable<Bulletin> {
		return this.http.post<Bulletin>(this.httpUrl, bulletin);
	}

	update(id: number, bulletin: Bulletin): Observable<Bulletin> {
		return this.http.put<Bulletin>(this.httpUrl + '/' + id, bulletin);
	}

	findById(id: number): Observable<Bulletin> {
		return this.http.get<Bulletin>(this.httpUrl + '/' + id);
	}

	delete(id: number) {
		return this.http.delete<boolean>(this.httpUrl + '/' + id);
	}

	findByCurrentDate(): Observable<Bulletin[]> {
		return this.http.get(this.httpUrl + '/findByCurrentDate').pipe(map((bulletins: Bulletin[]) => {
			return bulletins.map(bulletin => {
				return new Bulletin(bulletin);
			});
		}));
	}
}
