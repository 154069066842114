import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DialogService} from 'primeng/api';
import {Policies} from '../../../models/aps-policies';
import {UtilService} from '../../../services/util.service';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {Bulletin} from '../../../models/bulletin';
import {ManageBulletinsService} from '../../../services/master/manage-bulletins/manage-bulletins.service';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'manage-bulletins',
	templateUrl: './manage-bulletins.component.html',
	styleUrls: ['./manage-bulletins.component.css']
})
export class ManageBulletinsComponent implements OnInit {
	policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;

	constructor(private utilService: UtilService, private router: Router, public dialogService: DialogService, private bulletinService: ManageBulletinsService, private title: Title) {
	}

	bulletins: Bulletin[];

	ngOnInit() {
		this.findAll();
		this.findAllPolicies();
		this.title.setTitle('eCAR - Bulletins');
	}

	onCreate() {
		this.router.navigate(['create-bulletins/create/0']);
	}

	private findAll() {
		this.bulletinService.findAll().subscribe((response: Bulletin[]) => {
			this.bulletins = response;
			this.bulletins.forEach(bulletin => {
				bulletin.linksArray = bulletin.linkText.split(' ');
			});
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onEdit(id: number) {
		this.router.navigate(['create-bulletins/edit/' + id]);
	}

	onDelete(id: number) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.bulletinService.delete(id).subscribe(value => {
						this.utilService.pushMsg('success', 'Success', 'Bulletin has been deleted successfully');
						this.findAll();
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Unable to delete Bulletin!');
					});
				}
			}
		});
	}

	private retrieveLink(plainText): string {
		return this.utilService.linkify(plainText);
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:ManageBulletins';
		this.disableCreateButton = !this.utilService.checkPermittedUpdatePolicy(this.policies, policyName);
		this.disableDeleteButton = !this.utilService.checkPermittedDeletePolicy(this.policies, policyName);
	}
}
