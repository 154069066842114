export class OnDeleteDisplayDialogueModel {
	'message': string;
	'isDeleted': boolean;
	'isCopy': boolean;
	'selectedCopyOption': number;

	constructor(onDeleteDisplayModel: OnDeleteDisplayDialogueModel) {
		Object.assign(this, onDeleteDisplayModel);
	}
}

export const emptyOnDeleteDisplayModel = {
	'message': '',
	'isDeleted': false,
	'isCopy': false,
	'selectedCopyOption': 0
};
