import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProcessNameComponent} from './process-name/process-name.component';
import {TableModule} from 'primeng/table';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {AccordionModule} from 'primeng/accordion';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {ManageProcessStreamComponent} from './process-stream/manage-process-stream/manage-process-stream.component';
import {CreateProcessStreamComponent} from './process-stream/create-process-stream/create-process-stream.component';
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';
import {PickListModule} from 'primeng/picklist';
import {CarmessageService} from '../services/message/carmessage.service';
import {HistoricalMfgPerfComponent} from './historical-mfgperf/historical-mfgperf.component';
import {CalendarModule} from 'primeng/calendar';
import {ViewProcessStreamComponent} from './process-stream/view-process-stream/view-process-stream.component';
import {CreateSharedLoadingComponent} from './shared-loading/create-shared-loading/create-shared-loading.component';
import {ManageSharedLoadingComponent} from './shared-loading/manage-shared-loading/manage-shared-loading.component';
import {ManageSharedLoadingService} from '../services/master/manage-shared-loading/manage-shared-loading.service';
import {TooltipModule} from 'primeng/tooltip';
import {ManageSubmissionScenarioComponent} from './manage-submission-scenario/manage-submission-scenario.component';
import {CreateDeclarationQuestionsComponent} from './declaration-questions/create-declaration-questions/create-declaration-questions.component';
import {ManageDeclarationQuestionsComponent} from './declaration-questions/manage-declaration-questions/manage-declaration-questions.component';
import {ProcessTypeComponent} from './process-type/process-type.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {OneDigitDecimalNumberDirective} from '../directive/one-digit-decimal-number/one-digit-decimal-number.directive';
import {FileUploadModule} from 'primeng/fileupload';
import {FieldsetModule} from 'primeng/fieldset';
import {NumericDecimalDirective} from '../directive/numeric-decimal/numeric-decimal.directive';
import {KeyFilterModule} from 'primeng/keyfilter';
import {CreateBulletinsComponent} from './bulletins/create-bulletins/create-bulletins.component';
import {ManageBulletinsComponent} from './bulletins/manage-bulletins/manage-bulletins.component';
import {ShowBulletinsDialogComponent} from './bulletins/show-bulletins-dialog/show-bulletins-dialog/show-bulletins-dialog.component';
import {DialogModule} from 'primeng/dialog';
import {ChartModule} from 'primeng/chart';
import {EngineerApprovalFlowConditionsComponent} from './approval-flow-conditions/engineer-approval-flow-conditions/engineer-approval-flow-conditions.component';
import {SupervisorApprovalFlowConditionsComponent} from './approval-flow-conditions/supervisor-approval-flow-conditions/supervisor-approval-flow-conditions.component';
import { ManageHistoricalMfgperfComponent } from './historical-mfgperf/manage-historical-mfgperf/manage-historical-mfgperf.component';
import {ReplacePartNumberComponent} from './replace-part-number/replace-part-number.component';
import {
	ManageRevisionHistoryComponent
} from './revision-history/manage-revision-history/manage-revision-history.component';
import {
	CreateRevisionHistoryComponent
} from './revision-history/create-revision-history/create-revision-history.component';


@NgModule({
	imports: [
		CommonModule,
		TableModule,
		MessagesModule,
		MessageModule,
		ToastModule,
		FormsModule,
		ButtonModule,
		AccordionModule,
		InputTextModule,
		CheckboxModule,
		DropdownModule,
		RadioButtonModule,
		PickListModule,
		CalendarModule,
		TooltipModule,
		ReactiveFormsModule,
		ConfirmDialogModule,
		FileUploadModule,
		FieldsetModule,
		KeyFilterModule,
		DialogModule,
		ChartModule
	],
	declarations: [ProcessNameComponent, ManageProcessStreamComponent, CreateProcessStreamComponent, HistoricalMfgPerfComponent, ViewProcessStreamComponent, CreateSharedLoadingComponent, ManageSharedLoadingComponent, ManageSubmissionScenarioComponent, CreateDeclarationQuestionsComponent, ManageDeclarationQuestionsComponent, ProcessTypeComponent, OneDigitDecimalNumberDirective, NumericDecimalDirective, CreateBulletinsComponent, ManageBulletinsComponent, ShowBulletinsDialogComponent, EngineerApprovalFlowConditionsComponent, SupervisorApprovalFlowConditionsComponent, ManageHistoricalMfgperfComponent, ReplacePartNumberComponent, CreateRevisionHistoryComponent, ManageRevisionHistoryComponent],
	exports: [OneDigitDecimalNumberDirective, HistoricalMfgPerfComponent, HistoricalMfgPerfComponent, NumericDecimalDirective, ShowBulletinsDialogComponent, ViewProcessStreamComponent],
	providers: [CarmessageService, ManageSharedLoadingService, ConfirmationService]
})
export class MasterModule {
}
