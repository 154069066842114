export class RevisionHistory {

	id: number;
	revisionNumber: number;
	description: string;
	date: string;
	updatedBy: string;
	updatedDate: number;

	constructor(revisionHistory: RevisionHistory) {
		Object.assign(this, revisionHistory);
	}
}

export const emptyRevision = {
	'id': 0,
	'revisionNumber': null,
	'description': '',
	'date': '',
	'updatedBy': '',
	'updatedDate': 0
};
