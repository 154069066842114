import {ProcessData} from './process-data';

export class PsPnMapTran {
	reqId: number;
	processStreamId: number;
	processNameId: number;
	processName: string;
	processOrderId: number;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	processDataList: ProcessData[];
	isEditButtonDisabled: Boolean;
	formSubmitted: boolean;
	isPrimaryStream: boolean;
	processDesc: string;
	sharedLoadingLink: string;
	masterDataFlag: boolean;
	partNameAvailableCheck: boolean;
	historicalmfgperfsCheck: boolean;
	demonOEE: number;
	isHmpItemExceeds100: boolean;
	isOneOrMoreOEEPercentageGreaterThan100: boolean;
	hmpOlderThan32WeeksWarningShown: boolean;
	hmpRecordsCountLessThan25WarningShown: boolean;
	isProcessUsedInAnotherPartNumber: boolean;
	isPartNameAvailableInSharedLoading: boolean;
	cycleTimeHigher: boolean;
	cycleTimelower: boolean;
	recordsUsedInAnotherPartNumbers: number[];
	reqGoodPartsAPW: number;
	reqGoodPartsMPW: number;
	nictAPW: number;
	showSLPartNumMismatchMsg: boolean;
	capacityRecordNotFound: boolean;
	demonOEEFlag: string;
	reqGoodPartsAPWFlag: string;
	reqGoodPartsMPWFlag: string;

	constructor(psPnMapTran: PsPnMapTran) {
		Object.assign(this, psPnMapTran);
	}
}

export const emptyPsPnMapTran = {
	'reqId': 0,
	'processStreamId': 0,
	'processNameId': 0,
	'processName': null,
	'processOrderId': 0,
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'processDataList': [],
	'isEditButtonDisabled': true,
	'formSubmitted': false,
	'isPrimaryStream': true,
	'processDesc': '',
	'sharedLoadingLink': '',
	'masterDataFlag': false,
	'partNameAvailableCheck': false,
	'historicalmfgperfsCheck': false,
	'demonOEE': 0,
	'isHmpItemExceeds100': false,
	'isOneOrMoreOEEPercentageGreaterThan100': false,
	'hmpOlderThan32WeeksWarningShown': false,
	'hmpRecordsCountLessThan25WarningShown': false,
	'isProcessUsedInAnotherPartNumber': false,
	'isPartNameAvailableInSharedLoading': false,
	'cycleTimeHigher': false,
	'cycleTimelower': false,
	'recordsUsedInAnotherPartNumbers': [],
	'reqGoodPartsAPW': 0,
	'reqGoodPartsMPW': 0,
	'nictAPW': 0,
	'showSLPartNumMismatchMsg': false,
	'capacityRecordNotFound': true,
	'demonOEEFlag': '',
	'reqGoodPartsAPWFlag': '',
	'reqGoodPartsMPWFlag': ''
};
