export class ProcessName {
	id: number;
	processName: string;
	processDesc: string;
	siteCode: string;
	processTypeName: string;
	automationType: string;
	processTypeId: number;
	active: boolean;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	processBoxSizewithBottleNeck: string;
	processBoxSizewithOutBottleNeck: string;
	expandSelectedProcess: boolean;

	constructor(processName: ProcessName) {
		Object.assign(this, processName);
	}
}

export const emptyProcessNameDropDown = {
	active: false,
	createdBy: '',
	createdDate: 0,
	id: 0,
	processDesc: '',
	processName: 'Select Process Name',
	siteCode: '',
	processTypeName: '',
	automationType: '',
	processTypeId: 0,
	updatedBy: '',
	updatedDate: 0,
	processBoxSizewithBottleNeck : '380px',
	processBoxSizewithOutBottleNeck: '340px',
	expandSelectedProcess: false
};

export const emptyProcessNameDropDownForCarReq = {
	active: false,
	createdBy: '',
	createdDate: 0,
	id: -1,
	processDesc: '',
	processName: 'Select Process Name',
	updatedBy: '',
	updatedDate: 0,
	processBoxSizewithBottleNeck : '380px',
	processBoxSizewithOutBottleNeck: '340px',
	expandSelectedProcess: false
};

export const testDataProcessName = {
	active: true,
	createdBy: '',
	createdDate: 0,
	id: 1,
	processDesc: '',
	processName: 'Grommet Operation',
	updatedBy: '',
	updatedDate: 0,
	processBoxSizewithBottleNeck : '380px',
	processBoxSizewithOutBottleNeck: '340px',
	expandSelectedProcess: false
};
