import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'quick-info',
	templateUrl: './quick-info.component.html',
	styleUrls: ['./quick-info.component.css']
})
export class QuickInfoComponent implements OnInit {

	@Input() siteCode: string;
	@Input() partName: string;
	@Input() partNumber: string;
	@Input() requirementsAPW: number;
	@Input() requirementsMPW: number;
	@Input() volumesUsed?: number;
	@Input() submissionScenario: string;
	@Input() parentCarId?: string;
	@Input() parentStreamName?: string;
	@Input() parentConnectedProcessName?: string;
	@Input() eCARID: string;
	@Input() submissionStatus?: string;
	@Input() capacityStudyNumber?: string;
	@Input() capacityStudyScenario?: boolean;
	@Input() isLinked?: boolean;

	constructor() {
	}

	ngOnInit() {
	}
}
