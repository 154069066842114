import {Component, Input, OnInit} from '@angular/core';
import {CarRequest} from '../../../models/car-request';
import {ProcessStream} from '../../../models/process-stream';
import {OEEChart, oeeChartTemplate} from '../../../models/chart-data';
import * as _ from 'lodash';

@Component({
	selector: 'oee-chart',
	templateUrl: './oee-chart.component.html',
	styleUrls: ['./oee-chart.component.css']
})
export class OeeChartComponent implements OnInit {

	@Input() carRequest: CarRequest;
	@Input() selectedPrimaryStream: ProcessStream;
	private primaryProcessStreamChart: OEEChart;
	private supportingProcessStreamChart: OEEChart[] = [];
	private displayOEEDialog = false;
	private isPrimaryChartAvailable = false;
	private isSupportingChartAvailable = false;

	constructor() {
	}

	ngOnInit() {
		this.primaryProcessStreamChart = _.cloneDeep(oeeChartTemplate);
	}

	onClose() {
		console.log('onclose called');
		this.displayOEEDialog = false;
	}

	onClickOEE() {
		this.displayOEEDialog = true;
		this.buildOEEChartData();
	}

	private buildOEEChartData() {
		this.buildOEEPrimaryStreamChartData();
		this.buildOEESupportingStreamChartData();
	}

	private buildOEEPrimaryStreamChartData() {
		const reqAPW = [];
		const reqMPW = [];
		const demonstratedOee = [];
		const labels = [];
		let primaryDemonOEEMax;
		let primaryReqOEEMax;
		let yMax;
		this.primaryProcessStreamChart.isDemonOEEExceeds100Percent = false;
		this.primaryProcessStreamChart.isReqOEEExceeds100Percent = false;
		this.primaryProcessStreamChart.reqOeeProcessesGreaterThan100 = '';
		this.primaryProcessStreamChart.reqOeeProcessesGreaterThan100 = '';

		if (this.carRequest.psPnMapTransList.length > 0) {
			this.isPrimaryChartAvailable = true;
		}
		for (let i = this.carRequest.psPnMapTransList.length - 1; i >= 0; i--) {
			let pspnMapTran = this.carRequest.psPnMapTransList[i];
			reqAPW.push(pspnMapTran.processDataList[0].reqOee);
			reqMPW.push(pspnMapTran.processDataList[1].reqOee);
			if (!this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
				demonstratedOee.push(pspnMapTran.processDataList[0].averageHisOee);
			} else {
				demonstratedOee.push(pspnMapTran.processDataList[0].phaseOEE);
			}
			labels.push(pspnMapTran.processName);
			if (pspnMapTran.processDataList[0].reqOee > 100 || pspnMapTran.processDataList[1].reqOee > 100) {
				this.primaryProcessStreamChart.reqOeeProcessesGreaterThan100 = this.primaryProcessStreamChart.reqOeeProcessesGreaterThan100 === '' ? pspnMapTran.processName : this.primaryProcessStreamChart.reqOeeProcessesGreaterThan100 + ', ' + pspnMapTran.processName;
			}
			if (pspnMapTran.processDataList[0].averageHisOee > 100 || pspnMapTran.processDataList[1].averageHisOee > 100) {
				this.primaryProcessStreamChart.demonOeeProcessesGreaterThan100 = this.primaryProcessStreamChart.demonOeeProcessesGreaterThan100 === '' ? pspnMapTran.processName : this.primaryProcessStreamChart.demonOeeProcessesGreaterThan100 + ', ' + pspnMapTran.processName;
			}
		}
		primaryDemonOEEMax = Math.max(...demonstratedOee);
		const primaryReqOEEAPWMax = Math.max(...reqAPW);
		const primaryReqOEEMPWMax = Math.max(...reqMPW);
		primaryReqOEEMax = primaryReqOEEAPWMax > primaryReqOEEMPWMax ? primaryReqOEEAPWMax : primaryReqOEEMPWMax;
		if (primaryDemonOEEMax < 100 && primaryReqOEEMax < 100) {
			yMax = 100;
		} else {
			if (primaryDemonOEEMax > 100) {
				yMax = Math.ceil(primaryDemonOEEMax / 20) * 20;
				this.primaryProcessStreamChart.isDemonOEEExceeds100Percent = true;
			}
			if (primaryReqOEEMax > 100) {
				yMax = yMax > Math.ceil(primaryReqOEEMax / 20) * 20 ? yMax : Math.ceil(primaryReqOEEMax / 20) * 20;
				this.primaryProcessStreamChart.isReqOEEExceeds100Percent = true;
			}
		}
		this.primaryProcessStreamChart.processStreamName = this.selectedPrimaryStream.processStreamName;
		this.primaryProcessStreamChart.oeeChart = {
			labels: labels,
			datasets: [{
				type: 'bar',
				label: 'Required OEE (APW)',
				backgroundColor: '#1E88E5',
				data: reqAPW,
				borderColor: 'white',
				borderWidth: 2
			},
				{
					type: 'bar',
					label: 'Demonstrated OEE',
					borderColor: 'white',
					backgroundColor: '#E67E22',
					borderWidth: 2,
					data: demonstratedOee
				},
				{
					type: 'bar',
					label: 'Required OEE (MPW)',
					backgroundColor: '#9CCC65',
					data: reqMPW,
					borderColor: 'white',
					borderWidth: 2
				}]
		};
		this.primaryProcessStreamChart.oeeChartOptions = {
			scales: {
				yAxes: [{
					ticks: {
						min: 0,
						max: yMax,
						//suggestedMax: 100,
						beginAtZero: true
					}
				}]
			}
		};
	}

	private buildOEESupportingStreamChartData() {
		this.supportingProcessStreamChart = [];
		this.carRequest.primarySupportingMapTranList.forEach(supportingMap => {
			this.isSupportingChartAvailable = true;
			const supportingReqAPW = [];
			const supportingReqMPW = [];
			const supportingDemonstratedOee = [];
			const supportinglabels = [];
			let supportingDemonOEEMax;
			let supportingReqOEEMax;
			let yMax;

			const sChart = _.clone(oeeChartTemplate);
			sChart.processStreamName = supportingMap.processStreamName;
			for (let i = supportingMap.psPnMapTranList.length - 1; i >= 0; i--) {
				let pspnMap = supportingMap.psPnMapTranList[i];
				supportinglabels.push(pspnMap.processName);
				supportingReqAPW.push(pspnMap.processDataList[0].reqOee);
				supportingReqMPW.push(pspnMap.processDataList[1].reqOee);
				if (!this.carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
					supportingDemonstratedOee.push(pspnMap.processDataList[0].averageHisOee);
				} else {
					supportingDemonstratedOee.push(pspnMap.processDataList[0].phaseOEE);
				}
				if (pspnMap.processDataList[0].reqOee > 100 || pspnMap.processDataList[1].reqOee > 100) {
					sChart.reqOeeProcessesGreaterThan100 = sChart.reqOeeProcessesGreaterThan100 === '' ? pspnMap.processName : sChart.reqOeeProcessesGreaterThan100 + ', ' + pspnMap.processName;
				}
				if (pspnMap.processDataList[0].averageHisOee > 100 || pspnMap.processDataList[1].averageHisOee > 100) {
					sChart.demonOeeProcessesGreaterThan100 = sChart.demonOeeProcessesGreaterThan100 === '' ? pspnMap.processName : sChart.demonOeeProcessesGreaterThan100 + ', ' + pspnMap.processName;
				}
			}
			supportingDemonOEEMax = Math.max(...supportingDemonstratedOee);
			const supportingReqOEEAPWMax = Math.max(...supportingReqAPW);
			const supportingReqOEEMPWMax = Math.max(...supportingReqMPW);
			supportingReqOEEMax = supportingReqOEEAPWMax > supportingReqOEEMPWMax ? supportingReqOEEAPWMax : supportingReqOEEMPWMax;
			if (supportingDemonOEEMax < 100 && supportingReqOEEMax < 100) {
				yMax = 100;
			} else {
				if (supportingDemonOEEMax > 100) {
					yMax = Math.ceil(supportingDemonOEEMax / 20) * 20;
					sChart.isDemonOEEExceeds100Percent = true;
				}
				if (supportingReqOEEMax > 100) {
					yMax = yMax > Math.ceil(supportingReqOEEMax / 20) * 20 ? yMax : Math.ceil(supportingReqOEEMax / 20) * 20;
					sChart.isReqOEEExceeds100Percent = true;
				}
			}
			sChart.oeeChart = {
				labels: supportinglabels,
				datasets: [{
					type: 'bar',
					label: 'Required OEE (APW)',
					backgroundColor: '#1E88E5',
					data: supportingReqAPW,
					borderColor: 'white',
					borderWidth: 2
				},
					{
						type: 'bar',
						label: 'Demonstrated OEE',
						borderColor: 'white',
						backgroundColor: '#E67E22',
						borderWidth: 2,
						data: supportingDemonstratedOee
					},
					{
						type: 'bar',
						label: 'Required OEE (MPW)',
						backgroundColor: '#9CCC65',
						data: supportingReqMPW,
						borderColor: 'white',
						borderWidth: 2
					}
				]
			};
			sChart.oeeChartOptions = {
				scales: {
					yAxes: [{
						ticks: {
							min: 0,
							max: yMax,
							//suggestedMax: 100,
							beginAtZero: true
						}
					}]
				}
			};
			this.supportingProcessStreamChart.push(sChart);
		});
	}
}
