import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
	selector: '[numericDecimal]'
})
export class NumericDecimalDirective {

	@Input() digit: number;
	@Input() decimal: number;

	private regexWithDecimal: string;
	private regexWithNoDecimal: string;
	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

	constructor(private el: ElementRef) {
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const digit = /^[0-9.]?$/;
		this.regexWithDecimal = '^(?=.*\\d)\\d{0,' + this.digit + '}(\\.\\d{0,' + this.decimal + '}?)?$';
		this.regexWithNoDecimal = '^(?=.*\\d)\\d{0,' + this.digit + '}?$';
		const regExStr = this.decimal > 0 ? this.regexWithDecimal : this.regexWithNoDecimal;
		const regEx = new RegExp(regExStr);
		// console.log('keydown event called');
		// Check if the text is selected allow to continue
		if (this.el.nativeElement.value.toString() !== '' && document.getSelection().toString() === this.el.nativeElement.value.toString()) {
			return true;
		}
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		const input = event.key;
		const value = this.el.nativeElement.value;
		const newValue = value + input;

		if (!regEx.test(newValue)) {
			return false; //event.preventDefault();
		}
	}

	@HostListener('blur', ['$event'])
	onFocusOut(event: KeyboardEvent) {
		// console.log('focus out event called');
		const value = this.el.nativeElement.value;
		if (value[value.length - 1] === '.') {
			this.el.nativeElement.value += 0;
		}
	}
}
