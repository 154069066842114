import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CarRequest} from '../../../models/car-request';
import {OEEChart, oeeChartTemplate} from '../../../models/chart-data';
import {HistoricalMfgPerfService} from '../../../services/master/historical-mfgperf/historical-mfgperf.service';
import {HistoricalMfgPerf} from '../../../models/historical-mfgperf';
import * as _ from 'lodash';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {PsPnMapTran} from '../../../models/pspnmaptran';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UtilService} from '../../../services/util.service';

@Component({
	selector: 'hstmfg-chart',
	templateUrl: './hstmfg-chart.component.html',
	styleUrls: ['./hstmfg-chart.component.css'],
	providers: [DatePipe]
})
export class HstmfgChartComponent implements OnInit, OnDestroy {
	@Input() carRequest: CarRequest;
	private selectedSiteCode: string;

	displayHstMfgDialog = false;
	HstMfgPrimaryStreamChart: OEEChart[] = [];
	HstMfgSupportingStreamCharts: OEEChart[][] = [];
	private historicalMfgPerfObj: any;
	private HMPBasePath: string;
	isSupportingChartAvailable = false;
	isPrimaryChartAvailable = false;
	private unsubscribe$: Subject<any> = new Subject<any>();

	constructor(private utilService: UtilService, private historicalService: HistoricalMfgPerfService, private datepipe: DatePipe) {
	}

	ngOnInit() {
		this.HMPBasePath = window.location.origin + '/#/historical-mfgperf-master-page/';
		this.historicalService.triggerHMPChartSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(carRequest => {
			this.carRequest = carRequest;
			this.selectedSiteCode = this.carRequest.siteCode;
			this.loadChartData();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	loadChartData() {
		if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
			this.historicalService.findAllHMPByCarId(this.carRequest.reqId).subscribe((resp: HistoricalMfgPerf[]) => {
				this.buildPrimaryStreamHistoricalMfgChartData(resp);
				this.buildSupportingStreamHistoricalMfgChartData(resp);
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		} else {
			const processIdsTemp = [];
			this.carRequest.psPnMapTransList.forEach(value => {
				processIdsTemp.push(value.processNameId);
			});
			this.carRequest.primarySupportingMapTranList.forEach(supportingProcessList => {
				supportingProcessList.psPnMapTranList.forEach(supportingProcess => {
					processIdsTemp.push(supportingProcess.processNameId);
				});
			});

			const processNameIds = processIdsTemp.filter((c, index) => {
				return processIdsTemp.indexOf(c) === index;
			});
			if (processNameIds && processNameIds.length > 0) {
				this.historicalService.findAllHMPBySiteCodeProcessIds(this.carRequest.siteCode, processNameIds).subscribe((resp: HistoricalMfgPerf[]) => {
					this.buildPrimaryStreamHistoricalMfgChartData(resp);
					this.buildSupportingStreamHistoricalMfgChartData(resp);
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		}
	}

	onClickHstMfg() {
		this.displayHstMfgDialog = true;
	}

	onClose() {
		this.displayHstMfgDialog = false;
	}

	private buildPrimaryStreamHistoricalMfgChartData(hmpData: HistoricalMfgPerf[]) {
		this.HstMfgPrimaryStreamChart = [];
		this.isPrimaryChartAvailable = true;
		this.HstMfgPrimaryStreamChart = this.buildProcessDataChartData(hmpData, this.carRequest.psPnMapTransList);
	}

	private buildSupportingStreamHistoricalMfgChartData(hmpData: HistoricalMfgPerf[]) {
		this.HstMfgSupportingStreamCharts = [];
		let HstMfgSupportingStreamChart: OEEChart[];
		this.carRequest.primarySupportingMapTranList.forEach(supportingMap => {
			this.isSupportingChartAvailable = true;
			const supportingStreamName = supportingMap.processStreamName;
			HstMfgSupportingStreamChart = this.buildProcessDataChartData(hmpData, supportingMap.psPnMapTranList);
			HstMfgSupportingStreamChart.map(value => value.processStreamName = supportingStreamName + ': ' + value.processStreamName);
			this.HstMfgSupportingStreamCharts.push(HstMfgSupportingStreamChart);
		});
	}

	buildProcessDataChartData(hmpData: HistoricalMfgPerf[], psPnMapTransList: PsPnMapTran[]) {
		const chartData = [];
		for (let i = psPnMapTransList.length - 1; i >= 0; i--) {
			let psPnTran = psPnMapTransList[i];
			//psPnMapTransList.forEach(psPnTran => {
			const oee = [];
			const rOEE = [];
			const labels = [];
			let requiredOEE = 0;
			let demonOEEMax;
			let yMax;
			this.historicalMfgPerfObj = {
				processId: psPnTran.processNameId,
				siteCode: this.selectedSiteCode
			};

			const filteredHmpData = hmpData.filter(value => value.processId === psPnTran.processNameId);

			const rAPW = psPnTran.processDataList[0].reqOee;
			const rMPW = psPnTran.processDataList[1].reqOee;
			requiredOEE = rAPW > rMPW ? rAPW : rMPW;

			//Sort the response by date
			filteredHmpData.sort(function (left, right) {
				return moment.utc(left.hmfDate).diff(moment.utc(right.hmfDate));
			});
			filteredHmpData.forEach((historicalObj) => {
				oee.push(historicalObj.oee);
				rOEE.push(requiredOEE);
				if (historicalObj.hmfDate) {
					labels.push(this.datepipe.transform(historicalObj.hmfDate, 'dd-MMM-yyyy'));
				}
			});
			demonOEEMax = Math.max(...oee);
			const tempChart = _.cloneDeep(oeeChartTemplate);
			if (demonOEEMax < 100 && requiredOEE < 100) {
				yMax = 100;
			} else {
				if (demonOEEMax > 100) {
					yMax = Math.ceil(demonOEEMax / 20) * 20;
					tempChart.isDemonOEEExceeds100Percent = true;
				}
				if (requiredOEE > 100) {
					yMax = yMax > Math.ceil(requiredOEE / 20) * 20 ? yMax : Math.ceil(requiredOEE / 20) * 20;
					tempChart.isReqOEEExceeds100Percent = true;
				}
			}
			tempChart.processStreamName = psPnTran.processName;
			tempChart.hyperLink = this.HMPBasePath + '?siteCode=' + this.carRequest.siteCode + '&processId=' + psPnTran.processNameId;
			if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
				tempChart.hyperLink += '&carStatus=view&carId=' + this.carRequest.reqId;
			}
			tempChart.oeeChart = {
				labels: labels,
				datasets: [
					{
						type: 'line',
						label: 'Demonstrated OEE',
						borderColor: '#5DADE2',
						borderWidth: 2,
						fill: false,
						data: oee,
						pointRadius: 5
					},
					{
						type: 'line',
						label: 'Required OEE',
						borderColor: '#EC7063',
						borderWidth: 1,
						fill: false,
						data: rOEE,
						pointRadius: 0
					}
				]
			};

			tempChart.oeeChartOptions = {
				onAnimationComplete: function () {
					this.showTooltip(this.segments, true);
				},
				tooltipEvents: [],
				tooltips: {
					intersect: false,
					callbacks: {
						label: function (tooltipItem, data) {
							const label = [];
							label.push(data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toFixed(1));
							return label;
						}
					}
				},
				showTooltips: true,
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							max: yMax,
							callback: function (value, index, values) {
								return value + ' %';
							}
						}
					}],
					xAxes: [{
						ticks: {
							suggestedMax: 25,
							beginAtZero: true
						}
					}]
				}
			};
			chartData.push(tempChart);
		}
		//});
		return chartData;
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.unsubscribe$.unsubscribe();
	}
}
