import {AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {jsPlumb} from 'jsplumb';
import {ProcessStreamService} from '../../../services/master/process-stream/process-stream.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProcessStream} from '../../../models/process-stream';
import {UtilService} from '../../../services/util.service';
import {linkColors} from '../../../models/chart-data';
import {dummyPrimarySupportingMap, PrimarySupportingMap} from '../../../models/primary-supporting-map';

@Component({
	selector: 'view-process-stream',
	templateUrl: './view-process-stream.component.html',
	styleUrls: ['./view-process-stream.component.css']
})
export class ViewProcessStreamComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	@Input() carRequestId: number;
	@Input() isHistorical: boolean;
	@Input() viewProcessStreamId: number;
	@Input() rootProcessStreamId: number;
	@Input() mode: any;
	@Input() processStream: ProcessStream = new ProcessStream({
		availableProcessNames: [],
		connectingProcessNameId: 0,
		createdBy: '',
		createdDate: 0,
		id: 0,
		isPrimaryStream: false,
		mapToProcessName: 0,
		mapToProcessStream: 0,
		mapToStreamId: 0,
		originProcessStreamId: 0,
		primarySupportingMap: null,
		processStreamName: '',
		processStreamProcessNameMap: null,
		siteCode: '',
		status: '',
		updatedBy: '',
		updatedDate: 0,
		validationErrors: null
	});
	isConnectionMade = false;
	jsPlumbInstance;
	showConnectionToggle = false;
	buttonName = 'Connect';
	//processStream1: ProcessStream[] = [{}];
	numbers = [1, 2, 3, 4, 5, 6, 7];
	common = {
		anchors: ['BottomCenter', 'TopCenter'],
		endpoints: ['Dot', 'Blank']
	};
	private processStreamId: number;
	processStreamTree: ProcessStream[] = [];
	openedFromLink = false;
	primSuppMap: PrimarySupportingMap;

	constructor(private utilService: UtilService, private router: Router, private route: ActivatedRoute, private processStreamService: ProcessStreamService) {
	}

	ngOnInit() {
		console.log('ngOnInit called');
		this.jsPlumbInstance = jsPlumb.getInstance();
		if (this.viewProcessStreamId) {
			this.processStreamId = this.viewProcessStreamId;
		} else {
			this.retrieveRouteParameter();
		}
		if ((this.mode !== 'view' && !this.processStream.isPrimaryStream && this.rootProcessStreamId) || ((this.mode !== 'view' && this.processStream.isPrimaryStream && !this.rootProcessStreamId))) {
			if (this.processStream.isPrimaryStream) {
				if (this.mode === 'create') {
					this.processStreamTree[0] = this.processStream;
					this.processStreamTree[0].primarySupportingMap = null;
				} else {
					this.processStreamService.findProcessStreamTreeById(this.processStream.id).subscribe(value => {
						this.processStreamTree = value;
						this.processStreamTree[0].availableProcessNames = this.processStream.availableProcessNames;
					});
				}
			} else {
				this.processStreamService.findProcessStreamTreeById(this.rootProcessStreamId).subscribe(value => {
					this.processStreamTree = value;
					if (this.mode === 'create') {
						this.primSuppMap = Object.assign({}, dummyPrimarySupportingMap);
						this.primSuppMap.primaryProcessNameId = this.processStream.connectingProcessNameId;
						this.primSuppMap.supportingProcessStreamId = this.processStream.id;
						this.primSuppMap.originProcessStreamId = this.rootProcessStreamId;
						this.primSuppMap.primaryProcessStreamId = this.processStream.mapToStreamId;
						console.log('primaryProcessId ' + this.primSuppMap.primaryProcessNameId + ' supportingProcessStreamId ' + this.primSuppMap.supportingProcessStreamId
							+ ' originProcessStreamId ' + this.primSuppMap.originProcessStreamId + ' primaryProcessStreamId ' + this.primSuppMap.primaryProcessStreamId);
						if (!this.processStream.primarySupportingMap) {
							this.processStream.primarySupportingMap = [];
						}
						this.processStream.primarySupportingMap.push(this.primSuppMap);
						this.processStreamTree.push(this.processStream);
					} else {
						const indexToUpdate = this.processStreamTree.findIndex(stream => stream.id === this.processStream.id);
						this.processStream.primarySupportingMap['primaryProcessStreamId'] = this.processStream.mapToStreamId;
						this.processStream.primarySupportingMap['primaryProcessNameId'] = this.processStream.connectingProcessNameId;
						this.processStream.primarySupportingMap['primaryProcessNameId'] = this.processStream.connectingProcessNameId;
						this.processStreamTree[indexToUpdate] = this.processStream;
						this.processStreamTree = Object.assign([], this.processStreamTree);
					}
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}
		} else {
			if (this.isHistorical) {
				this.processStreamService.findProcessStreamTranTreeById(this.processStreamId, this.carRequestId).subscribe(value => {
					this.processStreamTree = value;
					//this.connectProcessNames();
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			} else {
				this.processStreamService.findProcessStreamTreeById(this.processStreamId).subscribe(value => {
					this.processStreamTree = value;
					//this.connectProcessNames();
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}

		}
	}

	private retrieveRouteParameter() {
		this.route.paramMap.subscribe(params => {
			this.processStreamId = Number(params.get('id'));
			this.carRequestId = Number(params.get('reqId'));
			this.isHistorical = Boolean(params.get('isHistorical'));
		});
		this.route.queryParamMap.subscribe(params => {
			this.openedFromLink = Boolean(params.get('view'));
		});
	}

	ngAfterViewInit(): void {
		console.log('ngAfterViewInit called');
		//this.jsPlumbInstance = jsPlumb.getInstance();
		//this.connectSourceToTargetUsingJSPlumb();
		setTimeout(() => {
			this.connectProcessNames();
		}, 3000);
		this.connectProcessNames();
	}

	ngAfterViewChecked(): void {
		//this.jsPlumbInstance = jsPlumb.getInstance();
		//this.connectProcessNames();
	}

	connectProcessNames() {
		console.log('connectProcessNames called');
		if (!this.isConnectionMade) {
			this.jsPlumbInstance = jsPlumb.getInstance();
			const psSize = this.processStreamTree.length;
			for (let curTreeIndex = 0; curTreeIndex < psSize; curTreeIndex++) {
				const curProcessStream = this.processStreamTree[curTreeIndex];
				const availableProcessNames = curProcessStream.availableProcessNames;
				const pnSize = availableProcessNames.length;
				for (let curPNIndex = 0; curPNIndex < pnSize; curPNIndex++) { // n-1 loop to make connection
					if (curPNIndex < pnSize - 1) {
						const sourceId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
						const targerId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;

						const connectJson = this.buildConnection(sourceId, targerId);
						this.jsPlumbInstance.connect(connectJson);
					}

					if (curTreeIndex !== 0) { // other than parent
						console.log('curProcessStream');
						let primaryProcessStreamId, supportingProcessStreamId, primaryProcessNameId;
						if (this.processStream.id === curProcessStream.id && this.mode === 'create') {
							this.primSuppMap = curProcessStream.primarySupportingMap[0];
							primaryProcessStreamId = this.primSuppMap.primaryProcessStreamId;
							supportingProcessStreamId = this.primSuppMap.supportingProcessStreamId;
							primaryProcessNameId = this.primSuppMap.primaryProcessNameId;
						} else {
							const priSuppoMap = curProcessStream.primarySupportingMap;
							primaryProcessStreamId = priSuppoMap['primaryProcessStreamId'];
							supportingProcessStreamId = priSuppoMap['supportingProcessStreamId'];
							primaryProcessNameId = priSuppoMap['primaryProcessNameId'];

						}
						const sourceIdPriSupp = 'div-' + primaryProcessStreamId + '-' + primaryProcessNameId;
						const targerIdPriSupp = 'div-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
						const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targerIdPriSupp, linkColors[curTreeIndex].colorCode);
						console.log('connectJsonPriSupp');
						this.jsPlumbInstance.connect(connectJsonPriSupp);

					}

					this.isConnectionMade = true;
				}
			}
		}
	}

	private buildConnection(sourceId, targerId) {
		const connectJson = {
			source: sourceId,
			target: targerId,
			anchors: ['Right', 'Left'],
			endpoint: 'Blank',
			endpointStyle: {fill: 'blue'},
			paintStyle: {stroke: '#03a108', strokeWidth: 1},
			hoverPaintStyle: {strokeStyle: '#dbe300'},
			connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}/*	private buildConnection(sourceId, targerId) {
		const connectJson = {
			source: sourceId,
			target: targerId,
			anchors: ['Right', 'Left'],
			endpoint: 'Blank',
			endpointStyle: {fill: 'blue'},
			paintStyle: {stroke: '#03a108', strokeWidth: 2},
			connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}]
		};
		return connectJson;
	}*/

	connectSourceToTargetUsingJSPlumb() {
		console.log('calling');
		/*
				this.jsPlumbInstance.connect({
					source: 'div1',
					target: 'div2',
					anchors: ['Right', 'Left' ],
					endpoint: 'Blank',
					endpointStyle: { fill: 'black' },
					connector : ['Flowchart', { cornerRadius: 1, alwaysRespectStubs: false}]
				});

		for (let i = 1; i <= 7; i++) {
			console.log('hai');
			this.jsPlumbInstance.connect({
				source: 'div' + i,
				target: 'div' + (i + 1),
				anchors: ['Right', 'Left'],
				endpoint: 'Blank',
				endpointStyle: {fill: 'blue'},
				paintStyle: {stroke: 'rgba(12,20,255,0.98)', strokeWidth: 2},
				connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}]
			});
		}

		this.jsPlumbInstance.connect({
			source: 'div3',
			target: 'div17',
			anchors: ['Bottom', 'Top'],
			paintStyle: {stroke: '#ff06be', strokeWidth: 2},
			endpoint: 'Blank',
			endpointStyle: {fill: 'yellow'},
			connector: ['Bezier', {curviness: 30}]
		});*/
		/*this.jsPlumbInstance.connect(
			{ source: 'Source', target: 'Target1', anchors: ['RightMiddle', 'LeftMiddle'] , connector: ['Flowchart', {stub: [3, 5], cornerRadius: 1, alwaysRespectStubs: false}]});
*//*		let labelName;
		labelName = '';*/
		/*this.jsPlumbInstance.connect({
			/!*connector: ['Flowchart', {stub: [3, 5], cornerRadius: 1, alwaysRespectStubs: false}],*!/
			source: 'Source',
			target: 'Target1'
			/!*,
			anchor: ['Right', 'Left'],
			paintStyle: {stroke: '#456', strokeWidth: 2}*!/
			/!*,
			overlays: [
				['Label', {label: labelName, location: 0.5, cssClass: 'connectingConnectorLabel'}]
			]*!/
		});*/
	}

	onCancel() {
		this.jsPlumbInstance.empty('root');
		this.router.navigate(['manage-process-stream']);
	}

	private buildPriSuppConnection(sourceIdPriSupp: string, targerIdPriSupp: string, strokeColor: string) {
		const connectJson = {
			source: targerIdPriSupp,
			target: sourceIdPriSupp,
			anchors: ['Right', 'Right'],
			paintStyle: {stroke: strokeColor, strokeWidth: 2},
			endpoint: 'Blank',
			endpointStyle: {fill: 'yellow'},
			connector: ['Bezier', {curviness: 30}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	ngOnDestroy() {
		this.jsPlumbInstance.deleteEveryConnection();
	}

	onClose() {
		window.close();
	}
}
