import {Component, OnInit} from '@angular/core';
import {Config} from '../config';
import {ExampleService} from './example.service';

/** Component that has no functionality other than plain html text in the template */
@Component({
	selector: 'app-example-page',
	templateUrl: './example-page.component.html',
	styleUrls: ['./example-page.component.css']
})
export class ExamplePageComponent implements OnInit {
	config = new Config();
	message: string;

	/** empty constructor */
	constructor(private exampleService: ExampleService) {
	}

	/** used for functionality that doesn't belong in the constructor but still
	 * needs to be run at the beginning of the lifecycle of the component.
	 */
	ngOnInit() {
		console.log('calling service');
		this.exampleService.retrieveWelcome().subscribe((message) => {
			this.message = message;
		});
	}

}
