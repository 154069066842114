import { Component, OnInit } from '@angular/core';

@Component({
	template: `<h3><p>Unable to retrieve / update data from database. Please try after an hour.</p>
  </h3><h3><p>if the issue persists please contact eCAR support team.</p></h3>
	<i class="far fa-sad-tear fa-8x mt-2" aria-hidden="true"></i>`
})
export class EcarErrorPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
