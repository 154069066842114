import {Component, OnInit} from '@angular/core';
import {ProcessNameService} from '../../services/master/process-name/process-name.service';
import {ProcessName} from '../../models/process-name';
import {DialogService, LazyLoadEvent} from 'primeng/api';
import {SiteCode} from '../../models/site-code';
import {ProcessType} from '../../models/process-type';
import {AutomationTypes, automationTypesMaster} from '../../models/automation-types';
import {ProcessTypeService} from '../../services/master/process-type/process-type.service';
import {Policies} from '../../models/aps-policies';
import {UtilService} from '../../services/util.service';
import {SiteCodeService} from '../../services/sitecode.service';
import {ApsAttributes} from '../../models/aps-attributes';
import {OndeleteDialogueboxComponent} from '../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../models/on-delete-display-dialogue-model';
import {PagingHelper} from '../../models/paging/paging-helper';
import {HttpResponse} from '@angular/common/http';
import {CarRequestService} from '../../services/request/car-request.service';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'process-name',
	templateUrl: './process-name.component.html',
	styleUrls: ['./process-name.component.css']
})
export class ProcessNameComponent implements OnInit {
	processNames: ProcessName [];
	clonedProcessName: { [s: string]: ProcessName; } = {};
	row = true;
	processName: string;
	description: string;
	processNameObj: any;
	active = true;
	siteCodes: SiteCode[];
	processTypes: ProcessType [];
	selectedSiteCode: SiteCode;
	selectedProcessTypeId: ProcessType;
	selectedAutomationType: AutomationTypes;
	automationTypes: AutomationTypes[] = automationTypesMaster;
	updatedSiteCode: SiteCode;
	updatedProcessTypeId: ProcessType;
	updatedAutomationType: AutomationTypes;
	processDesc: string;
	private policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;
	apsAttributes: ApsAttributes;
	totalProcessStreamRecordPages = 0;
	pageDetailsFrom = 0;
	pageDetailsTo = 0;
	currentPage = 0;
	currentPageSize = 10;
	eCARAssociated = false;
	isSitePresentInSIM = true;
	siteCodesString: string;
	isTokenOnlyUser: boolean;
	private isPrimeUser: boolean;

	constructor(private siteCodeService: SiteCodeService, private utilService: UtilService, private service: ProcessNameService, private processTypeService: ProcessTypeService, public dialogService: DialogService, private carRequestService: CarRequestService, private title: Title) {
	}

	ngOnInit() {
		//this.findAllProcessNames();
		this.loadActiveProcessTypes();
		this.findAllPolicies();
		this.loadSiteCodes();
		this.title.setTitle('eCAR - Supplier Processes');
	}

	private findAllProcessNames() {
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		if (this.apsAttributes.userRole === 'Super User' || this.apsAttributes.userRole === 'Buyer' || this.apsAttributes.userRole === 'STA User' || this.apsAttributes.userRole === 'Capacity Planner' || this.apsAttributes.userRole === 'Developer') {
			this.service.findAll().subscribe((response: ProcessName[]) => {
				this.processNames = response;
			});
		} else {
			this.service.findAllProcessNamesBySiteCodes().subscribe((response: ProcessName[]) => {
				this.processNames = response;
			});
		}
	}

	onRowEditInit(processName: ProcessName) {
		this.clonedProcessName[processName.id] = {...processName};
		this.updatedSiteCode = this.siteCodes.find(value => value.code === processName.siteCode);
		this.updatedProcessTypeId = this.processTypes.find(value => value.processTypeName === processName.processTypeName);
		this.updatedAutomationType = this.automationTypes.find(value => value.name === processName.automationType);
		// this.findeCARAssociated(processName.id);
	}

	onRowDelete(processName: ProcessName) {
		this.service.checkeCARAssociatedWithProcessNameId(processName.id).subscribe(response => {
			this.eCARAssociated = Boolean(response);
			if (!this.eCARAssociated) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '30%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'}
				});
				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.service.delete(processName.id).subscribe(value => {
								this.utilService.pushMsg('success', 'Success', 'Process Name is deleted successfully');
								//this.findAllProcessNames();
								this.findAllByPaging(0, 10);
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Unable to delete. Dependency exists in Hist Mfg/Process stream/Shared Loading');
							});
						} else {
							console.log('dont Delete response from dialogue box');
						}
					}
				});
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onUpdate(processName: ProcessName, index) {
		let isValid = true;
		if (processName.id > 0 && !processName.processName && processName.processName.trim() === '') {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Process Name  is Invalid');
		}
		if (!processName.processDesc) {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Description should be filled');
		}
		if (isValid) {
			delete this.clonedProcessName[processName.id];
			processName.automationType = this.updatedAutomationType.name;
			processName.processTypeId = this.updatedProcessTypeId.processTypeId;
			processName.siteCode = this.updatedSiteCode.code;
			this.service.update(processName.id, processName).subscribe(value => {
				this.utilService.pushMsg('success', 'Success', 'Process Name is updated successfully');
				//this.findAllProcessNames();
				this.findAllByPaging(0, 10);
			}, error => {
				if (error.error.message.includes('Process Name already exists')) {
					this.utilService.pushMsg('error', 'Error', 'Site Code, Process Type and Process Name combination already exist');
				} else {
					this.utilService.pushMsg('error', 'Error', 'Process Name Update operation failed, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				}
				//this.findAllProcessNames();
				this.findAllByPaging(0, 10);
			});

		}
	}

	private resetFields() {
		this.processName = '';
		this.description = '';
		this.selectedSiteCode = null;
		this.selectedProcessTypeId = null;
		this.selectedAutomationType = null;
		this.active = true;
	}

	onRowEditCancel(processName: ProcessName, index: number) {
		this.processNames[index] = this.clonedProcessName[processName.id];
		delete this.clonedProcessName[processName.id];
		this.eCARAssociated = false;
	}

	onCreate() {
		if (this.processName && this.description && this.processName.trim() !== '' && this.selectedSiteCode && this.selectedProcessTypeId && this.selectedAutomationType) {
			console.log('processName::' + this.processName + ' description::' + this.description + ' sitecode::' + this.selectedSiteCode.code + ' processTypeId::' + this.selectedProcessTypeId.processTypeId + ' automation:::' + this.selectedAutomationType.name);
			this.processNameObj = {
				processName: this.processName,
				processDesc: this.description,
				siteCode: this.selectedSiteCode.code,
				processTypeId: this.selectedProcessTypeId.processTypeId,
				automationType: this.selectedAutomationType.name,
				active: this.active
			};
			this.service.create(this.processNameObj).subscribe((value) => {
				this.utilService.pushMsg('success', 'Success', 'Supplier Process is created successfully');
				//this.findAllProcessNames();
				this.findAllByPaging(0, 10);
				this.resetFields();
				this.isSitePresentInSIM = true;
			}, error => {
				if (error.error.message.includes('Process Name already exists')) {
					this.utilService.pushMsg('error', 'Error', 'Site Code, Process Type and Process Name combination already exist');
				} else {
					this.utilService.pushMsg('error', 'Error', 'Process Name creation failed, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				}

			});
		} else if (!this.processName || !this.description) {
			this.utilService.pushMsg('error', 'Error', this.processName ? 'Description should not be empty' : 'Process Name should not be empty');
		} else if (!this.selectedSiteCode || !this.selectedProcessTypeId) {
			this.utilService.pushMsg('error', 'Error', this.selectedSiteCode ? 'Select any ProcessType' : 'Select any Site Code');
		} else {
			this.utilService.pushMsg('error', 'Error', 'Select any Automation Type');
		}
	}

	onClose($event: any) {
		this.resetFields();
	}

	private loadActiveProcessTypes() {
		this.processTypeService.findAllActiveProcessTypes().subscribe((response: ProcessType[]) => {
			response = response.sort(function (a, b) {
				return a.processTypeName.localeCompare(b.processTypeName, undefined, {
					numeric: true,
					sensitivity: 'base'
				});
			});
			this.processTypes = response;
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:DefineProcess';
		this.disableCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
	}

	loadSiteCodes() {
		this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		let supplierSiteCodes = this.siteCodeService.fetchSiteCodes();
		supplierSiteCodes = supplierSiteCodes.sort(function (a, b) {
			return a.code.localeCompare(b.code, undefined, {
				numeric: true,
				sensitivity: 'base'
			});
		});
		this.siteCodes = supplierSiteCodes;
	}

	private findeCARAssociated(processNameId: number) {
		this.service.checkeCARAssociatedWithProcessNameId(processNameId).subscribe(response => {
			this.eCARAssociated = Boolean(response);
		});
	}

	loadProcessNameLazy(event: LazyLoadEvent) {
		this.currentPage = event.first / event.rows;
		this.currentPageSize = event.rows;
		const sortingParam = [];
		if (event.sortField) {
			if (event.sortField === 'active') {
				if (event.sortOrder < 0) {
					sortingParam.push('isActive' + ',asc');
				} else {
					sortingParam.push('isActive' + ',desc');
				}
			} else {
				if (event.sortOrder < 0) {
					sortingParam.push(event.sortField + ',asc');
				} else {
					sortingParam.push(event.sortField + ',desc');
				}
			}
		}
		const filters = [];
		for (const field of Object.keys(event.filters)) {
			filters.push(field + ':' + event.filters[field].value);
		}
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		this.checkPrimeUser(sessionStorage.getItem('userId'), this.apsAttributes.userRole, this.currentPage, this.currentPageSize, sortingParam, filters);
	}

	private findAllByPaging(first, size, sort?: String[], filters?: String[]) {
		let uRole = this.apsAttributes.userRole;
		if (this.isPrimeUser) {
			uRole = 'Prime User';
			this.siteCodesString = 'hi';
		} else if (uRole.toLowerCase() === 'ecar_read' || uRole.toLowerCase() === 'ecar_update' || uRole.includes('Developer') || uRole.includes('SiteEngineer') || uRole.includes('Supervisor') || uRole.includes('Manager')) {
			this.siteCodesString = this.apsAttributes.siteCodes.map(site => site).join('-');
		} else {
			this.siteCodesString = 'hi';
		}
		this.service.findAllProcessName({
			page: first,
			size: size,
			sort: sort,
			filters: filters,
			userRole: uRole,
			siteCodes: this.siteCodesString
		}).subscribe((pageableProcessNames: PagingHelper) => {
			if (pageableProcessNames.content) {
				this.processNames = pageableProcessNames.content;
				this.totalProcessStreamRecordPages = pageableProcessNames.totalElements;
				this.pageDetailsFrom = ((pageableProcessNames.pageable.pageNumber + 1) - 1) * pageableProcessNames.pageable.pageSize + 1;
				this.pageDetailsTo = (pageableProcessNames.pageable.pageNumber + 1) * pageableProcessNames.pageable.pageSize;
				if (this.pageDetailsTo > this.totalProcessStreamRecordPages) {
					this.pageDetailsTo = this.totalProcessStreamRecordPages;
				}
			}
		});
	}

	isSiteCodeAvailableInSIM(siteCode: string) {
		this.service.isSiteCodePresentInSIM(siteCode).subscribe(isPresent => {
			this.isSitePresentInSIM = isPresent;
		});
	}

	// Downloads the list of process types
	downloadProcessTypes(): void {
		this.processTypeService.downloadExcel().subscribe((response: HttpResponse<Blob>) => {
			const blob = new Blob([response.body], {
				type:
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.setAttribute('style', 'display: none');
			a.href = url;
			const val = response.headers.get('Content-Disposition');
			const startInd = val.indexOf('filename=');
			a.download = val.substring(startInd + 9);
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();
		});
	}

	private checkPrimeUser(userId: string, userRole: string, first, size, sort?: String[], filters?: String[]) {
		if (userRole.includes('Manager') || userRole.includes('Engineer') || userRole.includes('Supervisor')) {
			this.carRequestService.isPrimeUser(userId).subscribe(isValid => {
				if (isValid) {
					this.isPrimeUser = true;
					this.findAllByPaging(first, size, sort, filters);
				} else {
					this.isPrimeUser = false;
					this.findAllByPaging(first, size, sort, filters);
				}
			});
		} else {
			this.isPrimeUser = false;
			this.findAllByPaging(first, size, sort, filters);
		}
	}
}
