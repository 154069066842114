import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
	selector: 'no-access',
	templateUrl: './no-access.component.html',
	styleUrls: ['./no-access.component.css']
})
export class NoAccessComponent implements OnInit {

	constructor(private title: Title) {
	}

	ngOnInit() {
		this.title.setTitle('eCAR - No Access');
	}

}
