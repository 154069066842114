import {Component, OnInit} from '@angular/core';
import {ProcessNameService} from '../../services/master/process-name/process-name.service';
import {ConfirmationService, DialogService, Message, MessageService} from 'primeng/api';
import {SiteCode} from '../../models/site-code';
import {Policies} from '../../models/aps-policies';
import {UtilService} from '../../services/util.service';
import {SiteCodeService} from '../../services/sitecode.service';
import {ManageSharedLoadingService} from '../../services/master/manage-shared-loading/manage-shared-loading.service';
import {Option} from '../../models/option';
import {PartNumberDetails} from '../../models/part-number-details';
import {ActivatedRoute, Router} from '@angular/router';
import {UrlConsumerService} from '../../oauth/url-consumer.service';
import {Title} from '@angular/platform-browser';


@Component({
	selector: 'replace-part-number',
	templateUrl: './replace-part-number.component.html',
	styleUrls: ['./replace-part-number.component.css']
})
export class ReplacePartNumberComponent implements OnInit {
	displayMessage: Message[] = [];
	private policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;

	siteCodes: SiteCode[];
	isSitePresentInSIM = true;
	isTokenOnlyUser: boolean;
	partNameSuffix: any;
	partNameBase: any;
	partNamePrefix: any;
	selectedSiteCode: SiteCode;
	selectedPartNumber: Option;
	partNumberList: Option[];
	private partNumberDetails: PartNumberDetails;
	private message: string;

	constructor(private activatedRoute: ActivatedRoute, private utilService: UtilService, private urlConsumerService: UrlConsumerService, private router: Router, private sharedLoadingService: ManageSharedLoadingService, private siteCodeService: SiteCodeService, private service: ProcessNameService, private confirmationService: ConfirmationService, public dialogService: DialogService, private messageService: MessageService, private title: Title) {
	}

	ngOnInit() {
		this.findAllPolicies();
		this.loadSiteCodes();
		this.title.setTitle('eCAR - Replace Part Number');
	}

	isSiteCodeAvailableInSIM(siteCode: string) {
		this.resetAllFieldsExceptSiteCode();
		this.service.isSiteCodePresentInSIM(siteCode).subscribe(isPresent => {
			this.isSitePresentInSIM = isPresent;
			if (this.isSitePresentInSIM) {
				this.sharedLoadingService.getPartNumberListBySiteCode(siteCode).subscribe(partNumbers => {
					if (partNumbers && partNumbers.length > 0) {
						this.partNumberList = partNumbers;
					} else {
						this.utilService.pushMsg('info', 'Information', 'No Part number(s) are available in the system for the selected site code.');
					}
				});
			}
		});
	}

	findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:DefineProcess';
		this.disableCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
	}

	loadSiteCodes() {
		this.isTokenOnlyUser = this.utilService.isUserHasOnlyTokenOnly();
		let supplierSiteCodes = this.siteCodeService.fetchSiteCodes();
		supplierSiteCodes = supplierSiteCodes.sort(function (a, b) {
			return a.code.localeCompare(b.code, undefined, {
				numeric: true,
				sensitivity: 'base'
			});
		});
		this.siteCodes = supplierSiteCodes;
	}

	suppressHyphen(event: KeyboardEvent) {
		if (event.key === '-') {
			return false;
		}
	}

	onClose($event: any) {
		this.resetAllFields();
	}

	private resetAllFields() {
		this.partNamePrefix = '';
		this.partNameBase = '';
		this.partNameSuffix = '';
		this.selectedPartNumber = null;
		this.partNumberList = null;
		this.selectedSiteCode = null;
	}

	private resetAllFieldsExceptSiteCode() {
		this.partNamePrefix = '';
		this.partNameBase = '';
		this.partNameSuffix = '';
		this.selectedPartNumber = null;
		this.partNumberList = null;
	}

	onReplace() {
		const partNumber = this.partNamePrefix + '-' + this.partNameBase + '-' + this.partNameSuffix;
		if (this.selectedPartNumber.code === partNumber) {
			this.utilService.pushMsg('error', 'Error', 'Selected part number and new part number should be different');
		} else {
			this.message = 'This action will affect the Shared Loading Plan for the below process names as well as the eCARs shown below.  Are you sure that you want to proceed? <br><br>';
			if (this.partNumberDetails.processNames && this.partNumberDetails.processNames.length > 0) {
				this.message = this.message + '<label> Process Name(s) :</label><br>';
				this.partNumberDetails.processNames.forEach( (processName, pnIndex) => {
					let rowVal = pnIndex + 1;
					this.message = this.message + rowVal + '. ' + processName + '<br>';
				});
			}
			if (this.partNumberDetails.ecars && this.partNumberDetails.ecars.length > 0) {
				this.message = this.message + '<label> eCAR(s) :</label><br>';
				this.partNumberDetails.ecars.forEach( (eCARId, eCarIndex) => {
					let rowNo = eCarIndex + 1;
					this.message = this.message + rowNo + '. #' + eCARId + '<br>';
				});
			}
			this.confirmationService.confirm({
				message: this.message,
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.sharedLoadingService.replacePartNumber(this.selectedSiteCode.code, this.selectedPartNumber.code, partNumber).subscribe(response => {
						if (response) {
							this.resetAllFields();
							this.utilService.pushMsg('success', 'Success', 'Part number updated successfully.');
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 5000);
						}
						if (!response) {
							this.utilService.pushMsg('error', 'Error', 'Part number update operation has failed.');
							window.scrollTo(0, 0);
							setTimeout(() => {
								this.displayMessage = [];
							}, 10000);
						}
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Copy operation failed, please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
					});
				},
				reject: () => {
					console.log('Part number update operation got cancelled.');
					this.utilService.pushMsg('info', 'Information', 'Part number update operation got cancelled.');
				}
			});
		}
	}

	fetchPartNumberDetails(selectedPartNumber: any) {
		if (selectedPartNumber) {
			this.sharedLoadingService.getPartNumberDetailsBySiteCodeAndPartNumber(this.selectedSiteCode.code, selectedPartNumber).subscribe(partNumberInfo => {
				this.partNumberDetails = partNumberInfo;
				const partNumber = selectedPartNumber.split('-');
				this.partNamePrefix = partNumber[0];
				this.partNameBase = partNumber[1];
				this.partNameSuffix = partNumber[2];
			});
		}
	}
}
