import {PsPnMapTran} from './pspnmaptran';

export class PrimarySupportingMapTran {
	primaryProcessStreamId: number;
	primaryProcessNameId: number;
	supportingProcessStreamId: number;
	originProcessStreamId: number;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	psPnMapTranList: PsPnMapTran[];
	processStreamName: string;
	carReqId: number;

	constructor(primarySupportingMap: PrimarySupportingMapTran) {
		Object.assign(this, primarySupportingMap);
	}
}

export const dummyPrimarySupportingMapTran: PrimarySupportingMapTran = {
	primaryProcessNameId: 0,
	primaryProcessStreamId: 0,
	supportingProcessStreamId: 0,
	originProcessStreamId: 0,
	createdBy: '',
	createdDate: 0,
	updatedBy: '',
	updatedDate: 0,
	psPnMapTranList: [],
	processStreamName: '',
	carReqId: 0
};
