import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {HttpClient} from '@angular/common/http';
import {SiteSupervisorSiteEngineerApproval} from '../../../models/sitesupervisor-siteengineer-approval';
import {Observable} from 'rxjs';
import {SiteCode} from '../../../models/site-code';

@Injectable({
	providedIn: 'root'
})
export class SupervisorApprovalFlowService {

	private config = new Config();
	private httpUrl = `${this.config.url}/supervisorApprovalConditions`;

	constructor(private http: HttpClient) {
	}

	createSiteEngineerCondition(siteSpvSiteEngineer: SiteSupervisorSiteEngineerApproval): Observable<SiteSupervisorSiteEngineerApproval> {
		return this.http.post<SiteSupervisorSiteEngineerApproval>(this.httpUrl + '/createSiteEngineerCondition', siteSpvSiteEngineer);
	}

	getAvailableSiteEngineerList(allSiteEngineerListFromSIM: string[], selectedSiteEngineers: string[]): Observable<SiteCode[]> {
		return this.http.get<SiteCode[]>(this.httpUrl + '/getAvailableUnselectedSiteEngineers/' + allSiteEngineerListFromSIM + '/' + selectedSiteEngineers);
	}

	getAllAvailableSiteEngineerList(allSiteEngineerListFromSIM: string[]): Observable<SiteCode[]> {
		return this.http.get<SiteCode[]>(this.httpUrl + '/getAllAvailableUnselectedSiteEngineers/' + allSiteEngineerListFromSIM);
	}

	deleteSiteEngineerCondition(siteEngineer: string): Observable<boolean> {
		return this.http.delete<boolean>(this.httpUrl + '/deleteSiteEngineerCondition/' + siteEngineer);
	}

	findBySiteEngineersForSupervisorApproval(siteEngineerList: string[]): Observable<SiteSupervisorSiteEngineerApproval[]> {
		return this.http.get<SiteSupervisorSiteEngineerApproval[]>(this.httpUrl + '/findAllBySiteEngineersForSpvApproval/' + siteEngineerList);
	}

	getSiteEngineerListFromSIM(loggedInSupervisor: string): Observable<string[]> {
		return this.http.get<string[]>(this.httpUrl + '/getAllSiteEngineersFromSIM/' + loggedInSupervisor);
	}
}
