import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {HistoricalMfgPerf} from '../../../models/historical-mfgperf';
import {map} from 'rxjs/operators';
import {ManageSharedLoadingService} from '../manage-shared-loading/manage-shared-loading.service';
import {CarRequest} from '../../../models/car-request';

@Injectable({
	providedIn: 'root'
})
export class HistoricalMfgPerfService {

	private config = new Config();
	private httpUrl = `${this.config.url}/historicalMfgPerf`;
	private httpUrlProcessAndSiteCodes = `${this.config.url}/historicalMfgPerf/ProcessAndSiteCodes`;
	private httpUrlProcessAndSiteCodesAndCarId = `${this.config.url}/historicalMfgPerf/ProcessAndSiteCodesAndCarId`;
	triggerSharedLoadingSubject = new Subject<string>();
	triggerHMPChartSubject = new Subject<CarRequest>();
	AllHMPData: HistoricalMfgPerf[];
	public hmpSources = [];
	public oldSources = [];

	constructor(private http: HttpClient, private sharedLoadingService: ManageSharedLoadingService) {
		this.oldSources = [
			{
				name: 'Surrogate historical data from similar process (details in Planning CAR)',
				code: '1'
			},
			{
				name: 'Historical data for production process with other parts (analyzed part not yet in production)',
				code: '2'
			},
			{name: 'Historical data for production process including analyzed part', code: '3'},
			{name: 'Other (to be explained in comments)', code: '4'}
		];

		this.hmpSources = [
			{
				name: 'Historical data for production process',
				code: '1'
			},
			{
				name: 'Surrogate historical data from similar process (details in Planning CAR)',
				code: '2'
			},
			{name: 'Other (to be explained in comments)', code: '3'}
		];
	}

	findAll(): Observable<HistoricalMfgPerf[]> {
		return this.http.get(this.httpUrl).pipe(map((historicalmfgperfs: HistoricalMfgPerf[]) => {
			return historicalmfgperfs.map(historicalmfgperf => {
				return new HistoricalMfgPerf(historicalmfgperf);
			});
		}));
	}

	findHistMfgPerfRecords(historicalMfgPerfObj): Observable<HistoricalMfgPerf[]> {
		return this.http.get(this.httpUrlProcessAndSiteCodes + '/' + historicalMfgPerfObj.processId + '/' + historicalMfgPerfObj.siteCode).pipe(map((historicalmfgperfs: HistoricalMfgPerf[]) => {
			return historicalmfgperfs.map(responseData => {
				if (responseData.hmfDate === null || responseData.gpp === null || responseData.nict === null || responseData.nat === null) {
					responseData.updatedBy = null;
					responseData.updatedDate = null;
				}
				let cnt = 0;
				this.hmpSources.forEach(source => {
					if (responseData.source.name === source.name) {
						responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
					} else {
						cnt++;
					}
				});
				if (cnt === this.hmpSources.length) {
					responseData.source = this.oldSources.find(source => source.name === responseData.source.name);
				}
				//responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
				return new HistoricalMfgPerf(responseData);
			});
		}));
	}

	findLatest25HistMfgPerfRecords(historicalMfgPerfObj: HistoricalMfgPerf): Observable<HistoricalMfgPerf[]> {
		return this.http.get(this.httpUrl + '/' + 'fetchLatest25' + '/' + historicalMfgPerfObj.processId + '/' + historicalMfgPerfObj.siteCode).pipe(map((hmpList: HistoricalMfgPerf[]) => {
			return this.mapHmpData(hmpList);
		}));
	}

	mapHmpData(hmpList: HistoricalMfgPerf[]) {
		return hmpList.map(responseData => {
			let cnt = 0;
			this.hmpSources.forEach(source => {
				if (responseData.source.name === source.name) {
					responseData.source = this.hmpSources.find(src => src.name === responseData.source.name);
				} else {
					cnt++;
				}
			});
			if (cnt === this.hmpSources.length) {
				responseData.source = this.oldSources.find(source => source.name === responseData.source.name);
			}
			return new HistoricalMfgPerf(responseData);
		});
	}


	findHistMfgPerfRecordsByCarId(historicalMfgPerfObj, carId): Observable<HistoricalMfgPerf[]> {
		return this.http.get(this.httpUrlProcessAndSiteCodesAndCarId + '/' + historicalMfgPerfObj.processId + '/' + historicalMfgPerfObj.siteCode + '/' + carId).pipe(map((historicalmfgperfs: HistoricalMfgPerf[]) => {
			return historicalmfgperfs.map(responseData => {
				if (responseData.hmfDate === null || responseData.gpp === null || responseData.nict === null || responseData.nat === null) {
					responseData.updatedBy = null;
					responseData.updatedDate = null;
				}
				let cnt = 0;
				this.hmpSources.forEach(source => {
					if (responseData.source.name === source.name) {
						responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
					} else {
						cnt++;
					}
				});
				if (cnt === this.hmpSources.length) {
					responseData.source = this.oldSources.find(source => source.name === responseData.source.name);
				}
				//responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
				return new HistoricalMfgPerf(responseData);
			});
		}));
	}

	create(historicalmfgperf: HistoricalMfgPerf): Observable<HistoricalMfgPerf> {
		return this.http.post<HistoricalMfgPerf>(this.httpUrl, historicalmfgperf);
	}

	update(historicalmfgperf: HistoricalMfgPerf[]): Observable<HistoricalMfgPerf[]> {
		return this.http.put<HistoricalMfgPerf[]>(this.httpUrl, historicalmfgperf).pipe(map((historicalmfgperfs: HistoricalMfgPerf[]) => {
			return historicalmfgperfs.map(responseData => {
				//	console.log('update::0: hmfId::' + responseData.hmfId + ' hmfdate:: ' + responseData.hmfDate + ' gpp:::' + responseData.gpp + ' nict::' + responseData.nict + ' nat::' + responseData.nat + ' oee:::' + responseData.oee + 'updateby::' + responseData.updatedBy + ' updateddate:::' + responseData.updatedDate);
				/*	if (responseData.hmfDate === null && responseData.oee === 0) {
				//		console.log('update::1: hmfId::' + responseData.hmfId + ' hmfdate:: ' + responseData.hmfDate + ' gpp:::' + responseData.gpp + ' nict::' + responseData.nat + ' nat::' + responseData.nat + ' oee:::' + responseData.oee + 'updateby::' + responseData.updatedBy + ' updateddate:::' + responseData.updatedDate)
						responseData.updatedBy = null;
						responseData.updatedDate = null;
					} */
				responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
				return new HistoricalMfgPerf(responseData);
			});
		}));
	}

	delete(id: number): Observable<HistoricalMfgPerf> {
		return this.http.delete<HistoricalMfgPerf>(this.httpUrl + '/' + id);
	}

	private handleError(error: Response) {
		return Observable.throw(error.statusText);
	}

	public downloadHstMfgTemplate() {
		this.http.get(this.httpUrl + '/downloadHstMfgTemplate', {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadGlobalFile(response, 'text/csv');
			});

	}

	public downloadHstMfgExcelEuropeTemplate() {
		this.http.get(this.httpUrl + '/downloadHstMfgExcelEuropeTemplate', {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadEuropeFile(response, 'text/csv');
			});
	}

	private downLoadGlobalFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'HMPTemplate.xlsm';
		anchor.href = url;
		anchor.click();
	}

	private downLoadEuropeFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'HMPTemplate_Europe.xlsm';
		anchor.href = url;
		anchor.click();
	}

	uploadFile(files, siteCode, processNameId): Observable<Object> {
		//FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
		const uploadImageData = new FormData();
		uploadImageData.append('file', files, files.name);
		uploadImageData.append('siteCode', siteCode);
		uploadImageData.append('processNameId', processNameId);
		return this.http.post(this.httpUrl + '/uploadHstMfgExcel', uploadImageData);
	}

	findSharedLoadingBySiteCodeProcessNameId(siteCode, processNameId) {
		return this.sharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(siteCode, processNameId);
	}

	findAllHMPByCarId(carId): Observable<HistoricalMfgPerf[]> {
		return this.http.get(this.httpUrl + '/AllHMPByCarId/' + carId).pipe(map((historicalmfgperfs: HistoricalMfgPerf[]) => {
			return historicalmfgperfs.map(responseData => {
				if (responseData.hmfDate === null || responseData.gpp === null || responseData.nict === null || responseData.nat === null) {
					responseData.updatedBy = null;
					responseData.updatedDate = null;
				}
				responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
				return new HistoricalMfgPerf(responseData);
			});
		}));
	}

	findAllHMPBySiteCodeProcessIds(siteCode, processNameIds: number[]): Observable<HistoricalMfgPerf[]> {
		const pIds = processNameIds.map(id => id).join('-');
		const params = new HttpParams()
			.set('siteCode', siteCode)
			.set('processNameIds', pIds);
		return this.http.get(this.httpUrl + '/AllHMPBySiteCodeProcessIds/' + siteCode + '/' + pIds).pipe(map((historicalmfgperfs: HistoricalMfgPerf[]) => {
			return historicalmfgperfs.map(responseData => {
				if (responseData.hmfDate === null || responseData.gpp === null || responseData.nict === null || responseData.nat === null) {
					responseData.updatedBy = null;
					responseData.updatedDate = null;
				}
				responseData.source = this.hmpSources.find(source => source.name === responseData.source.name);
				return new HistoricalMfgPerf(responseData);
			});
		}));
	}

	findAllHMPRootRecords(params: HttpParams) {
		return this.http.get(this.httpUrl + '/findAll', {params});
	}

	deleteAllHMPBySiteCodeAndProcessNameId(params: HttpParams) {
		return this.http.delete(this.httpUrl + '/deleteAll', {params});
	}

	getHMPRootBySiteCodeAndProcessId(siteCode: string, processNameId: number) {
		return this.http.get(this.httpUrl + '/getHMPRoot/' + siteCode + '/' + processNameId);
	}

	findProcessNameIdsWithHmp(siteCode: string) {
		return this.http.get(this.httpUrl + '/findProcessNameIdsWithHmp/' + siteCode);
	}
}
