export class SharedLoadingChart {
	processName: string;
	processStreamName: string;
	sharedLoadingLink: string;
	apwChart: ChartData;
	mpwChart: ChartData;

	constructor(slChart: SharedLoadingChart) {
		Object.assign(this, slChart);
	}
}

export class ChartData {
	labels: string[];
	datasets: Dataset[];
	isSharedLoadingExceeds100Percent: boolean;
	height: any;

	constructor(chartDataTemp: ChartData) {
		Object.assign(this, chartDataTemp);
	}
}

export class Dataset {
	data: number[];
	backgroundColor: string[];
	hoverBackgroundColor: string[];

	constructor(dataset: Dataset) {
		Object.assign(this, dataset);
	}
}

export class ChartColor {
	backgroundColor: string;
	hoverBackgroundColor: string;

	constructor(chartColor: ChartColor) {
		Object.assign(this, chartColor);
	}
}

export const chartColors: ChartColor[] = [
	new ChartColor({backgroundColor: '#ff06be', hoverBackgroundColor: '#FF6384'}),
	new ChartColor({backgroundColor: '#ff0f05', hoverBackgroundColor: '#ff5e06'}),
	new ChartColor({backgroundColor: '#0c14ff', hoverBackgroundColor: '#36A2EB'}),
	new ChartColor({backgroundColor: '#fff709', hoverBackgroundColor: '#fff9bc'}),
	new ChartColor({backgroundColor: '#07fffc', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#D891EF', hoverBackgroundColor: '#B666D2'}),
	new ChartColor({backgroundColor: '#DEB887', hoverBackgroundColor: '#996515'}),
	new ChartColor({backgroundColor: '#5F9EA0', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#A52A2A', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#1E90FF', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CD5C5C', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#8B008B', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#BC8F8F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00CED1', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#C5B358', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#800000', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FFB6C1', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#9400D3', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#4682B4', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#FF7F50', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#8FBC8F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#6495ED', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#D2691E', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#66CDAA', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#90EE90', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FFDF00', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FF00FF', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#5F9EA0', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00FFFF', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#8B4513', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CFB53B', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FFA500', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FF1493', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#DA70D6', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00008B', hoverBackgroundColor: '#0000FF'}),
	new ChartColor({backgroundColor: '#E9967A', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#8A2BE2', hoverBackgroundColor: '#00008B'}),
	new ChartColor({backgroundColor: '#7B68EE', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#008080', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#A52A2A', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CD853F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#999900', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#DDA0DD', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#800080', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#4B0082', hoverBackgroundColor: '#00008B'}),
	new ChartColor({backgroundColor: '#6A5ACD', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00FFFF', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#800000', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FF00FF', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#483D8B', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#40E0D0', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#ff06be', hoverBackgroundColor: '#FF6384'}),
	new ChartColor({backgroundColor: '#ff0f05', hoverBackgroundColor: '#ff5e06'}),
	new ChartColor({backgroundColor: '#0c14ff', hoverBackgroundColor: '#36A2EB'}),
	new ChartColor({backgroundColor: '#fff709', hoverBackgroundColor: '#fff9bc'}),
	new ChartColor({backgroundColor: '#07fffc', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#D891EF', hoverBackgroundColor: '#B666D2'}),
	new ChartColor({backgroundColor: '#DEB887', hoverBackgroundColor: '#996515'}),
	new ChartColor({backgroundColor: '#5F9EA0', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#A52A2A', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#1E90FF', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CD5C5C', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#8B008B', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#BC8F8F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00CED1', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#C5B358', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#800000', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FFB6C1', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#9400D3', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#4682B4', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#FF7F50', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#8FBC8F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#6495ED', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#D2691E', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#66CDAA', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#90EE90', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FFDF00', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FF00FF', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#5F9EA0', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00FFFF', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#8B4513', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CFB53B', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FFA500', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FF1493', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#DA70D6', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00008B', hoverBackgroundColor: '#0000FF'}),
	new ChartColor({backgroundColor: '#E9967A', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#8A2BE2', hoverBackgroundColor: '#00008B'}),
	new ChartColor({backgroundColor: '#7B68EE', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#008080', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#A52A2A', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CD853F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#999900', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#DDA0DD', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#800080', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#4B0082', hoverBackgroundColor: '#00008B'}),
	new ChartColor({backgroundColor: '#6A5ACD', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00FFFF', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#800000', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FF00FF', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#483D8B', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#40E0D0', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#ff06be', hoverBackgroundColor: '#FF6384'}),
	new ChartColor({backgroundColor: '#ff0f05', hoverBackgroundColor: '#ff5e06'}),
	new ChartColor({backgroundColor: '#0c14ff', hoverBackgroundColor: '#36A2EB'}),
	new ChartColor({backgroundColor: '#fff709', hoverBackgroundColor: '#fff9bc'}),
	new ChartColor({backgroundColor: '#07fffc', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#D891EF', hoverBackgroundColor: '#B666D2'}),
	new ChartColor({backgroundColor: '#DEB887', hoverBackgroundColor: '#996515'}),
	new ChartColor({backgroundColor: '#5F9EA0', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#A52A2A', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#1E90FF', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CD5C5C', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#8B008B', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#BC8F8F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00CED1', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#C5B358', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#800000', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FFB6C1', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#9400D3', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#4682B4', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#FF7F50', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#8FBC8F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#6495ED', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#D2691E', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#66CDAA', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#90EE90', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FFDF00', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FF00FF', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#5F9EA0', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00FFFF', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#8B4513', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CFB53B', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#FFA500', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FF1493', hoverBackgroundColor: '#FF4500'}),
	new ChartColor({backgroundColor: '#DA70D6', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00008B', hoverBackgroundColor: '#0000FF'}),
	new ChartColor({backgroundColor: '#E9967A', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#8A2BE2', hoverBackgroundColor: '#00008B'}),
	new ChartColor({backgroundColor: '#7B68EE', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#008080', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#A52A2A', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#CD853F', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#999900', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#DDA0DD', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#800080', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#4B0082', hoverBackgroundColor: '#00008B'}),
	new ChartColor({backgroundColor: '#6A5ACD', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#00FFFF', hoverBackgroundColor: '#1E90FF'}),
	new ChartColor({backgroundColor: '#800000', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#FF00FF', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#483D8B', hoverBackgroundColor: '#bcfff7'}),
	new ChartColor({backgroundColor: '#40E0D0', hoverBackgroundColor: '#FFFF00'}),
	new ChartColor({backgroundColor: '#04ff47', hoverBackgroundColor: '#1c6607'})];

export class ProcessLinkColor {
	colorCode: string;

	constructor(processLinkColor: ProcessLinkColor) {
		Object.assign(this, processLinkColor);
	}
}

export const linkColors: ProcessLinkColor[] = [
	new ProcessLinkColor({colorCode: '#33cc33'}),
	new ProcessLinkColor({colorCode: '#36A2EB'}),
	new ProcessLinkColor({colorCode: '#ff5e06'}),
	new ProcessLinkColor({colorCode: '#B666D2'}),
	new ProcessLinkColor({colorCode: '#800000'}),
	new ProcessLinkColor({colorCode: '#ff4dff'}),
	new ProcessLinkColor({colorCode: '#808000'}),
	new ProcessLinkColor({colorCode: '#800000'}),
	new ProcessLinkColor({colorCode: '#8B008B'}),
	new ProcessLinkColor({colorCode: '#808000'}),
	new ProcessLinkColor({colorCode: '#008000'}),
	new ProcessLinkColor({colorCode: '#008080'}),
	new ProcessLinkColor({colorCode: '#000080'}),
	new ProcessLinkColor({colorCode: '#ff4dff'}),
	new ProcessLinkColor({colorCode: '#ffcc00'}),
	new ProcessLinkColor({colorCode: '#0055ff'}),
	new ProcessLinkColor({colorCode: '#33cc33'}),
	new ProcessLinkColor({colorCode: '#800000'}),
	new ProcessLinkColor({colorCode: '#808000'}),
	new ProcessLinkColor({colorCode: '#008000'}),
	new ProcessLinkColor({colorCode: '#800080'}),
	new ProcessLinkColor({colorCode: '#008080'}),
	new ProcessLinkColor({colorCode: '#000080'}),
	new ProcessLinkColor({colorCode: '#36A2EB'}),
	new ProcessLinkColor({colorCode: '#ff4dff'}),
	new ProcessLinkColor({colorCode: '#ffcc00'}),
	new ProcessLinkColor({colorCode: '#0055ff'}),
	new ProcessLinkColor({colorCode: '#33cc33'})
];


export const dataSetTemplate: Dataset = {
	data: [],
	backgroundColor: [],
	hoverBackgroundColor: []
};

export const sharedLoadingChartTemplate: SharedLoadingChart = {
	processName: '',
	processStreamName: '',
	sharedLoadingLink: '',
	apwChart: {
		labels: [],
		datasets: [],
		isSharedLoadingExceeds100Percent: false,
		height: 0

	},
	mpwChart: {
		labels: [],
		datasets: [],
		isSharedLoadingExceeds100Percent: false,
		height: 0
	}
};

// OEE Chart
export class OEEChart {
	processStreamName: string;
	oeeChart: any;
	hyperLink: string;
	oeeChartOptions: any;
	isReqOEEExceeds100Percent: boolean;
	isDemonOEEExceeds100Percent: boolean;
	reqOeeProcessesGreaterThan100: string;
	demonOeeProcessesGreaterThan100: string;

	constructor(oeeChart: OEEChart) {
		Object.assign(this, oeeChart);
	}
}

export const oeeChartTemplate = {
	processStreamName: '',
	hyperLink: '',
	oeeChartOptions: '',
	isReqOEEExceeds100Percent: false,
	isDemonOEEExceeds100Percent: false,
	reqOeeProcessesGreaterThan100: '',
	demonOeeProcessesGreaterThan100: '',
	oeeChart: {
		labels: [],
		datasets: []
	}
};

