import {Injectable} from '@angular/core';
import {SiteCode} from '../models/site-code';
import {ApsAttributes} from '../models/aps-attributes';

@Injectable({
	providedIn: 'root'
})
export class SiteCodeService {
	apsAttributes: ApsAttributes;

	constructor() {
	}

	fetchSiteCodes(): SiteCode[] {
		this.apsAttributes = JSON.parse(sessionStorage.getItem('attributes'));
		const supplierSiteCodes = [];
		if (this.apsAttributes.siteCodes) {
			this.apsAttributes.siteCodes.forEach(siteCode => {
				if (siteCode.length > 4) {
					supplierSiteCodes.push({name: '', code: siteCode});
				}
			});
		}
		return supplierSiteCodes;
	}
}
