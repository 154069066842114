import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'view-car-request',
	templateUrl: './view-car-request.component.html',
	styleUrls: ['./view-car-request.component.css']
})
export class ViewCarRequestComponent implements OnInit {
	private carId: number;

	constructor(private activatedRoute: ActivatedRoute) {
	}

	ngOnInit() {
		this.retrieveRouteParameter();
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.carId = Number(params.get('id'));
			console.log('--------> ViewCarRequestComponent  -> id : ' + this.carId);
		});
	}
}
