import {Injectable} from '@angular/core';
import {CarRequest} from '../../models/car-request';
import {oeeChartTemplate} from '../../models/chart-data';
import * as _ from 'lodash';


@Injectable({
	providedIn: 'root'
})
export class ChartService {

	constructor() {
	}

	public buildOEEPrimaryStreamChartData(carRequest: CarRequest, streamName: string) {
		const reqAPW = [];
		const reqMPW = [];
		const demonstratedOee = [];
		const labels = [];
		const primaryProcessStreamChart = _.cloneDeep(oeeChartTemplate);
		let primaryDemonOEEMax;
		let primaryReqOEEMax;
		let yMax;
		primaryProcessStreamChart.isDemonOEEExceeds100Percent = false;
		primaryProcessStreamChart.isReqOEEExceeds100Percent = false;
		primaryProcessStreamChart.reqOeeProcessesGreaterThan100 = '';
		primaryProcessStreamChart.reqOeeProcessesGreaterThan100 = '';

		carRequest.psPnMapTransList.forEach(pspnMapTran => {
			reqAPW.push(pspnMapTran.processDataList[0].reqOee);
			reqMPW.push(pspnMapTran.processDataList[1].reqOee);
			if (!carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
				demonstratedOee.push(pspnMapTran.processDataList[0].averageHisOee);
			} else {
				demonstratedOee.push(pspnMapTran.processDataList[0].phaseOEE);
			}
			labels.push(pspnMapTran.processName);
			if (pspnMapTran.processDataList[0].reqOee > 100 || pspnMapTran.processDataList[1].reqOee > 100) {
				primaryProcessStreamChart.reqOeeProcessesGreaterThan100 = primaryProcessStreamChart.reqOeeProcessesGreaterThan100 === '' ? pspnMapTran.processName : primaryProcessStreamChart.reqOeeProcessesGreaterThan100 + ', ' + pspnMapTran.processName;
			}
			if (pspnMapTran.processDataList[0].averageHisOee > 100 || pspnMapTran.processDataList[1].averageHisOee > 100) {
				primaryProcessStreamChart.demonOeeProcessesGreaterThan100 = primaryProcessStreamChart.demonOeeProcessesGreaterThan100 === '' ? pspnMapTran.processName : primaryProcessStreamChart.demonOeeProcessesGreaterThan100 + ', ' + pspnMapTran.processName;
			}
		});
		primaryDemonOEEMax = Math.max(...demonstratedOee);
		const primaryReqOEEAPWMax = Math.max(...reqAPW);
		const primaryReqOEEMPWMax = Math.max(...reqMPW);
		primaryReqOEEMax = primaryReqOEEAPWMax > primaryReqOEEMPWMax ? primaryReqOEEAPWMax : primaryReqOEEMPWMax;
		if (primaryDemonOEEMax < 100 && primaryReqOEEMax < 100) {
			yMax = 100;
		} else {
			if (primaryDemonOEEMax > 100) {
				yMax = Math.ceil(primaryDemonOEEMax / 20) * 20;
				primaryProcessStreamChart.isDemonOEEExceeds100Percent = true;
			}
			if (primaryReqOEEMax > 100) {
				yMax = yMax > Math.ceil(primaryReqOEEMax / 20) * 20 ? yMax : Math.ceil(primaryReqOEEMax / 20) * 20;
				primaryProcessStreamChart.isReqOEEExceeds100Percent = true;
			}
		}
		primaryProcessStreamChart.processStreamName = streamName;
		primaryProcessStreamChart.oeeChart = {
			labels: labels,
			datasets: [{
				type: 'bar',
				label: 'Required OEE (APW)',
				backgroundColor: '#1E88E5',
				data: reqAPW,
				borderColor: 'white',
				borderWidth: 2
			},
				{
					type: 'bar',
					label: 'Demonstrated OEE',
					borderColor: 'white',
					backgroundColor: '#E67E22',
					borderWidth: 2,
					data: demonstratedOee
				},
				{
					type: 'bar',
					label: 'Required OEE (MPW)',
					backgroundColor: '#9CCC65',
					data: reqMPW,
					borderColor: 'white',
					borderWidth: 2
				}]
		};
		primaryProcessStreamChart.oeeChartOptions = {
			scales: {
				yAxes: [{
					ticks: {
						max: yMax,
						beginAtZero: true
					}
				}]
			}
		};
		return primaryProcessStreamChart;
	}

	public buildOEESupportingStreamChartData(carRequest: CarRequest) {
		const supportingProcessStreamChart = [];
		carRequest.primarySupportingMapTranList.forEach(supportingMap => {
			const supportingReqAPW = [];
			const supportingReqMPW = [];
			const supportingDemonstratedOee = [];
			const supportingLabels = [];
			let supportingDemonOEEMax;
			let supportingReqOEEMax;
			let yMax;

			const sChart = _.clone(oeeChartTemplate);
			sChart.processStreamName = supportingMap.processStreamName;
			supportingMap.psPnMapTranList.forEach(pspnMap => {
				supportingLabels.push(pspnMap.processName);
				supportingReqAPW.push(pspnMap.processDataList[0].reqOee);
				supportingReqMPW.push(pspnMap.processDataList[1].reqOee);
				if (!carRequest.submissionScenarioObject.templateName.startsWith('Phase')) {
					supportingDemonstratedOee.push(pspnMap.processDataList[0].averageHisOee);
				} else {
					supportingDemonstratedOee.push(pspnMap.processDataList[0].phaseOEE);
				}
				if (pspnMap.processDataList[0].reqOee > 100 || pspnMap.processDataList[1].reqOee > 100) {
					sChart.reqOeeProcessesGreaterThan100 = sChart.reqOeeProcessesGreaterThan100 === '' ? pspnMap.processName : sChart.reqOeeProcessesGreaterThan100 + ', ' + pspnMap.processName;
				}
				if (pspnMap.processDataList[0].averageHisOee > 100 || pspnMap.processDataList[1].averageHisOee > 100) {
					sChart.demonOeeProcessesGreaterThan100 = sChart.demonOeeProcessesGreaterThan100 === '' ? pspnMap.processName : sChart.demonOeeProcessesGreaterThan100 + ', ' + pspnMap.processName;
				}
			});
			supportingDemonOEEMax = Math.max(...supportingDemonstratedOee);
			const supportingReqOEEAPWMax = Math.max(...supportingReqAPW);
			const supportingReqOEEMPWMax = Math.max(...supportingReqMPW);
			supportingReqOEEMax = supportingReqOEEAPWMax > supportingReqOEEMPWMax ? supportingReqOEEAPWMax : supportingReqOEEMPWMax;
			if (supportingDemonOEEMax < 100 && supportingReqOEEMax < 100) {
				yMax = 100;
			} else {
				if (supportingDemonOEEMax > 100) {
					yMax = Math.ceil(supportingDemonOEEMax / 20) * 20;
					sChart.isDemonOEEExceeds100Percent = true;
				}
				if (supportingReqOEEMax > 100) {
					yMax = yMax > Math.ceil(supportingReqOEEMax / 20) * 20 ? yMax : Math.ceil(supportingReqOEEMax / 20) * 20;
					sChart.isReqOEEExceeds100Percent = true;
				}
			}
			sChart.oeeChart = {
				labels: supportingLabels,
				datasets: [{
					type: 'bar',
					label: 'Required OEE (APW)',
					backgroundColor: '#1E88E5',
					data: supportingReqAPW,
					borderColor: 'white',
					borderWidth: 2
				},
					{
						type: 'bar',
						label: 'Demonstrated OEE',
						borderColor: 'white',
						backgroundColor: '#E67E22',
						borderWidth: 2,
						data: supportingDemonstratedOee
					},
					{
						type: 'bar',
						label: 'Required OEE (MPW)',
						backgroundColor: '#9CCC65',
						data: supportingReqMPW,
						borderColor: 'white',
						borderWidth: 2
					}
				]
			};
			sChart.oeeChartOptions = {
				scales: {
					yAxes: [{
						ticks: {
							max: yMax,
							beginAtZero: true
						}
					}]
				}
			};
			supportingProcessStreamChart.push(sChart);
		});
		return supportingProcessStreamChart;
	}
}
