import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {SpinnerService} from './spinner/spinner.service';

/** This class is an implementation of an HttpInterceptor
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */

	constructor(private router: Router, private spinnerService: SpinnerService) {
	}

	private requests: HttpRequest<any>[] = [];

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = sessionStorage.getItem('encodedAccessToken');
		const authTokenOnly = {
			'Authorization': 'Bearer ' + token
		};
		const authTokenAndContentType = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		};
		const contenTypeOnly = {
			'Content-Type': 'application/json'
		};
		const emptyHeader = {};

		function isUploadHMPTemplateEndpoint() {
			return request.url.indexOf('historicalMfgPerf/uploadHstMfgExcel') !== -1;
		}

		function isUploadSLPTemplateEndpoint() {
			return request.url.indexOf('sharedLoading/uploadSLPExcel') !== -1;
		}

		function buildHeaders(uploadTemplateHeader, defaultHeader) {
			return isUploadHMPTemplateEndpoint() ? uploadTemplateHeader : isUploadSLPTemplateEndpoint() ? uploadTemplateHeader : defaultHeader;
		}

		function getHeaders() {
			return buildHeaders(authTokenOnly, authTokenAndContentType);
		}

		request = request.clone({
			headers: new HttpHeaders(getHeaders())
		});
		this.requests.push(request);
		this.spinnerService.show();
		return next.handle(request).pipe(
			tap(evt => {
					if (evt instanceof HttpResponse) {
						this.removeRequest(request);
						if (!evt.url.endsWith('/retrieveAttributes') && !evt.url.endsWith('/allDecisions')) {
							if (this.requests.length === 0) {
								setTimeout(() => {
									this.spinnerService.hide();
								}, 1500);
							}
						}
					}
				}
				, (error1) => {
					this.removeRequest(request);
					this.spinnerService.hide();
				}),
			/*catchError((err: any) => {
				if (err instanceof HttpErrorResponse) {
					console.log('error');
					sessionStorage.clear();
					this.router.navigate(['/404']);
				}
				return of(err);
			})*/

			catchError((error: HttpErrorResponse): Observable<HttpEvent<Object>> => {
				const serverError = 500;
				const badRequest = 400;
				const notFound = 404;
				const postError = 401;
				const communicationFailure = 0;
				const statusCodes = [notFound, communicationFailure];
				/*if (error && error.status === postError) { commented for reaching some scenarios
					sessionStorage.clear();
					this.router.navigate(['/no-access']);
				} else*/
				if (statusCodes.indexOf(error.status) >= 0) {
					sessionStorage.clear();
					this.router.navigate(['/404']);
				} else {
					console.log('Unhandled HTTP Error caught:');
					console.log(error);
				}
				return throwError(error);
			})
		);
	}
}
