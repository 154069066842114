import {Component, Input, OnInit} from '@angular/core';
import {CarRequest} from '../../../models/car-request';
import {
	chartColors,
	ChartData,
	Dataset,
	dataSetTemplate,
	SharedLoadingChart,
	sharedLoadingChartTemplate
} from '../../../models/chart-data';
import {PsPnMapTran} from '../../../models/pspnmaptran';
import {SharedLoadingRoot} from '../../../models/shared-loading-root';
import {ManageSharedLoadingService} from '../../../services/master/manage-shared-loading/manage-shared-loading.service';
import {SharedLoading} from '../../../models/shared-loading';
import * as _ from 'lodash';
import {ProcessStream} from '../../../models/process-stream';
import {SharedLoadingPlanHolder} from '../../../models/shared-loading-plan';
import {UtilService} from '../../../services/util.service';

@Component({
	selector: 'shared-loading-charts',
	templateUrl: './shared-loading-charts.component.html',
	styleUrls: ['./shared-loading-charts.component.css']
})
export class SharedLoadingChartsComponent implements OnInit {
	@Input() carRequest: CarRequest;
	@Input() selectedPrimaryStream: ProcessStream;
	isSharedLoadingExistInPS = false;
	isSharedLoadingExistInSS = false;
	sharedLoadingChartForPrimaryStream: SharedLoadingChart[];
	sharedLoadingChartForSupportingStream: SharedLoadingChart[][];
	sharedLoadingRoots: SharedLoadingRoot[];
	displaySharedLoadingChartPopup = false;
	isThereAnySHChartAvailable = false;
	private vLineGreaterTwenty: number;
	private noSharedPrimaryStrem = true;

	constructor(private utilService: UtilService, private manageSharedLoadingService: ManageSharedLoadingService) {
	}

	ngOnInit() {
	}

	retrieveSharedLoadingIdBySiteCodeAndBuildChart() {
		this.displaySharedLoadingChartPopup = true;
		this.buildChartDataForPrimaryStream();
		this.buildChartDataForSupportingStream();
	}

	buildChartDataForSupportingStream() {
		this.sharedLoadingChartForSupportingStream = [];
		if (this.carRequest && this.carRequest.primarySupportingMapTranList && this.carRequest.primarySupportingMapTranList.length > 0) {
			this.carRequest.primarySupportingMapTranList.forEach((primarySupportingMapTran, primarySupportingMapTranIndex) => {
				if (primarySupportingMapTran && primarySupportingMapTran.psPnMapTranList && primarySupportingMapTran.psPnMapTranList.length > 0) {
					const sharedLoadingChartForSupportingStreamTemp = [];
					for (let i = primarySupportingMapTran.psPnMapTranList.length - 1; i >= 0; i--) {
						const psPnMapTranObject = primarySupportingMapTran.psPnMapTranList[i];
						if (psPnMapTranObject && psPnMapTranObject.processDataList[0] && !psPnMapTranObject.processDataList[0].dedicated) {
							this.isThereAnySHChartAvailable = true;
							this.isSharedLoadingExistInSS = true;
							if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
								this.manageSharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTranObject.processNameId).subscribe(value => {
									if (value) {
										this.displaySharedLoadingChartPopup = true;
										const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, primarySupportingMapTran.processStreamName, value);
										if (sharedLoadingChartTemp) {
											sharedLoadingChartForSupportingStreamTemp.push(sharedLoadingChartTemp);
											if (i === 0 && sharedLoadingChartForSupportingStreamTemp && sharedLoadingChartForSupportingStreamTemp.length > 0) {
												this.sharedLoadingChartForSupportingStream.push(sharedLoadingChartForSupportingStreamTemp);
												console.log('final this.sharedLoadingChartForSupportingStreamTemp ' + this.sharedLoadingChartForSupportingStream.length);
											}
										}
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							} else {
								this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTranObject.processNameId.toString()).subscribe(value => {
									if (value) {
										this.displaySharedLoadingChartPopup = true;
										const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, primarySupportingMapTran.processStreamName, value);
										if (sharedLoadingChartTemp) {
											sharedLoadingChartForSupportingStreamTemp.push(sharedLoadingChartTemp);
											if (i === 0 && sharedLoadingChartForSupportingStreamTemp && sharedLoadingChartForSupportingStreamTemp.length > 0) {
												this.sharedLoadingChartForSupportingStream.push(sharedLoadingChartForSupportingStreamTemp);
												console.log('final this.sharedLoadingChartForSupportingStreamTemp ' + this.sharedLoadingChartForSupportingStream.length);
											}
										}
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}
					}
				}
			});
		}
	}

	buildChartDataForPrimaryStream() {
		this.sharedLoadingChartForPrimaryStream = [];
		if (this.carRequest && this.carRequest.psPnMapTransList && this.carRequest.psPnMapTransList.length > 0) {
			for (let i = this.carRequest.psPnMapTransList.length - 1; i >= 0; i--) {
				const psPnMapTranObject = this.carRequest.psPnMapTransList[i];
				if (psPnMapTranObject && psPnMapTranObject.processDataList[0] && !psPnMapTranObject.processDataList[0].dedicated) {
					this.isThereAnySHChartAvailable = true;
					if (this.carRequest.requestStatus !== null && this.carRequest.requestStatus !== 'DRAFT' && this.carRequest.requestStatus !== 'STADRAFT' && this.carRequest.requestStatus !== 'REJECTED') {
						this.manageSharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, psPnMapTranObject.processNameId).subscribe(value => {
							if (value) {
								this.displaySharedLoadingChartPopup = true;
								const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, psPnMapTranObject.processName, value);
								if (sharedLoadingChartTemp) {
									this.sharedLoadingChartForPrimaryStream.push(sharedLoadingChartTemp);
								}
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					} else {
						this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, psPnMapTranObject.processNameId.toString()).subscribe(value => {
							if (value) {
								this.displaySharedLoadingChartPopup = true;
								const sharedLoadingChartTemp = this.buildChartDataFromPsPnMapTranObject(psPnMapTranObject, psPnMapTranObject.processName, value);
								if (sharedLoadingChartTemp) {
									this.sharedLoadingChartForPrimaryStream.push(sharedLoadingChartTemp);
								}
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					}
				}
			}
			this.carRequest.psPnMapTransList.forEach(primeStream => {
				if (!primeStream.processDataList[0].dedicated && this.noSharedPrimaryStrem === true) {
					this.noSharedPrimaryStrem = false;
				}
			});
		}
		console.log('final buildChartDataForPrimaryStream sharedLoadingChart');
	}

	buildChartDataFromPsPnMapTranObject(psPnMapTranObject: PsPnMapTran, processStreamName: string, slRoot: SharedLoadingRoot): SharedLoadingChart {
		let sharedLoadingChartTemp: SharedLoadingChart;
		this.isSharedLoadingExistInPS = true;
		sharedLoadingChartTemp = this.buildAPWAndMPWChartData(slRoot, psPnMapTranObject, processStreamName);
		return sharedLoadingChartTemp;
	}

	buildAPWAndMPWChartData(sharedLoadingRoot: SharedLoadingRoot, psPnMapTranObject: PsPnMapTran, processStreamName: string): SharedLoadingChart {
		const sharedLoadingChartTemp = _.cloneDeep(sharedLoadingChartTemplate);
		sharedLoadingChartTemp.processName = psPnMapTranObject.processName;
		sharedLoadingChartTemp.sharedLoadingLink = psPnMapTranObject.sharedLoadingLink;
		sharedLoadingChartTemp.processStreamName = processStreamName;
		const sharedLoadingAPW = sharedLoadingRoot.sharedLoadingList[0];
		const sharedLoadingMPW = sharedLoadingRoot.sharedLoadingList[1];

		//APW
		sharedLoadingChartTemp.apwChart = this.extractAndBuildChartData(sharedLoadingAPW);

		//MPW
		sharedLoadingChartTemp.mpwChart = this.extractAndBuildChartData(sharedLoadingMPW);
		sharedLoadingChartTemp.mpwChart.labels = sharedLoadingChartTemp.apwChart.labels;
		sharedLoadingChartTemp.mpwChart.datasets[0].backgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].backgroundColor;
		sharedLoadingChartTemp.mpwChart.datasets[0].hoverBackgroundColor = sharedLoadingChartTemp.apwChart.datasets[0].hoverBackgroundColor;
		return sharedLoadingChartTemp;
	}

	buildColors(count, dataSetAPW: Dataset): Dataset {
		dataSetAPW.backgroundColor = [];
		dataSetAPW.hoverBackgroundColor = [];
		for (let i = 0; i < count; i++) {
			if (i === (count - 1)) {
				dataSetAPW.backgroundColor.push(chartColors[chartColors.length - 1].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[chartColors.length - 1].hoverBackgroundColor);
			} else {
				dataSetAPW.backgroundColor.push(chartColors[i].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[i].hoverBackgroundColor);
			}
		}
		return dataSetAPW;
	}

	extractAndBuildChartData(sharedLoading: SharedLoading): ChartData {
		const chartData: ChartData = {
			'labels': [],
			'datasets': [],
			'isSharedLoadingExceeds100Percent': false,
			'height': 0
		};
		const vLineList: string [] = [];

		//Data
		const dataSetAPW = _.cloneDeep(dataSetTemplate);
		const allocPercentData = [];
		let totalAllocation = 0;
		let remainingAllocation = 0;

		//Color
		dataSetAPW.backgroundColor = [];
		dataSetAPW.hoverBackgroundColor = [];
		const isCapacityStudyScenario = this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? true : false;
		//Handling for Capacity Study Scenario
		const sharedLoadingPlanHolderList = [];
		sharedLoading.sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
			const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
			if (sharedLoadingPlan.isCapacity) {
				let indexValue = 0;
				sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
					if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
						indexValue = indx;
					}
				});
				sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
			} else {
				sharedLoadingPlanHolder.apw = sharedLoadingPlan;
				sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
			}
		});

		sharedLoadingPlanHolderList.forEach((sharedLoadingPlanHolder, slpIndex) => {
			if (vLineList && vLineList.length > 0 && vLineList.includes(sharedLoadingPlanHolder.apw.vehLine)) {
				const index = vLineList.indexOf(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData[index] = allocPercentData[index] + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
			} else {
				vLineList.push(sharedLoadingPlanHolder.apw.vehLine);
				if (isCapacityStudyScenario && sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired) {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.capacityAPW.allocationTotalRequired, 1);
				} else {
					allocPercentData.push(_.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1));
					totalAllocation = totalAllocation + _.round(sharedLoadingPlanHolder.apw.allocationTotalRequired, 1);
				}
				dataSetAPW.backgroundColor.push(chartColors[slpIndex].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[slpIndex].hoverBackgroundColor);
			}
		});


		/*
		Commented: as same functionality added with capacity study value implemented above:
		sharedLoading.sharedLoadingPlanList.forEach((sharedLoadingPlan, slpIndex) => {
			const allocationTotalReqTemp = _.round(sharedLoadingPlan.allocationTotalRequired, 2);
			if (vLineList && vLineList.length > 0 && vLineList.includes(sharedLoadingPlan.vehLine)) {
				const index = vLineList.indexOf(sharedLoadingPlan.vehLine);
				allocPercentData[index] = allocPercentData[index] + allocationTotalReqTemp;
			} else {
				vLineList.push(sharedLoadingPlan.vehLine);
				allocPercentData.push(allocationTotalReqTemp);
				dataSetAPW.backgroundColor.push(chartColors[slpIndex].backgroundColor);
				dataSetAPW.hoverBackgroundColor.push(chartColors[slpIndex].hoverBackgroundColor);
			}
			totalAllocation = totalAllocation + allocationTotalReqTemp;
		});*/
		vLineList.push('Free');
		this.vLineGreaterTwenty = 0;
		vLineList.forEach(vLine => {
			if (vLine.length > 21) {
				this.vLineGreaterTwenty += 1;
			}
		});
		chartData.labels = vLineList;
		if ((chartData.labels.length >= 2 && chartData.labels.length <= 7 && this.vLineGreaterTwenty >= 2) || (this.vLineGreaterTwenty === 0 && chartData.labels.length > 10)) {
			chartData.height = 200;
		} else if (chartData.labels.length >= 8 && chartData.labels.length <= 10 && this.vLineGreaterTwenty > 1) {
			chartData.height = 300;
		} else if (chartData.labels.length >= 11 && chartData.labels.length <= 13 && this.vLineGreaterTwenty >= 1) {
			chartData.height = 400;
		} else if (chartData.labels.length >= 14 && chartData.labels.length <= 16 && this.vLineGreaterTwenty >= 1) {
			chartData.height = 500;
		} else if (chartData.labels.length > 16 && this.vLineGreaterTwenty >= 1) {
			chartData.height = 600;
		} else {
			chartData.height = 100;
		}
		totalAllocation = _.round(totalAllocation, 1);
		remainingAllocation = 100 - totalAllocation;
		if (remainingAllocation < 0) {
			chartData.isSharedLoadingExceeds100Percent = true;
		}
		remainingAllocation = _.round(remainingAllocation, 1);
		remainingAllocation = remainingAllocation <= 0 ? 0 : remainingAllocation;
		allocPercentData.push(remainingAllocation);

		dataSetAPW.data = allocPercentData;
		dataSetAPW.backgroundColor.push(chartColors[chartColors.length - 1].backgroundColor);
		dataSetAPW.hoverBackgroundColor.push(chartColors[chartColors.length - 1].hoverBackgroundColor);
		chartData.datasets[0] = dataSetAPW;
		return chartData;
	}

	extractAllocPercentData(sharedLoading: SharedLoading): number[] {
		const allocPercentData = [];
		let totalAllocation = 0;
		let remainingAllocation = 0;
		sharedLoading.sharedLoadingPlanList.forEach((sharedLoadingPlan, slpIndex) => {
			const allocationTotalReqTemp = _.round(sharedLoadingPlan.allocationTotalRequired);
			allocPercentData.push(allocationTotalReqTemp);
			totalAllocation = totalAllocation + allocationTotalReqTemp;
		});
		totalAllocation = _.round(totalAllocation);
		remainingAllocation = 100 - totalAllocation;
		remainingAllocation = _.round(remainingAllocation);
		allocPercentData.push(remainingAllocation);
		return allocPercentData;
	}

	onClick() {
		this.retrieveSharedLoadingIdBySiteCodeAndBuildChart();
	}

	onClose() {
		this.displaySharedLoadingChartPopup = false;
	}

	checkForFreeAllocationMessage(chartData: ChartData): boolean {
		let showMessage = false;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			const freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
			if (freeAllocation > 0 && freeAllocation <= 10) {
				showMessage = true;
			}
		}
		return showMessage;
	}

	getFreeAllocation(chartData: ChartData): number {
		let freeAllocation = 0;
		if (chartData && chartData.datasets && chartData.datasets.length > 0) {
			freeAllocation = chartData.datasets[0].data[chartData.datasets[0].data.length - 1];
		}
		return freeAllocation;
	}

	openSLP(sharedLoadingLink: string) {
		console.log('### sharedLoadingLink in chart ### ' + sharedLoadingLink);
		window.open(sharedLoadingLink);
	}
}
