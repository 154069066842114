import {Injectable} from '@angular/core';
import {Config} from '../config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ExampleService {
	private config = new Config();
	url = `${this.config.url}/welcome`;
	private options = {
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	retrieveWelcome(): Observable<string> {
		return this.http.get(this.url, {responseType: 'text'});
	}
}
