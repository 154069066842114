import { Component, OnInit } from '@angular/core';

@Component({
	template: `<h3><p>Unable to retrieve data from APS ( Security ). Please close the application and try to login in a new window/session
		after an hour.</p>
  </h3><h3><p>If the issue persists please contact eCAR support team.</p></h3>
	<i class="far fa-sad-tear fa-8x mt-2" aria-hidden="true"></i>`
})
export class ApsErrorPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
