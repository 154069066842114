import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DialogService} from 'primeng/api';
import {Policies} from '../../../models/aps-policies';
import {UtilService} from '../../../services/util.service';
import {OndeleteDialogueboxComponent} from '../../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../../models/on-delete-display-dialogue-model';
import {Title} from '@angular/platform-browser';
import {RevisionHistory} from '../../../models/revision-history';
import {
	ManageRevisionHistoryService
} from '../../../services/master/manage-revision-history/manage-revision-history.service';

@Component({
	selector: 'manage-revision-history',
	templateUrl: './manage-revision-history.component.html',
	styleUrls: ['./manage-revision-history.component.css']
})
export class ManageRevisionHistoryComponent implements OnInit {
	policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;

	constructor(private utilService: UtilService, private router: Router, public dialogService: DialogService, private revisionService: ManageRevisionHistoryService, private title: Title) {
	}

	revisionHistory: RevisionHistory[];

	ngOnInit() {
		this.findAll();
		this.findAllPolicies();
		this.title.setTitle('eCAR - Revision History');
	}

	onCreate() {
		this.router.navigate(['create-revision-history/create/0']);
	}

	private findAll() {
		this.revisionService.findAllByName('ManageRevisionHistory').subscribe((response: RevisionHistory[]) => {
			this.revisionHistory = response;
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onEdit(id: number) {
		this.router.navigate(['create-revision-history/edit/' + id]);
	}

	onDelete(id: number) {
		const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
			header: 'Confirmation',
			width: '30%',
			contentStyle: {'max-height': '500px', 'overflow': 'auto'}
		});

		deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
			if (deleteModel != null) {
				if (deleteModel.isDeleted) {
					this.revisionService.delete(id).subscribe(value => {
						this.utilService.pushMsg('success', 'Success', 'Revision history has been deleted successfully');
						this.findAll();
					}, error => {
						this.utilService.pushMsg('error', 'Error', 'Unable to delete Revision history!');
					});
				}
			}
		});
	}

	private findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:ManageBulletins';
		this.disableCreateButton = !this.utilService.checkPermittedUpdatePolicy(this.policies, policyName);
		this.disableDeleteButton = !this.utilService.checkPermittedDeletePolicy(this.policies, policyName);
	}
}
