import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {Observable} from 'rxjs';
import {SiteEngineerSiteCodeApproval} from '../../../models/siteengineer-sitecode-approval';
import {HttpClient} from '@angular/common/http';
import {SiteCode} from '../../../models/site-code';

@Injectable({
	providedIn: 'root'
})
export class EngineerApprovalFlowService {

	private config = new Config();
	private httpUrl = `${this.config.url}/engineerApprovalConditions`;

	constructor(private http: HttpClient) {
	}

	createSiteCodeCondition(siteEngSiteCode: SiteEngineerSiteCodeApproval): Observable<SiteEngineerSiteCodeApproval> {
		return this.http.post<SiteEngineerSiteCodeApproval>(this.httpUrl + '/createSiteCodeCondition', siteEngSiteCode);
	}

	getAvailableSiteCodeList(allSiteCodeListFromSIM: string[], selectedSiteCodes: string[]): Observable<SiteCode[]> {
		return this.http.get<SiteCode[]>(this.httpUrl + '/getAvailableUnselectedSiteCodes/' + allSiteCodeListFromSIM + '/' + selectedSiteCodes);
	}

	getAllAvailableSiteCodeList(allSiteCodeListFromSIM: string[]): Observable<SiteCode[]> {
		return this.http.get<SiteCode[]>(this.httpUrl + '/getAllAvailableUnselectedSiteCodes/' + allSiteCodeListFromSIM);
	}

	deleteSiteCodeCondition(siteCode: string): Observable<boolean> {
		return this.http.delete<boolean>(this.httpUrl + '/deleteSiteCodeCondition/' + siteCode);
	}

	findBySiteCodesForEngineerApproval(siteCodeList: string[]): Observable<SiteEngineerSiteCodeApproval[]> {
		return this.http.get<SiteEngineerSiteCodeApproval[]>(this.httpUrl + '/findAllBySiteCodesForEngApproval/' + siteCodeList);
	}

	getSiteCodeListFromSIM(loggedInEngineer: string): Observable<string[]> {
		return this.http.get<string[]>(this.httpUrl + '/getAllSiteCodesFromSIM/' + loggedInEngineer);
	}
}
