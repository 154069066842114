import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProcessName} from '../../../models/process-name';
import {map} from 'rxjs/operators';
import {ProcessStream} from '../../../models/process-stream';

@Injectable({
	providedIn: 'root'
})
export class ProcessStreamService {

	private config = new Config();
	private httpUrl = `${this.config.url}/processStream`;
	private httpUrlAllPrimary = `${this.config.url}/processStream/allPrimary`;
	private httpUrlAllPrimaryBySiteCode = `${this.config.url}/processStream/allPrimaryBySiteCode`;
	private httpUrlAllPrimaryBySiteCodeForCreateNew = `${this.config.url}/processStream/allUnAssociatedPrimaryBySiteCode`;
	private httpUrlAllSupportingByPrimaryStreamId = `${this.config.url}/processStream/allSupportingByPrimaryStreamId`;
	private httpUrlSelectedProcessNameByProcessStreamId = `${this.config.url}/processStream/retrieveSelectedProcessNameByProcessStreamId`;
	private httpUrlAvailableProcessNameByProcessStreamId = `${this.config.url}/processStream/retrieveAvailableProcessNameByProcessStreamId`;
	private httpUrlFindProcessStreamTreeById = `${this.config.url}/processStream/findProcessStreamTreeById`;
	private httpUrlFindProcessStreamBySiteCodes = `${this.config.url}/processStream/findProcessStreamBySiteCodes`;
	private httpUrlFindDraftCARAssociatedByStreamId = `${this.config.url}/processStream/findDraftCARAssociatedWithStreamId`;
	private httpUrlFindNonDraftCARAssociatedByStreamId = `${this.config.url}/processStream/findNonDraftCARAssociatedWithStreamId`;
	private httpUrlFindStreamAssociatedWithDraftCARHasChildren = `${this.config.url}/processStream/findStreamAssociatedWithDraftCARHasChildren`;
	private httpUrlDeleteTranEntriesByStreamId = `${this.config.url}/processStream/deleteTranEntriesByStreamId`;
	private httpUrlFindSupProcessStreamsForSelectedStreamId = `${this.config.url}/processStream/findSupProcessForSelectedStreamId`;
	private httpUrlDeleteTreeForSelectedStreamId = `${this.config.url}/processStream/deleteProcessStreamTreeForSelectedStreamId`;
	private httpUrlFindAllProcessStream = `${this.config.url}/processStream/findAll`;
	private httpUrlCopyProcessStream = `${this.config.url}/processStream/copyProcessStream`;
	private httpUrlFindProcessStreamTranTreeById = `${this.config.url}/processStream/findProcessStreamTranTreeById`;
	private httpUrlAllPrimaryBySiteCodeFromTrans = `${this.config.url}/processStream/allPrimaryBySiteCodeFromTrans`;
	private httpUrlSelectedProcessNameByProcessStreamIdAndReqId = `${this.config.url}/processStream/retrieveSelectedProcessNameByProcessStreamIdAndReqId`;
	private httpUrlFindOriginProcessStreamId = this.httpUrl + '/findOriginProcessStreamId';

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrl).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	findAllProcessStreamsBySiteCodes(): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlFindProcessStreamBySiteCodes).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	findAllProcessStream(pageSizeRequest) {
		let params = new HttpParams().set('page', pageSizeRequest.page).set('size', pageSizeRequest.size);
		if (pageSizeRequest.sort && pageSizeRequest.sort.length > 0) {
			params = params.set('sort', pageSizeRequest.sort);
		}
		if (pageSizeRequest.filters && pageSizeRequest.filters.length > 0) {
			params = params.set('filters', pageSizeRequest.filters);
		}
		if (pageSizeRequest.userRole && pageSizeRequest.userRole.length > 0) {
			params = params.set('userRole', pageSizeRequest.userRole);
		}
		if (pageSizeRequest.siteCodes && pageSizeRequest.siteCodes.length > 0) {
			params = params.set('siteCodes', pageSizeRequest.siteCodes);
		}
		return this.http.get(this.httpUrlFindAllProcessStream, {params});
	}

	create(processStream: ProcessStream): Observable<ProcessStream> {
		return this.http.post<ProcessStream>(this.httpUrl, processStream);
	}

	update(id: number, processStream: ProcessStream): Observable<ProcessStream> {
		return this.http.put<ProcessStream>(this.httpUrl + '/' + id, processStream);
	}

	delete(id: number): Observable<ProcessStream> {
		return this.http.delete<ProcessStream>(this.httpUrl + '/' + id);
	}

	findProcessStreamById(id: number): Observable<ProcessStream> {
		return this.http.get<ProcessStream>(this.httpUrl + '/' + id);
	}

	findAllPrimaryProcessStreamEdit(siteCode: string): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlAllPrimaryBySiteCode + '/' + siteCode).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	findAllPrimaryProcessStream(): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlAllPrimary).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams !== null ? processStreams.map(processStream => {
				return new ProcessStream(processStream);
			}) : null;
		}));
	}

	findAllSupportingStreamByPrimaryStreamId(id: number): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlAllSupportingByPrimaryStreamId + '/' + id).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	retrieveSelectedProcessNameByProcessStreamId(id: number): Observable<ProcessName[]> {
		return this.http.get(this.httpUrlSelectedProcessNameByProcessStreamId + '/' + id).pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	retrieveAvailableProcessNameByProcessStreamId(id: number): Observable<ProcessName[]> {
		return this.http.get(this.httpUrlAvailableProcessNameByProcessStreamId + '/' + id).pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	findProcessStreamTreeById(id: number): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlFindProcessStreamTreeById + '/' + id).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	findAllPrimaryProcessStreamForCreateNew(siteCode: string): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlAllPrimaryBySiteCodeForCreateNew + '/' + siteCode).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	checkDraftCARAssociatedWithStreamId(processStreamId: number, isPrimary: boolean): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlFindDraftCARAssociatedByStreamId + '/' + isPrimary + '/' + processStreamId);
	}

	checkNonDraftCARAssociatedWithStreamId(processStreamId: number, isPrimary: boolean): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlFindNonDraftCARAssociatedByStreamId + '/' + isPrimary + '/' + processStreamId);
	}

	isSuppStreamIdAssociatedWithDraftCARHasChildren(processStreamId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlFindStreamAssociatedWithDraftCARHasChildren + '/' + processStreamId);
	}

	isSupProcessStreamsPresentForSelectedStreamId(processStreamId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlFindSupProcessStreamsForSelectedStreamId + '/' + processStreamId);
	}

	deleteTranEntriesByProcessStreamId(processStreamId: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlDeleteTranEntriesByStreamId + '/' + processStreamId);
	}

	deleteProcessStreamTreeForSelectedStreamId(id: number): Observable<boolean> {
		return this.http.delete<boolean>(this.httpUrlDeleteTreeForSelectedStreamId + '/' + id);
	}

	copySelectedProcessStream(processStreamId: number, selectedOption: number): Observable<boolean> {
		return this.http.get<boolean>(this.httpUrlCopyProcessStream + '/' + selectedOption + '/' + processStreamId);
	}

	findProcessStreamTranTreeById(id: number, reqId: number): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlFindProcessStreamTranTreeById + '/' + id + '/' + reqId).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	findAllPrimaryProcessStreamEditFromTrans(siteCode: string, reqId: number): Observable<ProcessStream[]> {
		return this.http.get(this.httpUrlAllPrimaryBySiteCodeFromTrans + '/' + siteCode + '/' + reqId).pipe(map((processStreams: ProcessStream[]) => {
			return processStreams.map(processStream => {
				return new ProcessStream(processStream);
			});
		}));
	}

	retrieveSelectedProcessNameByProcessStreamIdAndReqId(id: number, reqId: number): Observable<ProcessName[]> {
		return this.http.get(this.httpUrlSelectedProcessNameByProcessStreamIdAndReqId + '/' + id + '/' + reqId).pipe(map((processNames: ProcessName[]) => {
			return processNames.map(processName => {
				return new ProcessName(processName);
			});
		}));
	}

	getOriginStreamIdBySuppStreamId(suppStreamId: number): Observable<number> {
		return this.http.get<number>(this.httpUrlFindOriginProcessStreamId + '/' + suppStreamId);
	}

	findAllReqStatusByProcessStreamId(id: number, isPrimary: boolean ): Observable<String[]> {
		return this.http.get<string[]>(this.httpUrl + '/findAllReqStatusByProcessStreamId/' + isPrimary + '/' + id);
	}
}
