import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '../../../config';
import {Observable} from 'rxjs';
import {ProcessType} from '../../../models/process-type';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ProcessTypeService {
	private config = new Config();
	private httpUrl = `${this.config.url}/processType`;

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<ProcessType[]> {
		return this.http.get(this.httpUrl).pipe(map((processTypes: ProcessType[]) => {
			return processTypes.map(processType => {
				return new ProcessType(processType);
			});
		}));
	}

	findAllActiveProcessTypes(): Observable<ProcessType[]> {
		return this.http.get(this.httpUrl + '/activeProcessTypes').pipe(map((processTypes: ProcessType[]) => {
			return processTypes.map(processType => {
				return new ProcessType(processType);
			});
		}));
	}

	create(processType: ProcessType): Observable<ProcessType> {
		return this.http.post<ProcessType>(this.httpUrl, processType);
	}

	update(id: number, processType: ProcessType): Observable<ProcessType> {
		return this.http.put<ProcessType>(this.httpUrl + '/' + id, processType);
	}

	checkProcessNameAssociatedWithProcessTypeId(processTypeId: number) {
		return this.http.get<boolean>(this.httpUrl + '/findProcessNameExists/' + processTypeId);
	}

	delete(id: number): Observable<ProcessType> {
		return this.http.delete<ProcessType>(this.httpUrl + '/' + id);
	}

	downloadExcel() {
		return this.http.get(this.httpUrl + '/downloadProcessTypes', {observe: 'response', responseType: 'blob'});
	}
}
