import {Component, OnInit} from '@angular/core';
import {ProcessTypeService} from '../../services/master/process-type/process-type.service';
import {ProcessType} from '../../models/process-type';
import {DialogService} from 'primeng/api';
import {Policies} from '../../models/aps-policies';
import {UtilService} from '../../services/util.service';
import {OndeleteDialogueboxComponent} from '../../ondelete-dialoguebox/ondelete-dialoguebox.component';
import {OnDeleteDisplayDialogueModel} from '../../models/on-delete-display-dialogue-model';
import {Title} from '@angular/platform-browser';


@Component({
	selector: 'process-type',
	templateUrl: './process-type.component.html',
	styleUrls: ['./process-type.component.css']
})
export class ProcessTypeComponent implements OnInit {
	processTypes: ProcessType [];
	clonedProcessType: { [s: string]: ProcessType; } = {};
	row = true;
	processTypeName: string;
	processTypeDesc: string;
	processTypeObj: any;
	active = true;
	private policies: Policies;
	disableCreateButton: boolean;
	disableDeleteButton: boolean;
	associatedWithProcessName: boolean;

	constructor(private utilService: UtilService, private processTypeService: ProcessTypeService, public dialogService: DialogService, private title: Title) {
	}

	ngOnInit() {
		this.findAllProcessTypes();
		this.findAllPolicies();
		this.title.setTitle('eCAR - Process Type');
	}

	private findAllProcessTypes() {
		this.processTypeService.findAll().subscribe((response: ProcessType[]) => {
			this.processTypes = response;
		});
	}

	onRowEditInit(processType: ProcessType) {
		this.clonedProcessType[processType.processTypeId] = {...processType};
	}

	onRowDelete(processType: ProcessType) {
		this.processTypeService.checkProcessNameAssociatedWithProcessTypeId(processType.processTypeId).subscribe(value => {
			this.associatedWithProcessName = value;
			if (!this.associatedWithProcessName) {
				const deleteDialogueRef = this.dialogService.open(OndeleteDialogueboxComponent, {
					header: 'Confirmation',
					width: '30%',
					contentStyle: {'max-height': '500px', 'overflow': 'auto'}
				});

				deleteDialogueRef.onClose.subscribe((deleteModel: OnDeleteDisplayDialogueModel) => {
					if (deleteModel != null) {
						if (deleteModel.isDeleted) {
							this.processTypeService.delete(processType.processTypeId).subscribe(value => {
								this.utilService.pushMsg('success', 'Success', 'Process Type is deleted successfully');
								this.findAllProcessTypes();
							}, error => {
								this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
							});
						} else {
							console.log('dont Delete response from dialogue box');
						}
					}
				});
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}


	onUpdate(processType: ProcessType, index) {
		let isValid = true;
		if (processType.processTypeId > 0 && !processType.processTypeName && processType.processTypeName.trim() === '') {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Process Type is Invalid');
		}
		if (processType.processTypeDesc === '') {
			isValid = false;
			this.utilService.pushMsg('error', 'Error', 'Description should not be empty');
		}
		if (isValid) {
			delete this.clonedProcessType[processType.processTypeId];
			this.processTypeService.update(processType.processTypeId, processType).subscribe(value => {
				this.utilService.pushMsg('success', 'Success', 'Process Type is updated successfully');
				this.findAllProcessTypes();
			}, error => {
				if (error.error.message === 'Process Type already exist' || null != error.error.message) {
					this.utilService.pushMsg('error', 'Error', 'Process Type is already exist');
				} else {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				}
				this.findAllProcessTypes();
			});
		}
	}

	private resetFields() {
		this.processTypeName = '';
		this.processTypeDesc = '';
		this.active = true;
	}

	onRowEditCancel(processType: ProcessType, index: number) {
		this.processTypes[index] = this.clonedProcessType[processType.processTypeId];
		delete this.clonedProcessType[processType.processTypeId];
	}

	onCreate() {
		if (this.processTypeName && this.processTypeDesc && this.processTypeName.trim() !== '') {
			this.processTypeObj = {
				processTypeName: this.processTypeName,
				processTypeDesc: this.processTypeDesc,
				active: this.active
			};
			this.processTypeService.create(this.processTypeObj).subscribe((value) => {
				this.utilService.pushMsg('success', 'Success', 'Process Type is created successfully');
				this.findAllProcessTypes();
				this.resetFields();
			}, error => {
				if (error.error.message === 'Process Type already exist' || null != error.error.message) {
					this.utilService.pushMsg('error', 'Error', 'Process Type is already exist');
				} else {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				}

			});
		} else {
			this.utilService.pushMsg('error', 'Error', this.processTypeName ? 'Description should not be empty' : 'Process Type should not be empty');
		}
	}

	onClose($event: any) {
		this.resetFields();
	}

	findAllPolicies() {
		this.policies = JSON.parse(sessionStorage.getItem('policies'));
		const policyName = 'eCAR:DefineProcessType';
		this.disableCreateButton = this.utilService.checkPermittedUpdatePolicy(this.policies, policyName) ? false : true;
		this.disableDeleteButton = this.utilService.checkPermittedDeletePolicy(this.policies, policyName) ? false : true;
	}
}

