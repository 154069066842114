import {ProcessStreamProcessNameMap} from './process-stream-process-name-map';
import {PrimarySupportingMap} from './primary-supporting-map';
import {ProcessName} from './process-name';

export class ProcessStream {
	id: number;
	processStreamName: string;
	siteCode: string;
	isPrimaryStream: boolean;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	mapToProcessStream: number;
	mapToProcessName: number;
	status: string;
	validationErrors: string[];
	mapToStreamId: number;
	connectingProcessNameId: number;
	processStreamProcessNameMap: ProcessStreamProcessNameMap[];
	primarySupportingMap: PrimarySupportingMap[];
	originProcessStreamId: number;
	availableProcessNames: ProcessName[];
	processNameList?: ProcessName[];

	constructor(processStream: ProcessStream) {
		Object.assign(this, processStream);
	}
}

export const emptyProcessStreamDroprdown = {
	connectingProcessNameId: 0,
	createdBy: '',
	createdDate: 0,
	id: 0,
	isPrimaryStream: false,
	mapToProcessName: 0,
	mapToProcessStream: 0,
	mapToStreamId: 0,
	primarySupportingMap: [],
	processStreamName: 'Please Select Stream',
	processStreamProcessNameMap: [],
	siteCode: '',
	status: '',
	updatedBy: '',
	updatedDate: 0,
	validationErrors: [],
	originProcessStreamId: 0,
	availableProcessNames: []
};

export const emptyProcessStreamModel = {
	connectingProcessNameId: 0,
	createdBy: '',
	createdDate: 0,
	id: -1,
	isPrimaryStream: false,
	mapToProcessName: 0,
	mapToProcessStream: 0,
	mapToStreamId: 0,
	primarySupportingMap: [],
	processStreamName: '',
	processStreamProcessNameMap: [],
	siteCode: '',
	status: '',
	updatedBy: '',
	updatedDate: 0,
	validationErrors: [],
	originProcessStreamId: 0,
	availableProcessNames: []
};