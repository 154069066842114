export class DropdownOption {
	name: string;
	code: string;

	constructor(dropdownOption: DropdownOption) {
		Object.assign(this, dropdownOption);
	}
}

export const template: DropdownOption[] = [
	{name: 'All', code: 'All'},
	{name: 'Capacity Planning', code: 'Capacity Planning'},
	{name: 'Phase 0 PPAP', code: 'Phase 0 PPAP'},
	{name: 'Phase 3 PPAP', code: 'Phase 3 PPAP'}
];

export const answerOptions: DropdownOption[] = [
	{name: 'Descriptive Type', code: 'Descriptive'},
	{name: 'Yes / No', code: 'Yes / No'},
	{name: 'Date Picker', code: 'Date Picker'}
];

export const optionGroup: DropdownOption[] = [
	{name: 'Yes or No', code: 'YesNo'},
	{name: 'Option type 1', code: 'Option1'},
	{name: 'Option type 2', code: 'Option2'},
	{name: 'Option type 3', code: 'Option3'}
];

export const yesNoOption: DropdownOption[] = [
	{name: 'Yes', code: 'Yes'},
	{name: 'No', code: 'No'}
];
