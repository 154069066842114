import {Injectable} from '@angular/core';
import {AuthGuardService} from './oauth/auth-guard.service';
import {HttpClient} from '@angular/common/http';
import {Config} from './config';
import {Policies} from './models/aps-policies';
import {Observable} from 'rxjs';
import {ApsAttributes} from './models/aps-attributes';

@Injectable({
	providedIn: 'root'
})
export class ApsService {
	private config = new Config();
	private serviceUrl = `${this.config.url}/aps`;
	private readonly _token;

	constructor(private authGuardService: AuthGuardService, private http: HttpClient) {
		this._token = sessionStorage.getItem('encodedAccessToken');
	}

	getAllDecisions(): Observable<Policies> {
		this.authGuardService.checkLogin();
		return this.http.get<Policies>(this.serviceUrl + '/allDecisions');
	}

	retrieveAttributes(): Observable<ApsAttributes> {
		return this.http.get<ApsAttributes>(this.serviceUrl + '/retrieveAttributes');
	}

	retrieveAtrributesForEngineerKeyContactCheck(userId: string): Observable<ApsAttributes> {
		return this.http.get<ApsAttributes>(this.serviceUrl + '/retrieveAtrributesForEngineerCheck/' + userId);
	}
}
