import {
	emptySLPAPWPart1,
	emptySLPAPWPart2,
	emptySLPMPWPart1,
	emptySLPMPWPart2,
	SharedLoadingPlan
} from './shared-loading-plan';

export class SharedLoading {
	id: number;
	siteCode: string;
	processNameId: number;
	daysPerWeek: number;
	shiftsPerDay: number;
	hrsPerShift: number;
	contPlanned: number;
	netAvailableTime: number;
	demonOEE: number;
	isAPW: boolean;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	sharedLoadingPlanList: SharedLoadingPlan[];

	constructor(sharedLoading: SharedLoading) {
		Object.assign(this, sharedLoading);
	}
}

export const emptySL = {
	'id': 0,
	'siteCode': '',
	'processNameId': 0,
	'daysPerWeek': null,
	'shiftsPerDay': null,
	'hrsPerShift': null,
	'contPlanned': null,
	'netAvailableTime': 0,
	'demonOEE': 0,
	'isAPW': true,
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'sharedLoadingPlanList': []
};

export const testDataSLAPW = {
	'id': 0,
	'siteCode': '',
	'processNameId': 0,
	'daysPerWeek': 5,
	'shiftsPerDay': 2,
	'hrsPerShift': 12,
	'contPlanned': 60,
	'netAvailableTime': 0,
	'demonOEE': 0,
	'isAPW': true,
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'sharedLoadingPlanList': [emptySLPAPWPart1, emptySLPAPWPart2]
};

export const testDataSLMPW = {
	'id': 0,
	'siteCode': '',
	'processNameId': 0,
	'daysPerWeek': 6,
	'shiftsPerDay': 2,
	'hrsPerShift': 12,
	'contPlanned': 60,
	'netAvailableTime': 0,
	'demonOEE': 0,
	'isAPW': true,
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null,
	'sharedLoadingPlanList': [emptySLPMPWPart1, emptySLPMPWPart2]
};
