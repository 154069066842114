import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {jsPlumb} from 'jsplumb';
import {ProcessStreamService} from '../../../services/master/process-stream/process-stream.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProcessStream} from '../../../models/process-stream';
import {CarRequestService} from '../../../services/request/car-request.service';
import {CarRequest} from '../../../models/car-request';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UtilService} from '../../../services/util.service';
import {linkColors} from '../../../models/chart-data';
import {HistoricalMfgPerf} from '../../../models/historical-mfgperf';
import {HistoricalMfgPerfService} from '../../../services/master/historical-mfgperf/historical-mfgperf.service';
import {ManageSharedLoadingService} from '../../../services/master/manage-shared-loading/manage-shared-loading.service';
import {SharedLoadingRoot} from '../../../models/shared-loading-root';
import * as moment from 'moment';
import {SharedLoadingPlan, SharedLoadingPlanHolder} from '../../../models/shared-loading-plan';
import {SpinnerService} from '../../../services/spinner/spinner.service';

@Component({
	selector: 'summary-process-stream',
	templateUrl: './summary-process-stream.component.html',
	styleUrls: ['./summary-process-stream.component.css']
})
export class SummaryProcessStreamComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() primaryStreamId: number;
	@Input() carRequest: CarRequest;
	@Input() isLinked: boolean;
	@Input() isCapacityStudy: boolean;
	@Input() capacityRequirementSource: string;
	@Input() hideCapacityStudyAttributes: boolean;
	@Input() hideBottleNeckProcess: boolean;
	@Input() mode: string;

	isConnectionMade = false;
	jsPlumbInstance;
	jsPlumbParentInstance;
	common = {
		anchors: ['BottomCenter', 'TopCenter'],
		endpoints: ['Dot', 'Blank']
	};
	processStreamTree: ProcessStream[] = [];
	parentProcessStreamTree: ProcessStream[] = [];
	parentCAR: CarRequest;
	isParent = false;
	private unsubscribe$: Subject<any> = new Subject<any>();
	private processBoxSizewithBottleNeck = '380px';
	private processBoxSizewithOutBottleNeck = '340px';
	expandAllWarningRiskErrorInfo = false;
	hideProcessDetailsValidation: boolean;
	historicalMfgPerfObj: any;
	historicalmfgperfs: HistoricalMfgPerf[];
	sharedLoadingRoot: SharedLoadingRoot;
	isCapacityStudyScenario = false;
	expandAllLinkText: string;

	constructor(private spinnerService: SpinnerService, private utilService: UtilService, private manageSharedLoadingService: ManageSharedLoadingService, private hmpService: HistoricalMfgPerfService, private router: Router, private route: ActivatedRoute, private processStreamService: ProcessStreamService, private carRequestService: CarRequestService) {
	}

	ngOnInit() {
		this.carRequestService.showViewStreamSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
			if (this.primaryStreamId > 0 && val) {
				if (this.processStreamTree.length < 1) {
					if ((this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason === null) || ((this.carRequest.requestStatus === 'REJECTED' || (this.carRequest.requestStatus === 'DRAFT' && this.carRequest.recallReason !== null)) && this.carRequest.keepHistoricalPSInRejRecall === false && this.carRequest.isProcessStreamAvailable)) {
						this.expandAllLinkText = this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT' ? 'Expand All Warning/Risk/Error Details' : 'Expand All Warning/Risk Details';
						this.processStreamService.findProcessStreamTreeById(this.primaryStreamId).subscribe(value => {
							this.processStreamTree = value;
							this.processStreamTree.forEach(processStreamTress => {
								processStreamTress.availableProcessNames.forEach(processName => {
									processName.expandSelectedProcess = false;
									processName.processBoxSizewithBottleNeck = '380px';
									processName.processBoxSizewithOutBottleNeck = '340px';
								});
							});
							this.isOldECARWithinTheDateLimit();
							if (this.isLinked) {
								this.carRequestService.findById(this.carRequest.selectedCarIdForLinkage).subscribe(parentCAR => {
									this.isParent = true;
									this.parentCAR = parentCAR;
									if (this.parentProcessStreamTree.length < 1) {
										this.processStreamService.findProcessStreamTreeById(this.parentCAR.primaryStreamId).subscribe(parentStreamTree => {
											this.parentProcessStreamTree = parentStreamTree;
										}, error => {
											this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
										});
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					} else {
						this.expandAllLinkText = this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT' ? 'Expand All Warning/Risk/Error Details' : 'Expand All Warning/Risk Details';
						this.processStreamService.findProcessStreamTranTreeById(this.primaryStreamId, this.carRequest.reqId).subscribe(value => {
							this.processStreamTree = value;
							this.processStreamTree.forEach(processStreamTress => {
								processStreamTress.availableProcessNames.forEach(processName => {
									processName.expandSelectedProcess = false;
									processName.processBoxSizewithBottleNeck = '380px';
									processName.processBoxSizewithOutBottleNeck = '340px';
								});
							});
							this.isOldECARWithinTheDateLimit();
							if (this.isLinked) {
								this.carRequestService.findById(this.carRequest.selectedCarIdForLinkage).subscribe(parentCAR => {
									this.isParent = true;
									this.parentCAR = parentCAR;
									if (this.parentProcessStreamTree.length < 1) {
										this.processStreamService.findProcessStreamTranTreeById(this.parentCAR.primaryStreamId, this.carRequest.reqId).subscribe(parentStreamTree => {
											this.parentProcessStreamTree = parentStreamTree;
										}, error => {
											this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
										});
									}
								}, error => {
									this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
								});
							}
						}, error => {
							this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
						});
					}

				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	ngAfterViewInit(): void {
		this.carRequestService.showAnchorsStreamSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
			if (this.primaryStreamId && val) {
				setTimeout(() => {
					if (this.processStreamTree && this.processStreamTree.length > 0) {
						if (this.jsPlumbInstance) {
							this.jsPlumbInstance.reset();
						}
						this.isConnectionMade = false;
						this.connectProcessNames(this.processStreamTree, true);
					}
					if (this.isLinked) {
						if (this.parentProcessStreamTree && this.parentProcessStreamTree.length > 0) {
							if (this.jsPlumbParentInstance) {
								this.jsPlumbParentInstance.reset();
							}
							this.isConnectionMade = false;
							this.connectProcessNames(this.parentProcessStreamTree, false);
						}
					}
				}, 4000);
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
		this.carRequestService.destroyAnchorsStreamSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
			if (val) {
				this.isConnectionMade = true;
				if (this.jsPlumbInstance) {
					this.jsPlumbInstance.reset();
				}
				if (this.isLinked && this.jsPlumbParentInstance) {
					this.jsPlumbParentInstance.reset();
				}
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	connectProcessNames(processStreamTree: ProcessStream[], isChild: boolean) {
		if (!this.isConnectionMade) {
			if (isChild) {
				this.jsPlumbInstance = jsPlumb.getInstance();
			} else {
				this.jsPlumbParentInstance = jsPlumb.getInstance();
			}
			const psSize = processStreamTree.length;
			for (let curTreeIndex = 0; curTreeIndex < psSize; curTreeIndex++) {
				const curProcessStream = processStreamTree[curTreeIndex];
				const availableProcessNames = curProcessStream.availableProcessNames;
				const pnSize = availableProcessNames.length;
				for (let curPNIndex = 0; curPNIndex < pnSize; curPNIndex++) { // n-1 loop to make connection
					if (curPNIndex < pnSize - 1) {
						let sourceId;
						let targetId;
						if (isChild) {
							sourceId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
							targetId = 'div-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;
							const connectJson = this.buildConnection(sourceId, targetId);
							this.jsPlumbInstance.connect(connectJson);
						} else {
							sourceId = 'parentdiv-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex].id;
							targetId = 'parentdiv-' + curProcessStream.id + '-' + availableProcessNames[curPNIndex + 1].id;
							const connectJson = this.buildConnection(sourceId, targetId);
							this.jsPlumbParentInstance.connect(connectJson);
						}
					}

					if (curTreeIndex !== 0) { // other than parent
						const priSuppoMap = curProcessStream.primarySupportingMap;
						const primaryProcessStreamId = priSuppoMap['primaryProcessStreamId'];
						const supportingProcessStreamId = priSuppoMap['supportingProcessStreamId'];
						const primaryProcessNameId = priSuppoMap['primaryProcessNameId'];

						let sourceIdPriSupp;
						let targetIdPriSupp;
						if (isChild) {
							sourceIdPriSupp = 'div-' + primaryProcessStreamId + '-' + primaryProcessNameId;
							targetIdPriSupp = 'div-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
							const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targetIdPriSupp, linkColors[curTreeIndex].colorCode);
							this.jsPlumbInstance.connect(connectJsonPriSupp);
						} else {
							sourceIdPriSupp = 'parentdiv-' + primaryProcessStreamId + '-' + primaryProcessNameId;
							targetIdPriSupp = 'parentdiv-' + supportingProcessStreamId + '-' + availableProcessNames[availableProcessNames.length - 1].id;
							const connectJsonPriSupp = this.buildPriSuppConnection(sourceIdPriSupp, targetIdPriSupp, linkColors[curTreeIndex].colorCode);
							this.jsPlumbParentInstance.connect(connectJsonPriSupp);
						}
					}
					this.isConnectionMade = true;
					if (isChild) {
						this.jsPlumbInstance.repaintEverything();
					} else {
						this.jsPlumbParentInstance.repaintEverything();
					}
				}
			}
		}
	}

	private buildConnection(sourceId, targetId) {
		const connectJson = {
			source: sourceId,
			target: targetId,
			anchors: ['Right', 'Left'],
			endpoint: 'Blank',
			endpointStyle: {fill: 'blue'},
			paintStyle: {stroke: '#03a108', strokeWidth: 1},
			hoverPaintStyle: {strokeStyle: '#dbe300'},
			connector: ['Flowchart', {cornerRadius: 2, alwaysRespectStubs: false}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	private buildPriSuppConnection(sourceIdPriSupp: string, targetIdPriSupp: string, strokeColor: string) {
		const connectJson = {
			source: targetIdPriSupp,
			target: sourceIdPriSupp,
			anchors: ['Right', 'Right'],
			paintStyle: {stroke: strokeColor, strokeWidth: 2},
			endpoint: 'Blank',
			endpointStyle: {fill: 'yellow'},
			connector: ['Bezier', {curviness: 120}],
			overlays: [['Arrow', {
				location: 1,
				id: 'arrow',
				length: 14,
				foldback: 0.4
			}]]
		};
		return connectJson;
	}

	ngOnDestroy() {
		if (this.jsPlumbInstance) {
			this.jsPlumbInstance.deleteEveryConnection();
		}
		if (this.jsPlumbParentInstance) {
			this.jsPlumbParentInstance.deleteEveryConnection();
		}
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.unsubscribe$.unsubscribe();
	}

	openSLP(sharedLoadingLink: string | undefined) {
		console.log('### sharedLoadingLink in summary ### ' + sharedLoadingLink);
		window.open(sharedLoadingLink);
	}

	expandAllWarningRiskErrorInformation() {
		if (!this.expandAllWarningRiskErrorInfo) {
			if (this.expandAllLinkText === 'Expand All Warning/Risk/Error Details') {
				this.expandAllLinkText = 'Collapse All Warning/Risk/Error Details';
			} else {
				this.expandAllLinkText = 'Collapse All Warning/Risk Details';
			}
			this.expandAllWarningRiskErrorInfo = true;
			this.processStreamTree.forEach( processStreamTress => {
				processStreamTress.availableProcessNames.forEach( processName => {
					processName.expandSelectedProcess = true;
					processName.processBoxSizewithBottleNeck = '530px';
					processName.processBoxSizewithOutBottleNeck = '480px';
				});
			});
		} else {
			if (this.expandAllLinkText === 'Collapse All Warning/Risk/Error Details') {
				this.expandAllLinkText = 'Expand All Warning/Risk/Error Details';
			} else {
				this.expandAllLinkText = 'Expand All Warning/Risk Details';
			}
			this.expandAllWarningRiskErrorInfo = false;
			this.processStreamTree.forEach( processStreamTress => {
				processStreamTress.availableProcessNames.forEach( processName => {
					processName.expandSelectedProcess = false;
					processName.processBoxSizewithBottleNeck = '380px';
					processName.processBoxSizewithOutBottleNeck = '340px';
				});
			});
		}
		this.reconstructConnection();
	}

	expandSelectedProcessBox(process: any) {
		if (!process.expandSelectedProcess) {
			process.expandSelectedProcess = true;
			process.processBoxSizewithBottleNeck = '530px';
			process.processBoxSizewithOutBottleNeck = '480px';
		} else {
			process.expandSelectedProcess = false;
			process.processBoxSizewithBottleNeck = '380px';
			process.processBoxSizewithOutBottleNeck = '340px';
		}
		this.reconstructConnection();
	}

	private isOldECARWithinTheDateLimit() {
		if (this.carRequest.requestStatus === 'SUBMITTED' || this.carRequest.requestStatus === 'COMPLETED' || this.carRequest.requestStatus === 'APPROVED' || this.carRequest.requestStatus === 'ACKNOWLEDGED') {
			this.carRequestService.isOldECARWithinTheDateLimit(this.carRequest.reqId).subscribe(isSubmitted => {
				if (isSubmitted) {
					this.hideProcessDetailsValidation = true;
				} else {
					this.hideProcessDetailsValidation = false;
				}
			});
		} else {
			this.hideProcessDetailsValidation = false;
		}
	}

	isFinite(value: number): boolean {
		return isFinite(value);
	}

	demonOEECalculate(processData: any, siteCode: any): boolean {
		if (!processData.historicalmfgperfsCheck) {
			let recordsCount = 0;
			const thirtyTwoWeeksAgo = moment().subtract(32, 'weeks').valueOf();
			processData.historicalmfgperfsCheck = true;
			this.historicalMfgPerfObj = {
				processId: processData.processNameId,
				siteCode: siteCode
			};
			this.hmpService.findLatest25HistMfgPerfRecords(this.historicalMfgPerfObj).subscribe((response: HistoricalMfgPerf[]) => {
				this.historicalmfgperfs = response;

				if (this.historicalmfgperfs && this.historicalmfgperfs.length > 0) {

					processData.demonOEE = this.utilService.calculateAverageOEE(this.historicalmfgperfs);

					this.historicalmfgperfs.forEach(hmp => {
						if (hmp.oee !== null && hmp.oee > 100) {
							processData.isOneOrMoreOEEPercentageGreaterThan100 = true;
						}
					});

					if (this.historicalmfgperfs.find(historicalObject => historicalObject.oee > 100)) {
						processData.isHmpItemExceeds100 = true;
					}

					this.historicalmfgperfs.forEach((historicalmfgperf) => {
						const historicalMfgPerfInMilliseconds = this.parseDate(historicalmfgperf.hmfDate);
						if (historicalMfgPerfInMilliseconds < thirtyTwoWeeksAgo) {
							processData.hmpOlderThan32WeeksWarningShown = true;
						}
					});

					this.historicalmfgperfs.forEach(hmp => {
						if (hmp.hmfDateDisplay !== null) {
							recordsCount++;
						}
					});
					if (recordsCount < 25) {
						processData.hmpRecordsCountLessThan25WarningShown = true;
					}
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
		return true;
	}

	parseDate(curDate: any) {
		return Date.parse(curDate);
	}

	private cycleTimeCheck(processData: any, flag: any): boolean {
		let isValid = false;
		if (processData.idealCycleTime > 0 && processData.idealCycleTime < 5) {
			isValid = this.validateRange(processData, 0.5, flag);
			return isValid;
		} else if (processData.idealCycleTime >= 5 && processData.idealCycleTime < 20) {
			isValid = this.validateRange(processData, 1, flag);
			return isValid;
		} else if (processData.idealCycleTime >= 20 && processData.idealCycleTime < 100) {
			isValid = this.validateRange(processData, 1.5, flag);
			return isValid;
		} else if (processData.idealCycleTime >= 100) {
			isValid = this.validateRange(processData, 2, flag);
			return isValid;
		}
	}

	private validateRange(processData: any, range: number, flag: any) {
		if (processData.observedAvgCycleTime > processData.idealCycleTime + range) {
			processData.cycleTimeHigher = true;
			processData.cycleTimelower = false;
		} else if (processData.observedAvgCycleTime < processData.idealCycleTime - range) {
			processData.cycleTimeHigher = false;
			processData.cycleTimelower = true;
		} else {
			processData.cycleTimeHigher = false;
			processData.cycleTimelower = false;
		}
		if (flag === 'Higher') {
			return processData.cycleTimeHigher;
		} else {
			return processData.cycleTimelower;
		}
	}

	partNameAvailabilityCheck(pspnTrans: any): boolean {
		const convertedDate = Date.parse(String(this.carRequest.updatedDate));
		const chars = this.carRequest.suffix.split('');
		let currentPartNumber: string = this.carRequest.prefix + '-' + this.carRequest.base + '-' + chars[0];
		if (currentPartNumber.includes('/')) {
			let count = currentPartNumber.split('/').length - 1;
			while (count > 0) {
				currentPartNumber = currentPartNumber.replace('/', '$');
				count--;
			}
		}
		if (!pspnTrans.partNameAvailableCheck) {
			pspnTrans.partNameAvailableCheck = true;
			if (!this.carRequest.requestStatus || this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
				this.manageSharedLoadingService.retrieveSharedLoadingIdBySiteCodeAndProcessNameId(this.carRequest.siteCode, pspnTrans.processNameId.toString()).subscribe(value => {
					this.sharedLoadingRoot = value;
					this.checkProcessNameAndPartNameAvailableInSharedLoading(pspnTrans);
				this.submissionScenatioCheck(pspnTrans);
				});
			} else {
				this.manageSharedLoadingService.retrieveSharedLoadingByCarIdAndSiteAndProcess(this.carRequest.reqId, this.carRequest.siteCode, pspnTrans.processNameId).subscribe(value => {
					this.sharedLoadingRoot = value;
					this.checkProcessNameAndPartNameAvailableInSharedLoading(pspnTrans);
					this.submissionScenatioCheck(pspnTrans);
				}, error => {
					this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
				});
			}

			this.carRequestService.findAlleCARByProcessDataId(pspnTrans.processNameId, currentPartNumber, convertedDate, this.mode).subscribe((response) => {
				pspnTrans.recordsUsedInAnotherPartNumbers = response;
				pspnTrans.isProcessUsedInAnotherPartNumber = false;
				if (pspnTrans.recordsUsedInAnotherPartNumbers.length > 0) {
					pspnTrans.isProcessUsedInAnotherPartNumber = true;
				}
			}, error => {
				this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
			});
		}
		return true;
	}

	checkProcessNameAndPartNameAvailableInSharedLoading(processData: any) {
		const partNumEntered = this.carRequest.prefix + '-' + this.carRequest.base + '-' + this.carRequest.suffix;
		if (this.sharedLoadingRoot) {
			if (this.sharedLoadingRoot.sharedLoadingList[0] && this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList) {
				const sharedLoadingPlan = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.find(slpPlan => slpPlan.partName !== partNumEntered);
				if (sharedLoadingPlan) {
					processData.isPartNameAvailableInSharedLoading = true;
				}
			}
		}
	}

	private reconstructConnection() {
		this.jsPlumbInstance.reset();
		this.spinnerService.show();
		setTimeout(() => {
			this.isConnectionMade = false;
			this.connectProcessNames(this.processStreamTree, true);
			this.spinnerService.hide();
		}, 2000);
	}

	private submissionScenatioCheck(pspnTrans: any): boolean {
		pspnTrans.reqGoodPartsAPW = null;
		pspnTrans.reqGoodPartsMPW = null;
		let partNameMatchedSlpAPW: SharedLoadingPlan;
		let partNameMatchedSlpMPW: SharedLoadingPlan;
		let isGoodPartsMatchedWithSlp = false;
		const partNameMatchedIndexs: any[] = [];
		const reqGoodPartsMatchedIndexes: any[] = [];
		const nictMatchedIndexes: any[] = [];
		const partNumEntered = this.carRequest.prefix + '-' + this.carRequest.base + '-' + this.carRequest.suffix;
		this.isCapacityStudyScenario = this.carRequest.submissionScenarioObject && this.carRequest.submissionScenarioObject['capacityStudy'] === true && this.carRequest.capacityRequirementSource === 'What-If' ? true : false;
		if (pspnTrans.processDataList[0].netIdleCycleTime && pspnTrans.processDataList[0].netIdleCycleTime !== null) {
			if (this.isCapacityStudyScenario) {
				const sharedLoadingPlanHolderList = [];
				if (this.sharedLoadingRoot) {
					this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
						const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
						if (sharedLoadingPlan.isCapacity) {
							sharedLoadingPlanHolder.capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolder.capacityMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							let indexValue = 0;
							sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
								if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
									indexValue = indx;
								}
							});
							sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolderList[indexValue].capacityMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
						} else {
							sharedLoadingPlanHolder.apw = sharedLoadingPlan;
							sharedLoadingPlanHolder.mpw = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
						}
						pspnTrans.nictAPW = sharedLoadingPlan.nict;
					});
					const matchedSLPHolderList = sharedLoadingPlanHolderList.filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase()) {
							pspnTrans.showSLPartNumMismatchMsg = false;
						}
						return sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase();
					}).filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW) {
							pspnTrans.capacityRecordNotFound = false;
						}
						return sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW;
					}).filter(sharedLoadingPlanHolder => {
						return pspnTrans.processDataList[0].netIdleCycleTime && sharedLoadingPlanHolder.capacityAPW.nict === pspnTrans.processDataList[0].netIdleCycleTime;
					});

					const fullyMatchedSLPHolderList = matchedSLPHolderList.filter(sharedLoadingPlanHolder => {
						if ((sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === pspnTrans.processDataList[0].reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === pspnTrans.processDataList[1].reqGoodPartsNext)) {
							isGoodPartsMatchedWithSlp = true;
						}
						return (sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === pspnTrans.processDataList[0].reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === pspnTrans.processDataList[1].reqGoodPartsNext);
					});

					if (isGoodPartsMatchedWithSlp && fullyMatchedSLPHolderList.length > 0) {
						this.setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(pspnTrans, fullyMatchedSLPHolderList[0]);
					} else if (matchedSLPHolderList.length > 0) {
						matchedSLPHolderList.forEach(matchedSLPHolder => {
							this.setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(pspnTrans, matchedSLPHolder);
						});
					}
				}
			} else {
				if (this.sharedLoadingRoot) {
					this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
						if (sharedLoadingPlan.partName.toUpperCase() === partNumEntered.toUpperCase() && !sharedLoadingPlan.isOtherAllocation && !sharedLoadingPlan.isCapacity) {
							partNameMatchedIndexs.push(index);
						}
					});
				}

				if (partNameMatchedIndexs.length > 0) {
					pspnTrans.showSLPartNumMismatchMsg = false;
					partNameMatchedIndexs.forEach(partNameMatchedIndex => {
						partNameMatchedSlpAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex];
						partNameMatchedSlpMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex];
						if (pspnTrans.processDataList[0].netIdleCycleTime && (pspnTrans.processDataList[0].netIdleCycleTime === partNameMatchedSlpAPW.nict)) {
							nictMatchedIndexes.push(partNameMatchedIndex);
						} else {
							if (pspnTrans.processNameId === this.sharedLoadingRoot.processNameId) {
								pspnTrans.nictAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].nict;
							}
						}
					});
				}

				if (nictMatchedIndexes.length > 0) {
					nictMatchedIndexes.forEach(nictMatchedIndex => {
						partNameMatchedSlpAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[nictMatchedIndex];
						partNameMatchedSlpMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[nictMatchedIndex];
						if (!isGoodPartsMatchedWithSlp) {
							if ((pspnTrans.processDataList[0].reqGoodPartsNext === partNameMatchedSlpAPW.reqGoodPartsPerWeek)
								&& (pspnTrans.processDataList[1].reqGoodPartsNext === partNameMatchedSlpMPW.reqGoodPartsPerWeek)) {
								isGoodPartsMatchedWithSlp = true;
								console.log('########### MATCH : ' + nictMatchedIndex);
								pspnTrans.reqGoodPartsAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[nictMatchedIndex].reqGoodPartsPerWeek;
								pspnTrans.reqGoodPartsMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[nictMatchedIndex].reqGoodPartsPerWeek;
								pspnTrans.nictAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[nictMatchedIndex].nict;
								//this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(pspnTrans, this.sharedLoadingRoot, nictMatchedIndex);
							} else {
								console.log('########### NON MATCH ' + nictMatchedIndex);
								pspnTrans.reqGoodPartsAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[nictMatchedIndex].reqGoodPartsPerWeek;
								pspnTrans.reqGoodPartsMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[nictMatchedIndex].reqGoodPartsPerWeek;
								pspnTrans.nictAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[nictMatchedIndex].nict;
								//this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(pspnTrans, this.sharedLoadingRoot, nictMatchedIndex);
							}
						}
					});
				}
			}
		} else {
			if (this.isCapacityStudyScenario) {
				const sharedLoadingPlanHolderList = [];
				if (this.sharedLoadingRoot) {
					this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
						const sharedLoadingPlanHolder = new SharedLoadingPlanHolder();
						if (sharedLoadingPlan.isCapacity) {
							sharedLoadingPlanHolder.capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolder.capacityMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							let indexValue = 0;
							sharedLoadingPlanHolderList.forEach((slPlanHolder, indx) => {
								if (slPlanHolder.apw.groupId === sharedLoadingPlan.groupId) {
									indexValue = indx;
								}
							});
							sharedLoadingPlanHolderList[indexValue].capacityAPW = sharedLoadingPlan;
							sharedLoadingPlanHolderList[indexValue].capacityMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
						} else {
							sharedLoadingPlanHolder.apw = sharedLoadingPlan;
							sharedLoadingPlanHolder.mpw = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[index];
							sharedLoadingPlanHolderList.push(sharedLoadingPlanHolder);
						}
					});
					const matchedSLPHolderList = sharedLoadingPlanHolderList.filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase()) {
							pspnTrans.showSLPartNumMismatchMsg = false;
						}
						return sharedLoadingPlanHolder.apw.partName.toUpperCase() === partNumEntered.toUpperCase();
					}).filter(sharedLoadingPlanHolder => {
						if (sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW) {
							pspnTrans.capacityRecordNotFound = false;
						}
						return sharedLoadingPlanHolder.capacityAPW && sharedLoadingPlanHolder.capacityMPW;
					}).filter(sharedLoadingPlanHolder => {
						if ((sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === pspnTrans.processDataList[0].reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === pspnTrans.processDataList[1].reqGoodPartsNext)) {
							isGoodPartsMatchedWithSlp = true;
						}
						return (sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek === pspnTrans.processDataList[0].reqGoodPartsNext) && (sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek === pspnTrans.processDataList[1].reqGoodPartsNext);
					});
					if (isGoodPartsMatchedWithSlp && matchedSLPHolderList.length > 0) {
						this.setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(pspnTrans, matchedSLPHolderList[0]);
					}
				}
			} else {
				if (this.sharedLoadingRoot) {
					this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList.forEach((sharedLoadingPlan, index) => {
						if (sharedLoadingPlan.partName.toUpperCase() === partNumEntered.toUpperCase() && !sharedLoadingPlan.isOtherAllocation && !sharedLoadingPlan.isCapacity) {
							partNameMatchedIndexs.push(index);
						}
					});

					if (partNameMatchedIndexs.length > 0) {
						pspnTrans.showSLPartNumMismatchMsg = false;
						partNameMatchedIndexs.forEach(partNameMatchedIndex => {
							partNameMatchedSlpAPW = this.sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex];
							partNameMatchedSlpMPW = this.sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex];
							if ((pspnTrans.processDataList[0].reqGoodPartsNext === partNameMatchedSlpAPW.reqGoodPartsPerWeek)
								&& (pspnTrans.processDataList[1].reqGoodPartsNext === partNameMatchedSlpMPW.reqGoodPartsPerWeek)) {
								reqGoodPartsMatchedIndexes.push(partNameMatchedIndex);
							}
						});
					}

					if (!isGoodPartsMatchedWithSlp && reqGoodPartsMatchedIndexes.length > 0) {
						isGoodPartsMatchedWithSlp = true;
						this.setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(pspnTrans, this.sharedLoadingRoot, reqGoodPartsMatchedIndexes[0]);
					}
				}
			}
		}
		return true;
	}

	private setAllocPercentReqGoodPartsSLPwithPartNumForAPWMAP(pspnTrans: any, sharedLoadingRoot: SharedLoadingRoot, partNameMatchedIndex) {
		pspnTrans.reqGoodPartsAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].reqGoodPartsPerWeek;
		pspnTrans.reqGoodPartsMPW = sharedLoadingRoot.sharedLoadingList[1].sharedLoadingPlanList[partNameMatchedIndex].reqGoodPartsPerWeek;
		pspnTrans.nictAPW = sharedLoadingRoot.sharedLoadingList[0].sharedLoadingPlanList[partNameMatchedIndex].nict;
	}

	private setAllocPercentReqGoodPartsSLPWithPartNumForCapacityAPWMAP(pspnTrans: any, sharedLoadingPlanHolder: SharedLoadingPlanHolder) {
		pspnTrans.reqGoodPartsAPW = sharedLoadingPlanHolder.capacityAPW.reqGoodPartsPerWeek;
		pspnTrans.reqGoodPartsMPW = sharedLoadingPlanHolder.capacityMPW.reqGoodPartsPerWeek;
		pspnTrans.nictAPW = sharedLoadingPlanHolder.capacityAPW.nict;
	}

	/*expandAllWarningRiskErrorInformation() {
		let count: number;
		let max = 0;
		if (!this.expandAllWarningRiskErrorInfo) {
			this.expandAllLinkText = 'Collapse All Warning/Risk/Error Details';
			this.expandAllWarningRiskErrorInfo = true;
			this.processStreamTree.forEach( processStreamTress => {
				processStreamTress.availableProcessNames.forEach( processName => {
					processName.expandSelectedProcess = true;
					this.carRequest.psPnMapTransList.forEach( pspnTran => {
						count = this.noOfMessageCheck(pspnTran, processName);
						if (max < count) {
							max = count;
						}
					});
					this.carRequest.primarySupportingMapTranList.forEach( primSupport => {
						primSupport.psPnMapTranList.forEach( pspnTran => {
							count = this.noOfMessageCheck(pspnTran, processName);
							if (max < count) {
								max = count;
							}
						});
					});
					this.setBoxSize(max, processName);
				});
			});
		} else {
			this.expandAllLinkText = 'Expand All Warning/Risk/Error Details';
			this.expandAllWarningRiskErrorInfo = false;
			this.processStreamTree.forEach( processStreamTress => {
				processStreamTress.availableProcessNames.forEach( processName => {
					processName.expandSelectedProcess = false;
					processName.processBoxSizewithBottleNeck = '380px';
					processName.processBoxSizewithOutBottleNeck = '340px';
				});
			});
		}
		this.reconstructConnection();
	}

	expandSelectedProcessBox(process: any, isPrimary: boolean) {
		if (!process.expandSelectedProcess) {
			process.expandSelectedProcess = true;
			if (isPrimary) {
				this.carRequest.psPnMapTransList.forEach( pspnTran => {
					if (pspnTran.processNameId === process.id) {
						this.noOfMessageCheck(pspnTran, process);
					}
				});
			} else {
				this.carRequest.primarySupportingMapTranList.forEach( primSupport => {
					primSupport.psPnMapTranList.forEach( pspnTran => {
						if (pspnTran.processNameId === process.id) {
							this.noOfMessageCheck(pspnTran, process);
						}
					});
				});
			}
		} else {
			process.expandSelectedProcess = false;
			process.processBoxSizewithBottleNeck = '380px';
			process.processBoxSizewithOutBottleNeck = '340px';
		}
		this.reconstructConnection();
	}
	noOfMessageCheck(pspnTran: any, process: any) {
		let count = 0;
		if (pspnTran.processDataList[0] && pspnTran.processDataList[0].daysPerWeek && (pspnTran.processDataList[0].daysPerWeek > 5) && (pspnTran.processDataList[0].daysPerWeek <= 7)) { count += 1; }
		if (pspnTran.processDataList[1] && pspnTran.processDataList[1].daysPerWeek && (pspnTran.processDataList[1].daysPerWeek > 6) && (pspnTran.processDataList[1].daysPerWeek <= 7)) { count += 1; }
		if (pspnTran.processDataList[0] && pspnTran.processDataList[0].shiftsPerDay && pspnTran.processDataList[0].hrsPerShift && (pspnTran.processDataList[0].shiftsPerDay * pspnTran.processDataList[0].hrsPerShift) > 24) { count += 1; }
		if (pspnTran.processDataList[1] && pspnTran.processDataList[1].shiftsPerDay && pspnTran.processDataList[1].hrsPerShift && (pspnTran.processDataList[1].shiftsPerDay * pspnTran.processDataList[1].hrsPerShift) > 24) { count += 1; }
		if ((pspnTran.processDataList[0].dedicated && pspnTran.isPartNameAvailableInSharedLoading)) { count += 1; }
		if ((pspnTran.processDataList[0].dedicated && pspnTran.isProcessUsedInAnotherPartNumber && (!pspnTran.isPartNameAvailableInSharedLoading) && this.mode !== 'view')) { count += 1; }
		if ((pspnTran.processDataList[0].dedicated && pspnTran.isProcessUsedInAnotherPartNumber && (!pspnTran.isPartNameAvailableInSharedLoading) && this.mode === 'view')) { count += 1; }
		if (pspnTran.processDataList[0].idealCycleTime !== null && pspnTran.processDataList[0].noToolsMachParallel !== null && pspnTran.processDataList[0].noIdentityParts !== null && pspnTran.processDataList[0].reqOee === 0) { count += 1; }
		if (pspnTran.processDataList[0].idealCycleTime !== null && pspnTran.processDataList[0].noToolsMachParallel !== null && pspnTran.processDataList[0].noIdentityParts !== null && pspnTran.processDataList[1].reqOee === 0) { count += 1; }
		if (((pspnTran.processDataList[0].avaiRemainPercent < 10) && (pspnTran.processDataList[0].avaiRemainPercent > 0))) { count += 1; }
		if (((pspnTran.processDataList[1].avaiRemainPercent < 10) && (pspnTran.processDataList[1].avaiRemainPercent > 0))) { count += 1; }
		if (pspnTran.processDataList[0].sharedLoadingTotalAllocPercent >= 90 && pspnTran.processDataList[0].sharedLoadingTotalAllocPercent <= 100) { count += 1; }
		if (pspnTran.processDataList[1].sharedLoadingTotalAllocPercent >= 90 &&  pspnTran.processDataList[1].sharedLoadingTotalAllocPercent <= 100) { count += 1; }
		if (pspnTran.processDataList[0].dedicatedTotalAllocPercent >= 90 && pspnTran.processDataList[0].dedicatedTotalAllocPercent <= 100) { count += 1; }
		if (pspnTran.processDataList[1].dedicatedTotalAllocPercent >= 90 &&  pspnTran.processDataList[1].dedicatedTotalAllocPercent <= 100) { count += 1; }
		if ((this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3') && pspnTran.processDataList[0].changeOverTimeActual && pspnTran.processDataList[0].changeOverTimeActual !== null && pspnTran.processDataList[0].changeOverTimeActual <= (pspnTran.processDataList[0].planMinChan - (pspnTran.processDataList[0].planMinChan * 0.5)))) { count += 1; }
		if ((this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3') && pspnTran.processDataList[0].changeOverTimeActual && pspnTran.processDataList[0].changeOverTimeActual !== null && pspnTran.processDataList[0].changeOverTimeActual >= (pspnTran.processDataList[0].planMinChan + (0.1 * pspnTran.processDataList[0].planMinChan)))) { count += 1; }
		if (( pspnTran.processDataList[0].noToolsMachParallel && (pspnTran.processDataList[0].noToolsMachParallel !== null) && pspnTran.processDataList[0].noOfCurrentToolsORMachines && (pspnTran.processDataList[0].noOfCurrentToolsORMachines !== null) && (pspnTran.processDataList[0].noOfCurrentToolsORMachines < pspnTran.processDataList[0].noToolsMachParallel))) { count += 1; }
		if (( pspnTran.processDataList[0].noIdentityParts && pspnTran.processDataList[0].noOfIdenticalPartsProduced && (pspnTran.processDataList[0].noOfIdenticalPartsProduced !== null) && (pspnTran.processDataList[0].noIdentityParts !== null) && (pspnTran.processDataList[0].noOfIdenticalPartsProduced < pspnTran.processDataList[0].noIdentityParts))) { count += 1; }
		if (( pspnTran.processDataList[0].scrappedPercent && (pspnTran.processDataList[0].noOfPartsScrappedPercent !== null) && (pspnTran.processDataList[0].scrappedPercent !== null) && (pspnTran.processDataList[0].noOfPartsScrappedPercent > pspnTran.processDataList[0].scrappedPercent))) { count += 1; }
		if (( pspnTran.processDataList[0].partsReworkedPercent && (pspnTran.processDataList[0].noOfPartsReworkedPercent !== null) && (pspnTran.processDataList[0].partsReworkedPercent !== null) && (pspnTran.processDataList[0].noOfPartsReworkedPercent > pspnTran.processDataList[0].partsReworkedPercent))) { count += 1; }
		if ((pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].observedAvgCycleTime === null) && pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].idealCycleTime === null)) && this.cycleTimeCheck(pspnTran.processDataList[0], 'Higher')) { count += 1; }
		if ((pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].observedAvgCycleTime === null) && pspnTran.processDataList[0].observedAvgCycleTime && !(pspnTran.processDataList[0].idealCycleTime === null)) && this.cycleTimeCheck(pspnTran.processDataList[0], 'Lower')) { count += 1; }
		if (( pspnTran.processDataList[0].plannedHeadCount && pspnTran.processDataList[0].actualHeadCount && (pspnTran.processDataList[0].actualHeadCount !== null) && (pspnTran.processDataList[0].plannedHeadCount !== null) && (pspnTran.processDataList[0].actualHeadCount < pspnTran.processDataList[0].plannedHeadCount))) { count += 1; }
		if (( pspnTran.processDataList[0].plannedHeadCount && pspnTran.processDataList[0].actualHeadCount && (pspnTran.processDataList[0].actualHeadCount !== null) && (pspnTran.processDataList[0].plannedHeadCount !== null) && (pspnTran.processDataList[0].actualHeadCount > pspnTran.processDataList[0].plannedHeadCount))) { count += 1; }
		if (pspnTran.hmpOlderThan32WeeksWarningShown) { count += 1; }
		if (pspnTran.hmpRecordsCountLessThan25WarningShown) { count += 1; }
		if ((pspnTran.processDataList[0].avaiRemainPercent <= 0)) { count += 1; }
		if ((pspnTran.processDataList[1].avaiRemainPercent <= 0)) { count += 1; }
		if ( (pspnTran.processDataList[0].reqOee > 100 || pspnTran.processDataList[1].reqOee > 100)) { count += 1; }
		if (pspnTran.processDataList[0].sharedLoadingTotalAllocPercent > 100) { count += 1; }
		if (pspnTran.processDataList[1].sharedLoadingTotalAllocPercent > 100) { count += 1; }
		if (pspnTran.processDataList[0].dedicatedTotalAllocPercent > 100) { count += 1; }
		if (pspnTran.processDataList[1].dedicatedTotalAllocPercent > 100) { count += 1; }
		if (pspnTran.demonOEE && pspnTran.processDataList[0].reqOee && pspnTran.demonOEE < pspnTran.processDataList[0].reqOee) { count += 1; }
		if (pspnTran.demonOEE && pspnTran.processDataList[1].reqOee && pspnTran.demonOEE < pspnTran.processDataList[1].reqOee) { count += 1; }
		if (pspnTran.demonOEE && pspnTran.demonOEE > 100) { count += 1; }
		if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase') && ((pspnTran.processDataList[0].phaseOEE < pspnTran.processDataList[0].reqOee) || (pspnTran.processDataList[1].phaseOEE < pspnTran.processDataList[1].reqOee))) { count += 1; }
		if (this.carRequest.requestStatus === 'DRAFT' || this.carRequest.requestStatus === 'REJECTED' || this.carRequest.requestStatus === 'STADRAFT') {
			if (pspnTran.processDataList[0] && pspnTran.processDataList[0].daysPerWeek && pspnTran.processDataList[0].daysPerWeek > 7) { count += 1; }
			if (pspnTran.processDataList[1] && pspnTran.processDataList[1].daysPerWeek && pspnTran.processDataList[1].daysPerWeek > 7) { count += 1; }
			if (pspnTran.processDataList[0].downTime && pspnTran.processDataList[0].hrsPerShift && (pspnTran.processDataList[0].downTime / 60 >= pspnTran.processDataList[0].hrsPerShift)) { count += 1; }
			if (pspnTran.processDataList[1].downTime && pspnTran.processDataList[1].hrsPerShift && (pspnTran.processDataList[1].downTime / 60 >= pspnTran.processDataList[1].hrsPerShift)) { count += 1; }
			if (pspnTran.processDataList[0].scrappedPercent > 100) { count += 1; }
			if (pspnTran.processDataList[0].partsReworkedPercent > 100) { count += 1; }
			if (pspnTran.isHmpItemExceeds100) { count += 1; }
			if (((pspnTran.processDataList[0].plannedDownTime + pspnTran.processDataList[0].changeOverTimeActual + pspnTran.processDataList[0].observedUnplannedDownTime) > pspnTran.processDataList[0].totalAvailableTime) && !this.hideProcessDetailsValidation && (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') || this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3'))) { count += 1; }
			if (pspnTran.processDataList[0].plannedDownTime > pspnTran.processDataList[0].totalAvailableTime) { count += 1; }
			if (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3') && pspnTran.processDataList[0].changeOverTimeActual !== null && pspnTran.processDataList[0].changeOverTimeActual <= 0 && (pspnTran.processDataList[0].planMinChan * pspnTran.processDataList[0].planChangFreq) > 0 && !this.hideProcessDetailsValidation) { count += 1; }
			if (pspnTran.processDataList[0].equipmentAvailability > 100 && !this.hideProcessDetailsValidation && (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') || this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3'))) { count += 1; }
			if ( (pspnTran.processDataList[0].noToolsMachParallel && pspnTran.processDataList[0].noOfCurrentToolsORMachines > pspnTran.processDataList[0].noToolsMachParallel) && !this.hideProcessDetailsValidation) { count += 1; }
			if ((pspnTran.processDataList[0].noIdentityParts && pspnTran.processDataList[0].noOfIdenticalPartsProduced > pspnTran.processDataList[0].noIdentityParts) && !this.hideProcessDetailsValidation) { count += 1; }
			if (( pspnTran.processDataList[0].noIdentityParts && (pspnTran.processDataList[0].performanceEfficiency !== null) && (isFinite(pspnTran.processDataList[0].performanceEfficiency) && pspnTran.processDataList[0].performanceEfficiency > 100 && !this.hideProcessDetailsValidation && (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') || this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3'))))) { count += 1; }
			if ((pspnTran.processDataList[0].noOfPartsScrapped + pspnTran.processDataList[0].noOfPartsReworked) > pspnTran.processDataList[0].totalPartsRun) { count += 1; }
			if (pspnTran.processDataList[0].qualityRate < 0 && !this.hideProcessDetailsValidation && (this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 0') || this.carRequest.submissionScenarioObject.templateName.startsWith('Phase 3'))) { count += 1; }
			if (pspnTran.isOneOrMoreOEEPercentageGreaterThan100) { count += 1; }
		}
		this.setBoxSize(count, process);
		return count;
	}

	private setBoxSize(count: number, process: any) {
		if (count <= 3) {
			process.processBoxSizewithBottleNeck = '500px';
			process.processBoxSizewithOutBottleNeck = '450px';
		} else if (count > 3 && count <= 6) {
			process.processBoxSizewithBottleNeck = '650px';
			process.processBoxSizewithOutBottleNeck = '600px';
		} else if (count > 6 && count <= 9) {
			process.processBoxSizewithBottleNeck = '800px';
			process.processBoxSizewithOutBottleNeck = '750px';
		} else if (count > 9 && count <= 12) {
			process.processBoxSizewithBottleNeck = '950px';
			process.processBoxSizewithOutBottleNeck = '950px';
		} else if (count > 12 && count <= 15) {
			process.processBoxSizewithBottleNeck = '1100px';
			process.processBoxSizewithOutBottleNeck = '1050px';
		} else if (count > 15 && count <= 18) {
			process.processBoxSizewithBottleNeck = '1250px';
			process.processBoxSizewithOutBottleNeck = '1200px';
		} else if (count > 18 && count <= 21) {
			process.processBoxSizewithBottleNeck = '1400px';
			process.processBoxSizewithOutBottleNeck = '1350px';
		} else if (count > 21 && count <= 24) {
			process.processBoxSizewithBottleNeck = '1550px';
			process.processBoxSizewithOutBottleNeck = '1500px';
		} else if (count > 24 && count <= 30) {
			process.processBoxSizewithBottleNeck = '1700px';
			process.processBoxSizewithOutBottleNeck = '1650px';
		} else if (count > 30 && count <= 37) {
			process.processBoxSizewithBottleNeck = '1850px';
			process.processBoxSizewithOutBottleNeck = '1800px';
		} else if (count > 37 && count <= 45) {
			process.processBoxSizewithBottleNeck = '2350px';
			process.processBoxSizewithOutBottleNeck = '2300px';
		} else if (count > 45) {
			process.processBoxSizewithBottleNeck = '2800px';
			process.processBoxSizewithOutBottleNeck = '2750px';
		}
		process.processBoxSizewithBottleNeck = '2800px';
		process.processBoxSizewithOutBottleNeck = '2750px';
	}*/
}
