import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Bulletin, emptyBulletin} from '../../../models/bulletin';
import {UtilService} from '../../../services/util.service';
import {ManageBulletinsService} from '../../../services/master/manage-bulletins/manage-bulletins.service';

@Component({
	selector: 'create-bulletins',
	templateUrl: './create-bulletins.component.html',
	styleUrls: ['./create-bulletins.component.css']
})
export class CreateBulletinsComponent implements OnInit {
	private mode: string;
	private displayName: string;
	private bulletinObj: Bulletin = new Bulletin(emptyBulletin);

	private buttonName: string;
	private disableCreateButton = false;
	private startDateDisplay: Date;
	private endDateDisplay: Date;
	private yearRange: string;

	constructor(private activatedRoute: ActivatedRoute, private bulletinService: ManageBulletinsService, private router: Router, private utilService: UtilService) {

	}

	ngOnInit() {
		this.retrieveRouteParameter();
		if (this.mode === 'create') {
			this.initializeScreen();
		} else if (this.mode === 'edit') {
			this.findById(this.bulletinObj.id);
		}
		this.validateCreateButton();
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.bulletinObj.id = Number(params.get('id'));
			if (this.mode === 'create') {
				this.displayName = 'Create New Bulletin';
				this.buttonName = 'Create New';
			} else if (this.mode === 'edit') {
				this.displayName = 'Edit Bulletin';
				this.buttonName = 'Update';
			}
			console.log('this.displayName ' + this.displayName);
		});
		let currentDate = new Date();
		this.yearRange = currentDate.getFullYear() + ':' + (currentDate.getFullYear() + 100);
	}

	private initializeScreen() {
		this.bulletinObj = new Bulletin(emptyBulletin);
	}

	private onCreate(newBulletin: Bulletin) {
		if (this.validateFields(newBulletin)) {
			this.bulletinService.create(newBulletin).subscribe(success => {
				this.utilService.pushMsg('success', 'Success', 'New Bulletin has been created successfully');
				this.goToManageScreen();
			}, createError => {
				this.utilService.pushMsg('error', 'Error', 'Error while creating new Bulletin');
			});
		}
	}

	private onUpdate(updateBulletin: Bulletin) {
		if (this.validateFields(updateBulletin)) {
			this.bulletinService.update(updateBulletin.id, updateBulletin).subscribe(success => {
				this.utilService.pushMsg('success', 'Success', 'Bulletin has been successfully modified');
				this.goToManageScreen();
			}, createError => {
				this.utilService.pushMsg('error', 'Error', 'Error while updating Bulletin');
			});
		}
	}

	private validateFields(bulletin: Bulletin): boolean {
		if (bulletin.title === null || bulletin.title.trim() === '') {
			this.utilService.pushMsg('error', 'Error', 'Please enter Bulletin Title');
			return false;
		} else if (bulletin.description === null || bulletin.description.trim() === '') {
			this.utilService.pushMsg('error', 'Error', 'Please enter Bulletin Description');
			return false;
		} else if (bulletin.startDate === null || !bulletin.startDate) {
			this.utilService.pushMsg('error', 'Error', 'Please enter Start Date value');
			return false;
		} else if (bulletin.endDate === null || !bulletin.endDate) {
			this.utilService.pushMsg('error', 'Error', 'Please enter End Date value');
			return false;
		} else {
			return true;
		}
	}

	private validateCreateButton() {
		if (this.bulletinObj.title && this.bulletinObj.title.trim() !== '' && this.bulletinObj.description && this.bulletinObj.description.trim() !== '' && (this.startDateDisplay || this.bulletinObj.startDate) && (this.endDateDisplay || this.bulletinObj.endDate)) {
			this.disableCreateButton = false;
		} else {
			this.disableCreateButton = true;
		}
	}


	private findById(id: number) {
		this.bulletinService.findById(id).subscribe(response => {
			this.bulletinObj = response;
			this.startDateDisplay = new Date(this.bulletinObj.startDate);
			this.endDateDisplay = new Date(this.bulletinObj.endDate);
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Currently we are not able to process your request, Please try again later. If the issue continuously persists, kindly contact eCAR Support team.');
		});
	}

	onSubmit() {
		console.log('this.startDateDisplay.toISOString() ' + this.startDateDisplay.toISOString());
		this.bulletinObj.startDate = this.startDateDisplay.toISOString();
		this.bulletinObj.endDate = this.endDateDisplay.toISOString();
		if (this.mode === 'create') {
			this.onCreate(this.bulletinObj);
		} else if (this.mode === 'edit') {
			this.onUpdate(this.bulletinObj);
		}

	}

	private goToManageScreen() {
		this.router.navigate(['manage-bulletins']);
	}

	onCancel() {
		this.utilService.pushMsg('info', 'Information', 'Operation Cancelled');
		this.goToManageScreen();
	}
}
