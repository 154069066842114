import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Bulletin} from '../../../../models/bulletin';
import {UtilService} from '../../../../services/util.service';

@Component({
	selector: 'show-bulletins-dialog',
	templateUrl: './show-bulletins-dialog.component.html',
	styleUrls: ['./show-bulletins-dialog.component.css']
})
export class ShowBulletinsDialogComponent implements OnInit {
	@Input() bulletinsToShow: Bulletin[];
	@ViewChild('bulletinForm', {static: false}) bulletinForm;
	@ViewChild('dialDetail', {static: false}) dialDetail;

	bulletinsData: Bulletin[];
	displayBulletins = false;

	constructor(private utilService: UtilService) {
	}

	ngOnInit() {
	}

	onShowBulletins() {
		this.displayBulletins = true;
		if (this.bulletinsToShow && this.bulletinsToShow.length > 0) {
			this.bulletinsData = this.bulletinsToShow;
			this.bulletinsData.forEach(bulletin => {
				if (bulletin.linkText && bulletin.linkText.trim() !== '') {
					bulletin.linksArray = bulletin.linkText.split(' ');
				}
			});
		}
		console.log('bulletins data length ' + this.bulletinsData.length);
	}

	retrieveLink(plainText): string {
		return this.utilService.linkify(plainText);
	}

	onClose() {
		this.displayBulletins = false;
	}
}
