import {AfterViewChecked, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CarRequest} from '../../../models/car-request';
import {CarRequestCommentsService} from '../../../services/request/car-req-comments.service';
import {UtilService} from '../../../services/util.service';
import {CarReqComments} from '../../../models/car-req-comments';

@Component({
	selector: 'car-req-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.css']
})
export class CommentsComponent {

	carReq: CarRequest;

	newComment: string;

	comments: CarReqComments[] = [];

	/**
	 * Input setter to set the car request
	 * and trigger fetchComments asynchronously
	 * @param carReq - Car Request Object
	 */
	@Input()
	set carRequest(carReq: CarRequest) {
		this.carReq = carReq;
		this.fetchComments();
	}

	constructor(
		public carReqCommentsService: CarRequestCommentsService,
		private utilService: UtilService) {
	}

	/**
	 * Fetch the available comments for the selected
	 * CAR Request id
	 */
	fetchComments() {
		this.carReqCommentsService.findCommentsForReqId(this.carReq.reqId).subscribe(
			(carReqComments: CarReqComments[]) => {
				this.comments = carReqComments;
			}, (error) => {
				this.utilService.pushMsg('error',
					'Error',
					'Something went wrong while fetching comments');
			});
	}

	/**
	 * Post the comments for the selected CAR Request
	 * if the comment is not empty else show the warning message
	 */
	postComments() {
		if (!this.newComment || this.newComment.length === 0) {
			this.utilService.pushMsg('warn',
				'Error',
				'Please enter comments to submit');
			return;
		}
		const carReqComments: CarReqComments = new CarReqComments().deSerialize({
			'comment': this.newComment,
			'carReqId': this.carReq.reqId
		});
		this.carReqCommentsService.create(carReqComments, this.carReq.reqId).subscribe((response) => {
			this.comments.push(response);
			this.newComment = '';
			this.utilService.pushMsg('success',
				'Success',
				'Comments Submitted Successfully');
		}, (error) => {
			this.utilService.pushMsg('error',
				'Error',
				'Something went wrong while creating comments');
		});
	}

	/**
	 * Getter to sort the comments based on the
	 * comment primary-key to show the latest one first
	 */
	get sortedComments(): CarReqComments[] {
		return [...this.comments].sort(
			(a, b) => b.carReqCommentId - a.carReqCommentId);
	}
}

