export class SharedLoadingPlan {
	id: number;
	sharedLoadingId: number;
	vehLine: string;
	partName: string;
	reqGoodPartsPerWeek: number;
	nict: number;
	timeReq: number;
	toolChangeoverTime: number;
	allocationMinRequired: number;
	additionalAllocation: number;
	allocationTotalRequired: number;
	isApw: boolean;
	isOtherAllocation: boolean;
	createdBy: string;
	createdDate: number;
	updatedBy: string;
	updatedDate: number;
	partNamePrefix: string;
	partNameBase: string;
	partNameSuffix: string;
	groupId: number;
	isCapacity: boolean;
	capacityId: string;
	isAlertReq: boolean;

	constructor(sharedLoadingPlan: SharedLoadingPlan) {
		Object.assign(this, sharedLoadingPlan);
	}
}

export class SharedLoadingPlanHolder {
	apw: SharedLoadingPlan;
	mpw: SharedLoadingPlan;
	capacityAPW: SharedLoadingPlan;
	capacityMPW: SharedLoadingPlan;
	enableUndoShareLoading = false;
	enableAckShareLoading = false;
	enableAckUndoShareLoading = false;
	enableConfirmShareLoading = true;
}

export const emptySLP = {
	'id': 0,
	'sharedLoadingId': 0,
	'vehLine': '',
	'partName': '',
	'reqGoodPartsPerWeek': null,
	'nict': null,
	'timeReq': 0.0,
	'toolChangeoverTime': null,
	'allocationMinRequired': 0.0,
	'additionalAllocation': null,
	'allocationTotalRequired': 0.0,
	'isApw': false,
	'isOtherAllocation': false,
	'createdBy': '',
	'createdDate': 1580372096578,
	'updatedBy': '',
	'updatedDate': 1580372096578,
	'partNamePrefix': '',
	'partNameBase': '',
	'partNameSuffix': '',
	'groupId': 0,
	'isCapacity': false,
	'capacityId': '',
	'isAlertReq': null
};

export const emptySLPAPWPart1 = {
	'id': 0,
	'sharedLoadingId': 0,
	'vehLine': 'P702',
	'partName': 'ML3T-11A059-SX',
	'reqGoodPartsPerWeek': 2739,
	'nict': 8.2,
	'timeReq': 7.9,
	'toolChangeoverTime': 15,
	'allocationMinRequired': 7.2,
	'additionalAllocation': 0.0,
	'allocationTotalRequired': 7.2,
	'isApw': true,
	'isOtherAllocation': false,
	'createdBy': '',
	'createdDate': 1580372096578,
	'updatedBy': '',
	'updatedDate': 1580372096578,
	'partNamePrefix': '',
	'partNameBase': '',
	'partNameSuffix': '',
	'groupId': 0,
	'isCapacity': false,
	'capacityId': '',
	'isAlertReq': null
};

export const emptySLPMPWPart1 = {
	'id': 0,
	'sharedLoadingId': 0,
	'vehLine': 'P702',
	'partName': 'ML3T-11A059-SX',
	'reqGoodPartsPerWeek': 2992,
	'nict': 8.2,
	'timeReq': 8.6,
	'toolChangeoverTime': 15,
	'allocationMinRequired': 6.5,
	'additionalAllocation': 0.0,
	'allocationTotalRequired': 6.5,
	'isApw': false,
	'isOtherAllocation': false,
	'createdBy': '',
	'createdDate': 1580372096578,
	'updatedBy': '',
	'updatedDate': 1580372096578,
	'partNamePrefix': '',
	'partNameBase': '',
	'partNameSuffix': '',
	'groupId': 0,
	'isCapacity': false,
	'capacityId': '',
	'isAlertReq': null
};

export const emptySLPAPWPart2 = {
	'id': 0,
	'sharedLoadingId': 0,
	'vehLine': 'GM',
	'partName': '1440003216',
	'reqGoodPartsPerWeek': 50,
	'nict': 8.2,
	'timeReq': 0.1,
	'toolChangeoverTime': 15,
	'allocationMinRequired': 0.1,
	'additionalAllocation': 0.0,
	'allocationTotalRequired': 0.1,
	'isApw': true,
	'isOtherAllocation': false,
	'createdBy': '',
	'createdDate': 1580372096578,
	'updatedBy': '',
	'updatedDate': 1580372096578,
	'partNamePrefix': '',
	'partNameBase': '',
	'partNameSuffix': '',
	'groupId': 0,
	'isCapacity': false,
	'capacityId': '',
	'isAlertReq': null
};

export const emptySLPMPWPart2 = {
	'id': 0,
	'sharedLoadingId': 0,
	'vehLine': 'GM',
	'partName': '1440003216',
	'reqGoodPartsPerWeek': 70,
	'nict': 8.2,
	'timeReq': 0.2,
	'toolChangeoverTime': 15,
	'allocationMinRequired': 0.1,
	'additionalAllocation': 0.10,
	'allocationTotalRequired': 0.1,
	'isApw': false,
	'isOtherAllocation': false,
	'createdBy': '',
	'createdDate': 1580372096578,
	'updatedBy': '',
	'updatedDate': 1580372096578,
	'partNamePrefix': '',
	'partNameBase': '',
	'partNameSuffix': '',
	'groupId': 0,
	'isCapacity': false,
	'capacityId': '',
	'isAlertReq': null
};
